import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class IndexRoute extends Route {
  @service store

  @service session

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type')
    const teachersForSubType = (
      await this.store.query('teacher', {
        scope: `districts/${this.session.currentDistrict.id}`,
        filter: { 'subscription-type': subscriptionType }, // only teachers that have a school-class for this sub
        include: [
          'school',
          'school.school-subscriptions', // for the active school filter below
          'school.school-subscriptions.subscription', // to ensure gravity has all data for school-subscription view
          'school-classes',
          'school-classes.students',
          'teacher-subscriptions',
        ].join(','),
      })
    )
      .slice()
      // filter by active schools. We do it via school-subs to avoid requesting utilisations, which are slow
      .filter((teacher) => teacher.school?.hasActiveSchoolSubscriptionFor(subscriptionType))
    return { teachersForSubType }
  }

  resetController(controller, isExiting) {
    if (isExiting) controller.uncheckSelectedItems?.()
  }
}
