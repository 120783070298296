import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { isPresent } from '@ember/utils'
import { service } from '@ember/service'
import type SessionService from 'district-ui-client/services/session'
import type AlertService from 'district-ui-client/services/alert'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type Teacher from 'district-ui-client/models/teacher'

export default class ManageTeacherEditController extends Controller<Teacher> {
  @service session!: SessionService

  @service alert!: AlertService

  @service activeRoute!: ActiveRouteService

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get teacher() {
    return this.model
  }

  @tracked updatingTeacher = false

  cancel = () => {
    this.model.rollbackAttributes()
    this.backToPrevious()
  }

  updateTeacher = async () => {
    try {
      this.updatingTeacher = true
      await this.model.save()
      this.updatingTeacher = false
      this.backToPrevious()
      this.alert.showWithDismiss({
        type: 'positive',
        message: `Successfully updated teacher ${this.model.fullNameReversed}`,
      })
    } catch {
      this.updatingTeacher = false
      return this.showModelError()
    }
  }

  private showModelError = () => {
    if (isPresent(this.model.errors.messages)) {
      this.model.errors.messages.forEach((error: any) => {
        this.alert.showWithDismiss({
          type: 'critical',
          message: error.detail,
        })
      })
    } else {
      this.alert.showWithDismiss({
        type: 'critical',
        message: 'Failed to update details',
      })
    }
  }

  private backToPrevious() {
    window.history.back()
  }
}
