import Model, { belongsTo, attr } from '@ember-data/model'
import { get } from '@ember/object'
import { tracked } from '@glimmer/tracking'

/**
 * Model class for clever data teacher
 *
 * @class CleverTeacherModel
 * @property {string} firstName - first name of teacher
 * @property {string} lastName - last name of teacher
 * @property {string} sisId - unique clever id for the teacher
 * @property {boolean} matched - Flag to show if teacher is matched
 * @property {CleverSchoolModel} cleverSchool - HasOne relationship to the clever school
 */
export default class extends Model {
  @attr('string') firstName

  @attr('string') lastName

  @attr('string') email

  @attr('string') sisId

  @attr('boolean') matched

  @attr('boolean') readingCleverApp

  @attr('boolean') mathsCleverApp

  @attr() matchError

  @belongsTo('clever/clever-school', { async: false, inverse: 'cleverTeachers' }) cleverSchool

  @belongsTo('clever/clever-teacher-match', { async: false, inverse: 'cleverTeacher' }) cleverTeacherMatch

  get blakeTeacher() {
    return get(this, 'cleverTeacherMatch.teacher')
  }

  // undefined | "existsDifferentDistrict" | "existsAsParent" | "existsAsParentContact" | "idMismatchError" | "staleAccountError"
  get matchErrorCode() {
    return this.matchError?.code
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ') || '-'
  }

  @tracked isCreateBlakeTeacherChecked = false
}
