import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { service } from '@ember/service'
import { isEmpty } from '@ember/utils'

export default class AddExistingTeachersController extends Controller {
  @service session

  @service addExistingTeachers

  @service activeRoute

  @service router

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.activeRoute.subscriptionType)
  }

  @tracked selectedIds = []

  get selectedTeachers() {
    return this.notProductTeachers.filter((t) => this.selectedIds.includes(t.id))
  }

  get notProductTeachers() {
    return this.model.teachersWithoutSubType
  }

  @action
  updateSelectedIds(selectedIds) {
    this.selectedIds = selectedIds
  }

  @action
  async addTeachers(isTrial) {
    if (isEmpty(this.selectedTeachers)) return

    const teachers = this.selectedTeachers

    const result = await this.addExistingTeachers.execute(teachers, this.activeRoute.subscriptionType, isTrial)
    if (result) {
      this._uncheckSelectedItems()
      this.router.transitionTo('subscription-type.manage.teachers')
    }
  }

  _uncheckSelectedItems() {
    this.selectedIds = []
  }
}
