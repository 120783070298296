import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
export const LoadingSpinner: TOC<unknown> = template(`
  <FidgetLoadingIndicatorComponent @show={{true}} @overlay={{false}} @loadingText="Loading..." @centered={{true}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LoadingSpinner;
