import { helper } from '@ember/component/helper'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import { THEME_KEYS } from 'district-ui-client/utils/themes'

interface Signature {
  Args: {
    Positional: [subscriptionType?: SubscriptionType]
  }
  Return: string
}

/**
 * Provide a subscription type, get back a theme key. This theme key can be used to make color-based UI decisions in JS.
 *
 * {{theme-key "reading"}} -> "blue"
 * {{theme-key "maths"}} -> "green"
 */
export const themeKey = helper<Signature>(function ([subscriptionType = '']) {
  const theme = {
    reading: THEME_KEYS.blue,
    maths: THEME_KEYS.green,
  }[subscriptionType]
  return theme || ''
})

export default themeKey
