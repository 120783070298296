import Model, { belongsTo, attr } from '@ember-data/model'
import { get } from '@ember/object'
import { tracked } from '@glimmer/tracking'

/**
 * Model class for clever data student
 *
 * @class CleverStudentModel
 * @property {string} firstName - first name of student
 * @property {string} lastName - last name of student
 * @property {string} grade - grade from clever for student
 * @property {string} sisId - unique clever id for the school
 * @property {boolean} matched - Flag to show if school is matched
 */
export default class extends Model {
  @attr('string') firstName

  @attr('string') lastName

  // From the Clever API, grades for clever records do come through as strings, but we want them as numbers.
  @attr('number') gradePosition

  @attr('string') sisId

  @attr('boolean') matched

  @attr('boolean') mathsCleverApp

  @attr('boolean') readingCleverApp

  @belongsTo('clever/clever-school', { async: false, inverse: 'cleverStudents' })
  cleverSchool

  @belongsTo('clever/clever-student-match', { async: false, inverse: 'cleverStudent' })
  cleverStudentMatch

  get blakeStudent() {
    return get(this, 'cleverStudentMatch.student')
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ') || '-'
  }

  @tracked isCreateBlakeStudentChecked = false
}
