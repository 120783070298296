import Service, { service } from '@ember/service'
import { A } from '@ember/array'
import { set, setProperties } from '@ember/object'
import { schedule } from '@ember/runloop'
import RSVP from 'rsvp'
import groupBy from 'lodash/groupBy'
import { timeout, task } from 'ember-concurrency'
import { join, joinQueryParams } from 'district-ui-client/utils/uri'
import { isNone } from '@ember/utils'
import { waitFor } from '@ember/test-waiters'

/**
 * No this service is not clever, but useful. It provides a central location for all data
 * operations we might come across. It provides an interface to
 * 1. create
 * 2. update
 * 3. and find records.
 *
 * Simply said, it hides away all knowledge about our data. What cant go into the model classes
 * should be added here.
 *
 * @class CleverService
 */
export default class CleverService extends Service {
  @service store

  @service flashQueue

  @service intl

  @service authToken

  @service log

  @service session

  _cleverSchoolPollingTimeout = 5000

  /**
   * Method to create a new Blake teacher followed by creating a match record.
   * Note: we are using adapterOptions generate the correct url for the post request
   * @param {CleverTeacherModel} cleverTeacher
   * @param {CleverSchoolModel} cleverSchool
   * @param {Object} adapterOptions
   * @returns {Promise}
   */
  async createBlakeTeacherFromCleverData(cleverTeacher, cleverSchool, adapterOptions = {}) {
    const { store } = this
    const schoolMatch = cleverSchool.get('cleverSchoolMatch')
    const districtMatch = store.peekAll('clever/clever-district-match')?.slice()?.[0] // there will always be one
    const cleverTeacherEmail = cleverTeacher.email
    const blakeSchool = schoolMatch.get('school')

    const allBlakeTeachersForSchool = blakeSchool.get('teachers')
    let blakeTeacher = allBlakeTeachersForSchool.find(({ email }) => email === cleverTeacherEmail)

    if (isNone(blakeTeacher)) {
      // create a new blake teacher record
      const newTeacher = store.createRecord('clever/teacher', {
        firstName: cleverTeacher.firstName,
        lastName: cleverTeacher.lastName,
        email: cleverTeacherEmail,
        cleverSchoolMatch: schoolMatch,
        cleverDistrictMatch: districtMatch,
        students: [],
        school: cleverSchool.get('blakeSchool'),
        accountType: 'school-subscription',
      })
      try {
        await newTeacher.save({ adapterOptions })
        blakeTeacher = newTeacher
      } catch (e) {
        // save failed, possibly due to a match error. Remove uncommitted record from the store.
        // then rethrow error (so that page can show an error flash etc)
        newTeacher.unloadRecord()
        throw e
      }
    }

    // match the blake teacher record to a clever teacher
    return this.matchCleverTeacherToBlakeTeacher(cleverTeacher, blakeTeacher.id, adapterOptions)
  }

  /**
   * Method to create a new Blake student followed by creating a match record.
   * Note: we are using adapterOptions generate the correct url for the post request
   * @param {CleverStudentModel} cleverStudent
   * @param {CleverSchoolModel} cleverSchool
   * @param {Object} adapterOptions
   * @returns {Promise}
   */
  async createBlakeStudentFromCleverData(cleverStudent, cleverSchool, adapterOptions = {}) {
    const { store } = this
    const schoolMatch = cleverSchool.get('cleverSchoolMatch')
    const districtMatch = store.peekAll('clever/clever-district-match')?.slice()?.[0] // there will always be one
    const blakeSchool = schoolMatch.get('school')

    await store
      .createRecord('clever/student', {
        firstName: cleverStudent.firstName,
        lastName: cleverStudent.lastName,
        gradePosition: cleverStudent.gradePosition,
        cleverSchoolMatch: schoolMatch,
        cleverDistrictMatch: districtMatch,
        school: blakeSchool,
      })
      .save({
        adapterOptions: {
          ...adapterOptions,
          meta: {
            'clever-student-match': { 'clever-id': cleverStudent.id, 'sis-id': cleverStudent.sisId },
          },
          queryParams: {
            include: 'clever-student-match',
          },
        },
      })
  }

  /**
   * Initiates the creation of a school match record
   *
   * @param {CleverSchoolModel} cleverSchool
   * @param {BlakeSchoolModel} blakeSchool
   * @returns {Promise}
   */
  async matchCleverSchoolToBlakeSchool(cleverSchool, blakeSchool) {
    const { store } = this

    const cleverSchoolMatch = await store
      .createRecord('clever/clever-school-match', {
        cleverDistrictMatch: cleverSchool.get('cleverDistrict.cleverDistrictMatch'),
        cleverId: cleverSchool.id,
        cleverSchool,
        school: blakeSchool,
        sisId: cleverSchool.sisId,
      })
      .save()

    // update the blake school with both the cleverSchoolMatch
    set(blakeSchool, 'cleverSchoolMatch', cleverSchoolMatch)

    return cleverSchoolMatch
  }

  /**
   * Initiates the creation of a teacher match record
   *
   * @param {CleverTeacherModel} cleverTeacher
   * @param {String} blakeTeacherId
   * @param {Object} adapterOptions
   * @returns {Promise}
   */
  async matchCleverTeacherToBlakeTeacher(cleverTeacher, blakeTeacherId, adapterOptions = {}) {
    const { store } = this

    const blakeTeacher = this.getBlakeTeacherById(blakeTeacherId)
    const cleverDistrictMatch = await cleverTeacher.get('cleverSchool.cleverDistrict.cleverDistrictMatch')

    const cleverTeacherMatch = store.createRecord('clever/clever-teacher-match', {
      cleverId: cleverTeacher.id,
      sisId: cleverTeacher.sisId,
      cleverDistrictMatch,
      teacher: blakeTeacher,
      cleverTeacher,
    })
    try {
      await cleverTeacherMatch.save({ adapterOptions })
      // update the blake teacher with both the cleverDistrictMatch,
      // and the cleverTeacherMatch record
      setProperties(blakeTeacher, {
        cleverDistrictMatch,
        cleverTeacherMatch,
      })
    } catch (e) {
      // save failed. Remove uncommitted record from the store, then rethrow (so that page can show an error flash etc)
      cleverTeacherMatch.rollbackAttributes()
      throw e
    }

    return cleverTeacherMatch
  }

  /**
   * Initiates the creation of a student match record through the blakeStudent model
   *
   * @param {CleverStudentModel} cleverStudent
   * @param {String} blakeStudentId
   * @param {Object} adapterOptions
   * @returns {Promise}
   */
  async matchCleverStudentToBlakeStudent(cleverStudent, blakeStudentId, adapterOptions = {}) {
    const { store } = this
    const blakeStudent = this.getBlakeStudentById(blakeStudentId)

    const cleverDistrictMatch = await cleverStudent.get('cleverSchool.cleverDistrict.cleverDistrictMatch')

    const cleverStudentMatch = await store
      .createRecord('clever/clever-student-match', {
        cleverDistrictMatch,
        cleverId: cleverStudent.id,
        cleverStudent,
        sisId: cleverStudent.sisId,
        student: blakeStudent,
      })
      .save({ adapterOptions })

    // update the blake student with both the cleverStudentMatch
    set(blakeStudent, 'cleverStudentMatch', cleverStudentMatch)

    return cleverStudentMatch
  }

  /**
   * Sets the matched attribute of a cleverUser to a boolean value
   *
   * @param {CleverTeacherModel|CleverStudentModel} cleverUser
   * @param {Boolean} matched
   * @returns {Boolean}
   */
  setCleverUserMatchedState(cleverUser, matched = true) {
    return set(cleverUser, 'matched', matched)
  }

  /**
   * Set the confirmedMatch flag to true
   * @param {CleverSchoolModel} cleverSchool
   */
  confirmAutoMatch(cleverSchool) {
    cleverSchool.set('confirmedMatch', true)
    return cleverSchool.save()
  }

  /**
   * Returns a single BlakeSchoolModel record based on its Id
   * @param blakeSchoolId
   * @returns {BlakeSchoolModel}
   */
  getBlakeSchoolById(blakeSchoolId) {
    const { store } = this
    return store.peekRecord('clever/school', blakeSchoolId) || {}
  }

  getBlakeTeacherById(blakeTeacherId) {
    const { store } = this
    if (blakeTeacherId === null) return {}
    return store.peekRecord('clever/teacher', blakeTeacherId) || {}
  }

  getBlakeStudentById(blakeStudentId) {
    const { store } = this
    if (blakeStudentId === null) return {}
    return store.peekRecord('clever/student', blakeStudentId) || {}
  }

  /**
   * Returns blake schooles which dont have any school matched
   * @returns {Array<BlakeSchoolModel>}
   */
  blakeSchoolsWithoutMatches() {
    const { store } = this
    return store.peekAll('clever/school').filter((school) => school.belongsTo('cleverSchoolMatch').value() === null)
  }

  /**
   * Turns blake students into an array format which can be passed onto a csv generator.
   * @param {DS.RecordArray<BlakeStudentModel>} blakeStudents
   * @param {Boolean} inlcudeSisId
   * @returns {Array} - the result will be like: [['', 'klaus', 'dieter', 1],[...],[...]]
   */
  blakeStudentsToCSVArray(blakeStudents, inlcudeSisId = false) {
    return blakeStudents.map(function (student) {
      let sisId = ''
      if (inlcudeSisId) sisId = student.get('sisId')
      return [sisId, student.get('firstName'), student.get('lastName')]
    })
  }

  /**
   * Turns blake teachers into an array format which can be passed onto a csv generator.
   * @param {DS.RecordArray<BlakeTeacherModel>} blakeTeachers
   * @param {Boolean} inlcudeSisId
   * @returns {Array} - the result will be like: [['', 'klaus', 'dieter', 1],[...],[...]]
   */
  blakeTeachersToCSVArray(blakeTeachers, inlcudeSisId = false) {
    return blakeTeachers.map(function (teacher) {
      let sisId = ''
      if (inlcudeSisId) sisId = teacher.get('sisId')
      return [sisId, teacher.get('firstName'), teacher.get('lastName')]
    })
  }

  async fetchCleverSchool(cleverDistrictId, cleverSchoolId) {
    const cleverSchools = await this.store.query('clever/clever-school', {
      scope: `clever-districts/${cleverDistrictId}`,
      filter: { id: [cleverSchoolId] },
    })
    return cleverSchools[0]
  }

  // If the backend is still working on matches, we need to give it time before it's ready. Re-query every 5 seconds
  // until it's no longer in an "in progress" state
  pollForCleverSchool = task({ drop: true }, async (cleverSchool) => {
    while (cleverSchool.isMatchingInProgress) {
      await timeout(this._cleverSchoolPollingTimeout)
      await this.fetchCleverSchool(cleverSchool.cleverDistrictId, cleverSchool.id)
    }
  })

  // This is useful on the dashboard, to reload any clever-schools that are in some "in progress" state, rather than
  // being stuck there until the user refreshes the page.
  pollForCleverSchools = task({ restartable: true }, async (cleverDistrictId, cleverSchools) => {
    const isMatchingInProgress = (cleverSchool) => cleverSchool.isMatchingInProgress
    let cleverSchoolsInProgress = cleverSchools.filter(isMatchingInProgress)

    while (cleverSchoolsInProgress.length > 0) {
      await timeout(this._cleverSchoolPollingTimeout)
      const ids = cleverSchoolsInProgress.map((cleverSchool) => cleverSchool.id)
      await this.store.query('clever/clever-school', {
        scope: `clever-districts/${cleverDistrictId}`,
        filter: { id: ids },
      })
      cleverSchoolsInProgress = cleverSchools.filter(isMatchingInProgress)
    }
  })

  /**
   * This methods creates a post request to the initial_sync clever endpoint.
   * @link https://blake.readme.io/v1.0/reference#districts
   * @param {Number} cleverId
   * @param {String} subscriptionType
   * @return {Promise}
   */
  async callInitialSync(cleverId, subscriptionType) {
    const { store } = this
    const cleverAdapter = store.adapterFor('clever/clever-district')
    const { host, namespace } = cleverAdapter
    const url = join(host, namespace, 'clever-districts', cleverId, 'initial-sync')
    const urlWithQPs = joinQueryParams(url, { 'subscription-type': subscriptionType })

    const response = await fetch(urlWithQPs, {
      method: 'POST',
      headers: {
        Authorization: this.authToken.token,
        'content-type': 'application/vnd.api+json',
      },
    })
    if (response.ok) {
      return response
    } else {
      throw response
    }
  }

  /**
   * Use this task to start the school sync process for a given array of clever school ids.
   *
   * It will make the requests to start the sync, then query the ember data records repeatedly until all schools are
   * synced.
   *
   * @param string cleverDistrictId - the relevant district id, used to scope the clever school query when polling
   * @param string[] cleverSchoolIds - the clever school ids to sync
   * @param string subscriptionType - the subscription type to sync the school for
   * @param function onUpdateCallback - (optional) called periodically so that the UI may update as schools are synced
   */
  syncSchoolsTask = task(
    { drop: true },
    waitFor(async (cleverDistrictId, cleverSchoolIds, subscriptionType, { onUpdateCallback } = {}) => {
      const { intl, flashQueue, log, store } = this

      const flashPrefix = 'clever.flashMessages.syncSchools'
      const flashTitle = intl.t(`${flashPrefix}.title`)

      try {
        // start the process for each school
        for (const cleverSchoolId of cleverSchoolIds) {
          await this.callCompleteSyncForSchool(cleverSchoolId, subscriptionType)
        }
        flashQueue.addSuccess({ title: flashTitle, subtitle: intl.t(`${flashPrefix}.started`) })

        // now poll, every 5 seconds for updates, until all of the provided schools are ready
        let unsyncedCleverSchoolIds = cleverSchoolIds
        while (unsyncedCleverSchoolIds.length > 0) {
          const cleverSchools = await store.query('clever/clever-school', {
            scope: `clever-districts/${cleverDistrictId}`,
            filter: { id: unsyncedCleverSchoolIds },
          })

          // Split into "still unsynced" and "newly synced" groups
          const groupedBySync = groupBy(cleverSchools.slice(), (school) =>
            school.hasCompletedSync ? 'synced' : 'unsynced',
          )
          groupedBySync.synced ??= []
          groupedBySync.unsynced ??= []

          unsyncedCleverSchoolIds = groupedBySync.unsynced.map((cleverSchool) => cleverSchool.id)

          // If there are newly synced schools, update the UI and load new student counts that may have changed
          if (groupedBySync.synced.length > 0) {
            onUpdateCallback?.()
            const blakeSchoolIds = groupedBySync.synced.map((cleverSchool) => cleverSchool.blakeSchoolId)
            await this.store.query('school-statistic', {
              scope: `districts/${this.session.currentDistrict.id}`,
              filter: { 'school-ids': blakeSchoolIds.join(',') },
            })
          }

          // If there are still unsynced schools, wait a bit before re-polling. Otherwise skip if they're all done now.
          if (unsyncedCleverSchoolIds.length > 0) await timeout(this._cleverSchoolPollingTimeout)
        }

        this.flashQueue.addSuccess({ title: flashTitle, subtitle: intl.t(`${flashPrefix}.success`) })
      } catch (error) {
        const failMessage = intl.t(`${flashPrefix}.fail`)
        flashQueue.addFail({ title: flashTitle, subtitle: failMessage })
        log.error(failMessage, { backendError: error })

        onUpdateCallback?.()
      }
    }),
  )

  /**
   * Use this task to reset the school sync process for a given array of clever school ids.
   *
   * It will make the requests to reset, then query the ember data records repeatedly until all schools are reset and
   * finished initializing.
   *
   * @param string cleverDistrictId - the relevant district id, used to scope the clever school query when polling
   * @param string[] cleverSchoolIds - the clever school ids to reset
   * @param string subType - the subscription type to reset the school for
   * @param function onUpdateCallback - (optional) called periodically so that the UI may update as schools are reset
   */
  resetSchoolsTask = task(
    { drop: true },
    waitFor(async (cleverDistrictId, cleverSchoolIds, subscriptionType, { onUpdateCallback } = {}) => {
      const { intl, flashQueue, log, store } = this

      const flashPrefix = 'clever.flashMessages.resetSchools'
      const flashTitle = intl.t(`${flashPrefix}.title`)

      try {
        // start the process for each school
        for (const cleverSchoolId of cleverSchoolIds) {
          await this.callResetSyncForSchool(cleverSchoolId, subscriptionType)
        }
        flashQueue.addSuccess({ title: flashTitle, subtitle: intl.t(`${flashPrefix}.started`) })

        // now poll, every 5 seconds for updates, until all schools are ready
        let uninitializedCleverSchoolIds = cleverSchoolIds
        while (uninitializedCleverSchoolIds.length > 0) {
          const cleverSchools = await store.query('clever/clever-school', {
            scope: `clever-districts/${cleverDistrictId}`,
            filter: { id: uninitializedCleverSchoolIds },
          })
          // could just check each school is out of the 'init' state (via cleverSchool.isInitializing), but we want to
          // continue polling & wait until each school is completely ready & done any automatching
          const uninitializedCleverSchools = cleverSchools.filter((cleverSchool) => cleverSchool.isMatchingInProgress)
          uninitializedCleverSchoolIds = uninitializedCleverSchools.map((cleverSchool) => cleverSchool.id)

          onUpdateCallback?.()

          // skip waiting if they're all done
          if (uninitializedCleverSchoolIds.length > 0) await timeout(this._cleverSchoolPollingTimeout)
        }

        this.flashQueue.addSuccess({ title: flashTitle, subtitle: intl.t(`${flashPrefix}.success`) })
      } catch (error) {
        const failMessage = intl.t(`${flashPrefix}.fail`)
        flashQueue.addFail({ title: flashTitle, subtitle: failMessage })
        log.error(failMessage, { backendError: error })

        onUpdateCallback?.()
      }
    }),
  )

  async callCompleteSyncForSchool(cleverSchoolId, subscriptionType) {
    const { store } = this
    const cleverAdapter = store.adapterFor('clever/clever-school')
    const { host, namespace } = cleverAdapter

    const url = join(host, namespace, 'clever-schools', cleverSchoolId, 'complete-sync')
    const urlWithQPs = joinQueryParams(url, { 'subscription-type': subscriptionType })

    const { token } = this.authToken
    const contentType = 'application/vnd.api+json'

    const response = await fetch(urlWithQPs, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': contentType,
      },
    })

    if (response?.ok) {
      return true
    } else {
      throw new Error(`Received ${response.status} HTTP error while completing sync for school ${cleverSchoolId}`)
    }
  }

  async callResetSyncForSchool(cleverSchoolId, subscriptionType) {
    const { store } = this
    const cleverAdapter = store.adapterFor('clever/clever-school')
    const { host, namespace } = cleverAdapter

    const url = join(host, namespace, 'clever-schools', cleverSchoolId, 'reset-sync')
    const urlWithQPs = joinQueryParams(url, { 'subscription-type': subscriptionType })

    const { token } = this.authToken
    const contentType = 'application/vnd.api+json'

    const response = await fetch(urlWithQPs, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': contentType,
      },
    })

    if (response?.ok) {
      return true
    } else {
      throw new Error(`Received ${response.status} HTTP error while resetting sync for school ${cleverSchoolId}`)
    }
  }

  findAllExtended(modelName, includes = null, filter = null) {
    const params = {}
    if (includes) params.include = includes
    if (filter) params.filter = filter
    return this.store.query(modelName, params)
  }

  /**
   * Request the clever-teacher-match and clever-student-match and filter by missing fields
   * The finder is a custom query to support jsonApis filters and includes
   * @returns {Promise<{teacherMatches: module:ember.Ember.Array, studentMatches: module:ember.Ember.Array}>}
   */
  async getUserMatchesWithMissingFields(fetchData = true) {
    const { store } = this
    const teacherMatchModel = 'clever/clever-teacher-match'
    const studentMatchModel = 'clever/clever-student-match'
    const cleverDistrictMatch = store.peekAll('clever/clever-district-match')[0]
    const cleverDistrictMatchId = cleverDistrictMatch.id

    if (fetchData) {
      await RSVP.Promise.all([
        this.findAllExtended(teacherMatchModel, 'teacher', {
          'missing-email': true,
          'clever-district-id': cleverDistrictMatchId,
        }),
        this.findAllExtended(studentMatchModel, 'student', {
          'missing-grade': true,
          'clever-district-id': cleverDistrictMatchId,
        }),
      ])
    }

    const teacherMatchesWithMissingEmail =
      store.peekAll(teacherMatchModel).filter(({ missingEmail }) => missingEmail === true) || A()
    const studentMatchesWithMissingGrade =
      store.peekAll(studentMatchModel).filter(({ missingGrade }) => missingGrade === true) || A()

    return {
      teacherMatches: teacherMatchesWithMissingEmail,
      studentMatches: studentMatchesWithMissingGrade,
    }
  }

  /**
   * Load user matches and show a flash message which sticks around until a disco closes it
   *
   * @returns {Promise<void>}
   */
  async checkUserMatchesAndShowMissingFieldNotice(urlToMissingFieldRoute) {
    const { flashQueue, intl } = this
    const matches = await this.getUserMatchesWithMissingFields()

    if (matches?.teacherMatches?.length || matches?.studentMatches?.length) {
      schedule('afterRender', this, () => {
        const title = intl.t('clever.missingFields.title')
        const subtitle = intl.t('clever.missingFields.body', { htmlSafe: true, url: urlToMissingFieldRoute })

        flashQueue.addCaution({ title, subtitle })
      })
    }
  }

  /**
   * Bulk create blake records (teachers or students) from clever data
   *
   * @param {CleverSchoolModel} cleverSchool
   * @param {Array<CleverTeacherModel>|Array<CleverStudentModel>} cleverRecords
   * @param {*} createFunction - the function to use to create a blake record with
   */
  async _bulkCreateBlakeRecords(cleverSchool, cleverRecords, createFunction) {
    // the adapter's bulk manager handles the batching for us. Just call and await them all.
    const createPromises = cleverRecords.map((cleverRecord) => {
      return createFunction(cleverRecord, cleverSchool, { bulk: true }).then(() => {
        this.setCleverUserMatchedState(cleverRecord, true)
      })
    })
    await RSVP.Promise.all(createPromises)
  }

  /**
   * Bulk create teachers from clever data
   * @param  {CleverSchoolModel} cleverSchool
   * @param  {Array<CleverTeacherModel>} cleverTeachers
   */
  async bulkCreateBlakeTeachersFromCleverData(cleverSchool, cleverTeachers) {
    await this._bulkCreateBlakeRecords(cleverSchool, cleverTeachers, this.createBlakeTeacherFromCleverData.bind(this))
  }

  /**
   * Bulk create students from clever data
   * @param  {CleverSchoolModel} cleverSchool
   * @param  {Array<CleverStudentModel>} cleverStudents
   */
  async bulkCreateBlakeStudentsFromCleverData(cleverSchool, cleverStudents) {
    const createFunction = (...args) => this.createBlakeStudentFromCleverData(...args)
    await this._bulkCreateBlakeRecords(cleverSchool, cleverStudents, createFunction)
  }
}
