import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { fn, array } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { isPresent } from '@ember/utils';
import type { SafeString } from '@ember/template';
import { t, type IntlService } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import orderBy from 'lodash/orderBy';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import type ManageSchoolsIndexRoute from 'district-ui-client/routes/subscription-type/manage/schools/index';
import { featureFlag } from 'district-ui-client/helpers/feature-flag';
import { formatValue } from 'district-ui-client/utils/format-value';
import { OverCapacityAlert } from 'district-ui-client/components/over-capacity-alert';
import { Tooltip } from 'district-ui-client/components/tooltip';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { TableContainer, Table, TRHead, TBody, TH, TD } from 'district-ui-client/components/table';
import { linkClassNames } from 'district-ui-client/components/themed-link';
import { Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section';
import { PageTitle } from 'district-ui-client/components/page-title';
import { RolloverAlert } from 'district-ui-client/components/rollover-alert';
import { InputSearch } from 'district-ui-client/components/input-search';
import colspanMax from 'district-ui-client/modifiers/colspan-max';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { hash } from '@ember/helper';
interface SchoolRow {
    schoolId: string;
    schoolName: string;
    schoolCode: string;
    schoolTown: string | SafeString;
    subcoName: string | SafeString;
    subcoNameReversed: string | SafeString;
    subExpiryDate: Date;
    subExpiryFormatted: string;
    subCapacity: number;
    subCapacityFormatted: string;
    subIsOverCapacity: boolean;
    teacherCount: number;
    studentCount: number;
}
type SortKey = keyof SchoolRow;
type SortDir = 'asc' | 'desc';
interface Signature {
    Args: {
        model: ModelFor<ManageSchoolsIndexRoute>;
    };
}
let ManageSchoolsIndexTemplate = class ManageSchoolsIndexTemplate extends Component<Signature> {
    @service
    activeRoute: ActiveRouteService;
    @service
    intl: IntlService;
    get subscriptionType() {
        return this.activeRoute.subscriptionType;
    }
    @tracked
    search = '';
    @tracked
    sorts: `${SortKey}:${SortDir}`[] = [
        'schoolName:asc'
    ];
    get sortKey() {
        return this.sorts[0].split(':')[0] as SortKey;
    }
    get sortDirection() {
        return this.sorts[0].split(':')[1] as SortDir;
    }
    get sortIcon(): IconName {
        switch(this.sortDirection){
            case 'asc':
                return 'sort-up';
            case 'desc':
                return 'sort-down';
            default:
                return 'sort';
        }
    }
    get sortedRows() {
        const properties1 = this.sorts.map((sort1)=>sort1.split(':')[0]);
        const directions1 = this.sorts.map((sort1)=>sort1.split(':')[1]);
        assert('unknown sort direction', directions1.every((d1)=>d1 === 'asc' || d1 === 'desc'));
        return orderBy(this.rows, properties1, directions1);
    }
    get rows(): SchoolRow[] {
        const { subscriptionType: subscriptionType1 } = this;
        return this.args.model.schools.map((school1)=>{
            const utilisation1 = school1.utilisationFor(subscriptionType1);
            if (!utilisation1) return null // manage schools should only be active schools (schools with utilisation record)
            ;
            const subcoRecord1 = school1.subscriptionCoordinators?.find((subco1)=>subco1.isForSubscriptionType(subscriptionType1));
            const schoolStat1 = this.args.model.schoolStats.find((schoolStat1)=>schoolStat1.id === school1.id);
            const teacherCount1 = schoolStat1?.teacherCountFor(subscriptionType1) ?? 0;
            const studentCount1 = schoolStat1?.studentCountFor(subscriptionType1) ?? 0;
            return {
                schoolId: school1.id,
                schoolName: school1.name,
                schoolCode: school1.schoolCode,
                schoolTown: formatValue(school1.contactDetail?.town),
                subcoName: formatValue(subcoRecord1?.teacher?.fullName, {
                    replacement: this.intl.t('na')
                }),
                subcoNameReversed: formatValue(subcoRecord1?.teacher?.fullNameReversed, {
                    replacement: this.intl.t('na')
                }),
                subExpiryDate: utilisation1.endDate,
                subExpiryFormatted: this.intl.formatDate(utilisation1.endDate, {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                }),
                subCapacity: utilisation1.licenceCount,
                subCapacityFormatted: utilisation1.formattedCapacity,
                subIsOverCapacity: utilisation1.isOverCapacity,
                teacherCount: teacherCount1,
                studentCount: studentCount1
            };
        }).filter(isPresent);
    }
    get filteredContent() {
        const lowerCaseSearch1 = this.search.toLowerCase();
        return this.sortedRows.filter((row1)=>[
                row1.schoolName,
                row1.schoolCode,
                row1.schoolTown,
                row1.subcoName,
                row1.subcoNameReversed
            ].join(' ').toLowerCase().includes(lowerCaseSearch1));
    }
    setSearch = (value1: string)=>{
        this.search = value1;
    };
    /**
   * Updates the sort direction and properties for schools
   */ sortBy = (property1: SortKey)=>{
        // Only supports 1 column, but can be extended.
        const [sortProperty1, sortDirection1] = this.sorts[0].split(':') as [SortKey, SortDir];
        if (sortProperty1 === property1) {
            this.sorts = [
                `${property1}:${sortDirection1 === 'asc' ? 'desc' : 'asc'}`
            ];
        } else {
            this.sorts = [
                `${property1}:asc`
            ];
        }
    };
    static{
        template(`
    <PageTitle>{{t "subscriptionType.manageSchools.index.manageSchools"}}</PageTitle>

    <div class="mt-8 space-y-6">
      <RolloverAlert />
      <OverCapacityAlert @schools={{@model.schools}} @subscriptionType={{this.subscriptionType}} />

      <Panel>
        <PanelHeader>
          <div class="flex items-center justify-between gap-4">
            <PanelTitle>{{t "schools"}}</PanelTitle>
            <div>
              <InputSearch
                aria-label={{t "searchPlaceholder.schools"}}
                placeholder={{t "searchPlaceholder.schools"}}
                @value={{this.search}}
                @updateValue={{this.setSearch}}
              />
            </div>
          </div>
        </PanelHeader>
        <PanelBody @tight={{true}}>
          <TableContainer>
            <Table @minWidth="min-w-[68rem]">
              <thead>
                <TRHead>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "schoolName")}} data-test-school-name-col>
                    {{t "subscriptionType.manageSchools.index.name"}}
                    <FaIcon @icon={{if (eq this.sortKey "schoolName") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "schoolTown")}}>
                    {{t "subscriptionType.manageSchools.index.city"}}
                    <FaIcon @icon={{if (eq this.sortKey "schoolTown") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "schoolCode")}} class="w-32">
                    {{t "subscriptionType.manageSchools.index.code"}}
                    <FaIcon @icon={{if (eq this.sortKey "schoolCode") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "subcoNameReversed")}} class="w-48">
                    {{t "subscriptionType.manageSchools.index.subscriptionCoordinator" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "subcoNameReversed") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "subExpiryDate")}} class="w-32">
                    {{t "subscriptionType.manageSchools.index.subscriptionExpiry" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "subExpiryDate") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "subCapacity")}} class="w-32">
                    {{t "subscriptionType.manageSchools.index.subscriptionCapacity" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "subCapacity") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "teacherCount")}} class="w-36">
                    {{t "subscriptionType.manageSchools.index.numberOfTeachers" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "teacherCount") this.sortIcon "sort"}} />
                  </TH>
                  <TH @clickable={{true}} {{on "click" (fn this.sortBy "studentCount")}} class="w-36">
                    {{t "subscriptionType.manageSchools.index.numberOfStudents" htmlSafe=true}}
                    <FaIcon @icon={{if (eq this.sortKey "studentCount") this.sortIcon "sort"}} />
                  </TH>
                </TRHead>
              </thead>
              <TBody>
                {{#each this.filteredContent as |row index|}}
                  <tr data-test-school-row={{index}}>
                    <TD data-test-school-name>
                      <LinkTo
                        class={{linkClassNames}}
                        @route="subscription-type.manage.schools.edit"
                        @models={{array this.subscriptionType row.schoolId}}
                      >
                        {{row.schoolName}}
                      </LinkTo>
                    </TD>
                    <TD data-test-school-city>{{row.schoolTown}}</TD>
                    <TD data-test-school-code>{{row.schoolCode}}</TD>
                    <TD data-test-school-subco-name>{{row.subcoNameReversed}}</TD>
                    <TD data-test-school-expiry-date>{{row.subExpiryFormatted}}</TD>
                    <TD
                      data-test-school-subscription-capacity
                      @textColor={{if row.subIsOverCapacity "text-orange-400"}}
                    >
                      <span>
                        {{row.subCapacityFormatted}}
                        {{#if row.subIsOverCapacity}}
                          <FaIcon @icon="triangle-exclamation" />
                          <Tooltip @text={{t "subscriptionType.manageSchools.index.overCapacityTooltip"}} />
                        {{/if}}
                      </span>
                    </TD>
                    <TD data-test-school-teachers-link>
                      <LinkTo
                        class={{linkClassNames}}
                        @route="subscription-type.manage.schools.teachers.index"
                        @models={{array row.schoolId}}
                        data-test-manage-schools-teacher-link
                      >
                        {{t "subscriptionType.manageSchools.index.teachers" count=row.teacherCount}}
                      </LinkTo>
                    </TD>
                    <TD data-test-school-students-link>
                      {{#if (featureFlag "duiUseManageStudentsFF")}}
                        <LinkTo
                          class={{linkClassNames}}
                          @route="subscription-type.manage.students"
                          {{! clear out any other QPs that might have been previously set }}
                          @query={{hash
                            schoolId=row.schoolId
                            teacherId=""
                            schoolClassId=""
                            studentFirstName=""
                            studentLastName=""
                          }}
                        >
                          {{t "subscriptionType.manageSchools.index.students" count=row.studentCount}}
                        </LinkTo>
                      {{else}}
                        <LinkTo
                          class={{linkClassNames}}
                          @route="subscription-type.manage.schools.students.index"
                          @models={{array row.schoolId}}
                        >
                          {{t "subscriptionType.manageSchools.index.students" count=row.studentCount}}
                        </LinkTo>
                      {{/if}}
                    </TD>
                  </tr>
                {{else}}
                  <tr>
                    <TD {{colspanMax}}>
                      {{t "noResults"}}
                    </TD>
                  </tr>
                {{/each}}
              </TBody>
            </Table>
          </TableContainer>
        </PanelBody>
      </Panel>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ManageSchoolsIndexTemplate);
