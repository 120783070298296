import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { SummaryTableSchools } from 'district-ui-client/components/summary-tables/schools';
import type School from 'district-ui-client/models/school';
import type ReportingMathsSummaryTableRoute from 'district-ui-client/routes/reporting/ui-scope/maths/summary/table';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<ReportingMathsSummaryTableRoute>;
    };
}
export class ReportingMathsSummaryTableRouteTemplate extends Component<Signature> {
    get schools() {
        const uiScope1 = this.args.model.scope;
        if (uiScope1.scope === 'district') return this.args.model.scopedData.schools;
        return this.args.model.scopedData.schools.filter((school1: School)=>school1.id === uiScope1.id);
    }
    static{
        template(`
    <DetailedPageWrapper @viewMode="table">
      <div
        class="overflow-hidden rounded-lg shadow-[0_4px_12px_0_rgba(18,17,16,0.1)] print:rounded-none print:shadow-none"
      >
        <SummaryTableSchools
          @product={{@model.product}}
          @schools={{this.schools}}
          @schoolsData={{@model.simpleSummary.simple_summary.schools}}
          @averagesAndTotalsData={{@model.simpleSummary.simple_summary.district}}
        />
      </div>
    </DetailedPageWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingMathsSummaryTableRouteTemplate);
