import Modifier from 'ember-modifier'
import { inject as service } from '@ember/service'
import type { ArgsFor } from 'ember-modifier'
import type Owner from '@ember/owner'
import { registerDestructor } from '@ember/destroyable'
import type Viewport from '../viewport/service.ts'

interface Signature {
  Args: {
    Positional: []
    Named: {
      propertyName?: unknown
    }
  }
  Element: HTMLElement
}

/**
 * This can be called to track the viewport height in a CSS property or
 * variable by listening for resize events.  This method does nothing if it's
 * called more than once.
 */
export default class BindViewportHeight extends Modifier<Signature> {
  @service('device/viewport') declare viewport: Viewport

  declare elementToResize: HTMLElement

  declare propertyName: string

  constructor(owner: Owner, args: ArgsFor<Signature>) {
    super(owner, args)

    registerDestructor(this, () => {
      this.viewport.off('deviceResize', this, this._setViewportHeightProperty)
    })
  }

  override modify(
    element: Signature['Element'],
    _positional: Signature['Args']['Positional'],
    named: Signature['Args']['Named'],
  ) {
    this.elementToResize = element
    if (typeof named.propertyName !== 'string')
      throw new Error(
        `device/bind-viewport-height requires a string "propertyName" argument (got ${typeof named.propertyName})`,
      )
    this.propertyName = named.propertyName

    this._setViewportHeightProperty()
    this.viewport.on('deviceResize', this, this._setViewportHeightProperty)
  }

  /**
   * This sets a CSS property to the visible viewport height.  In extreme
   * circumstances (looking at you, iPhone), these properties can then be used
   * (judiciously) to position elements within the viewport.
   */
  _setViewportHeightProperty = () => {
    if (this.elementToResize instanceof HTMLElement) {
      this.elementToResize.style.setProperty(
        this.propertyName,
        `${this.viewport.height}px`,
      )
    }
  }
}
