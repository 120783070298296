import { action } from '@ember/object'
import { service } from '@ember/service'
import Controller from '@ember/controller'

export default class ImportTeachersController extends Controller {
  @service store

  @service activeRoute

  @service fileDownload

  @service intl

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  /**
   * Generates a sample teacher Import CSV array
   *
   * @return {string[][]} data array for csv
   */
  generateTeacherSampleImportData() {
    const fieldPrefix = 'manage.teachers.sampleCsv.fields'

    const row = [
      this.intl.t(`${fieldPrefix}.firstName`),
      this.intl.t(`${fieldPrefix}.lastName`),
      this.intl.t(`${fieldPrefix}.teacherEmail`),
      this.intl.t(`${fieldPrefix}.trialIndicator`),
      this.intl.t(`${fieldPrefix}.schoolCode`),
    ]

    return Array.from({ length: 4 }, () => row)
  }

  @action
  downloadSampleCsv() {
    const data = this.generateTeacherSampleImportData()
    const filename = this.intl.t('manage.teachers.sampleCsv.filename')
    this.fileDownload.downloadAsCsv(data, { filename })
  }
}
