import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class AddExistingTeachersRoute extends Route {
  @service store

  @service session

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type')
    // get all teachers where school has this product, but teacher does not have this product
    await this.store.query('school', {
      scope: `districts/${this.session.currentDistrict.id}`,
      // need ALL school-classes, in order to know exactly what sub-types a teacher has classes for.
      include: 'school-classes,teachers,teachers.school-classes,school-subscription-utilisations',
    })
    // get teachers for school of subtype that dont have classes for that subtype
    const teachersWithoutSubType = this.store.peekAll('teacher').filter((t) => {
      const schoolHasSubType = t.school.utilisationFor(subscriptionType)
      const teacherHasSubType = t.schoolClasses.some((schoolClass) => schoolClass.subscriptionType === subscriptionType)
      return schoolHasSubType && !teacherHasSubType
    })
    return { teachersWithoutSubType, subscriptionType }
  }
}
