import { template } from "@ember/template-compiler";
import { MultiSelect as BaseMultiSelect } from '@blakeelearning/ember-select/components/multi-select';
import { t } from 'ember-intl';
import { not } from 'ember-truth-helpers';
import type { SelectOption } from '@blakeelearning/ember-select';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TOC } from '@ember/component/template-only';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import PopoverStandardDropdown from '@blakeelearning/popovers/components/popover/dropdowns/standard';
import { MakeListbox } from '@blakeelearning/ember-select/modifiers/make-listbox';
import { MakeSearchInput } from '@blakeelearning/ember-select/modifiers/make-search-input';
import { MakeCheckboxOption } from '@blakeelearning/ember-select/modifiers/make-checkbox-option';
import { ThemedDropdownButton, ThemedDropdownContent, type DropdownButtonStyle } from 'district-ui-client/components/themed-dropdown';
import { ThemedMenu, ThemedMenuGroup, ThemedMenuItem } from 'district-ui-client/components/themed-menu';
import groupBy from 'lodash/groupBy';
import { isPresent } from '@ember/utils';
interface Signature {
    Element: HTMLButtonElement;
    Args: {
        options: SelectOption[];
        selectedItems: SelectOption['value'][];
        /** Provided with selection immediately. Mutually exclusive with onCommit */ onSelect?: (selected: SelectOption['value'][]) => void;
        /** Provided with selection only on closing the dropdown. Mutually exclusive with onSelect */ onCommit?: (selected: SelectOption['value'][]) => void;
        style: DropdownButtonStyle;
        placeholder: string;
        searchPlaceholder?: string;
        beforeLabel?: string;
        selectAllLabel?: string;
        matchTriggerWidth?: boolean;
        disabled?: boolean;
    };
}
export function grouper(options1: SelectOption[]) {
    return groupBy(options1, (opt1)=>(isPresent(opt1.group) ? opt1.group : '')) // treat nullable as empty string
    ;
}
export class MultiSelect extends Component<Signature> {
    @tracked
    private selected?: SelectOption['value'][];
    // display saved selected items for commit, else display selected items exactly as given from args
    get selectedItems() {
        return this.selected ?? this.args.selectedItems;
    }
    onSelect = (selected1: SelectOption['value'][])=>{
        // call the handler immediately with new selection else keep the selection for commit
        if (this.args.onSelect) {
            this.args.onSelect(selected1);
        } else {
            this.selected = selected1;
        }
    };
    onClose = ()=>{
        // call the commit handler if there were changes made, then clear
        if (this.args.onCommit && this.selected) {
            this.args.onCommit(this.selectedItems);
            this.selected = undefined;
        }
    };
    static{
        template(`
    <PopoverStandardDropdown
      @matchTriggerWidth={{@matchTriggerWidth}}
      @disabled={{@disabled}}
      @onClose={{this.onClose}}
      as |dropdown status|
    >
      <BaseMultiSelect
        @options={{@options}}
        @selectedItems={{this.selectedItems}}
        @onSelect={{this.onSelect}}
        as |state|
      >
        <ThemedDropdownButton
          {{dropdown.makeTrigger}}
          @disabled={{@disabled}}
          @style={{@style}}
          class="inline-flex w-full items-center justify-between {{if status.isOpen 'active'}}"
          ...attributes
        >
          <span data-test-placeholder class="truncate text-left font-medium">{{@placeholder}}</span>
          <FaIcon @icon="chevron-down" class="ml-2" />
        </ThemedDropdownButton>
        <dropdown.content>
          {{! container with round corners, then scroller, then padded content }}
          <ThemedDropdownContent data-test-dropdown-content @constrainWidth={{not @matchTriggerWidth}}>
            <div class="max-h-[45vh] overflow-auto">
              <ThemedMenu class="flex flex-col" {{MakeListbox}}>
                {{#if @searchPlaceholder}}
                  <div class="mb-2 flex rounded-l-full rounded-r-full bg-neutral-50 px-3 py-2">
                    <input
                      class="placeholder:text-neutral-250 w-full bg-neutral-50 text-sm text-neutral-400 outline-none ring-0"
                      placeholder={{@searchPlaceholder}}
                      type="search"
                      aria-label={{@searchPlaceholder}}
                      {{MakeSearchInput state}}
                    />
                    <FaIcon @icon="search" />
                  </div>
                {{/if}}
                {{#if @beforeLabel}}<div class="text-neutral-250 px-3 py-2 text-sm">{{@beforeLabel}}</div>{{/if}}
                {{#if @selectAllLabel}}
                  {{#unless state.searchTerm}}
                    <ThemedMenuGroup>
                      <CheckboxOption class="font-semibold" data-select-check-all {{MakeCheckboxOption state}}>
                        {{@selectAllLabel}}
                      </CheckboxOption>
                    </ThemedMenuGroup>
                  {{/unless}}
                {{/if}}
                {{#each-in (grouper state.options) as |groupLabel options|}}
                  <ThemedMenuGroup @label={{groupLabel}}>
                    {{#each options as |option|}}
                      <CheckboxOption
                        class="font-normal"
                        data-select-check-option={{option.value}}
                        {{MakeCheckboxOption state option}}
                      >
                        {{option.label}}
                      </CheckboxOption>
                    {{/each}}
                  </ThemedMenuGroup>
                {{else}}
                  <ThemedMenuGroup>
                    <li class="px-3 py-2 text-sm">{{t "noResultsFound"}}</li>
                  </ThemedMenuGroup>
                {{/each-in}}
              </ThemedMenu>
            </div>
          </ThemedDropdownContent>
        </dropdown.content>
      </BaseMultiSelect>
    </PopoverStandardDropdown>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default MultiSelect;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        MultiSelect: typeof MultiSelect;
    }
}
const CheckboxOption: TOC<{
    Element: HTMLLIElement;
    Blocks: {
        default: [];
    };
}> = template(`
  <ThemedMenuItem ...attributes>
    <span class="flex items-center space-x-2">
      {{! no uniqueId helper in ember 4.x to use aria-labelledby with }}
      {{! template-lint-disable require-input-label }}
      <input type="checkbox" class="mr-3 h-4 w-4 cursor-pointer" />
      {{yield}}
    </span>
  </ThemedMenuItem>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
