import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
interface Args {
    progressPercent?: number;
}
interface Signature {
    Args: Args;
    Element: HTMLDivElement;
}
export class UploadProgress extends Component<Signature> {
    get progressBarStyle() {
        return htmlSafe(`width: ${this.args.progressPercent ?? 0}%`);
    }
    static{
        template(`
    <div class="bg-dusty-black-50 h-6 overflow-hidden rounded-md" ...attributes>
      <div
        class="max-w-100% bg-oceany-blue-300 h-full text-center text-white"
        role="progressbar"
        style={{this.progressBarStyle}}
      >
        {{@progressPercent}}%
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default UploadProgress;
