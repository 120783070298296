import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { ReportingMathsCourseProgressAveragesRoute } from 'district-ui-client/routes/reporting/ui-scope/maths/course-progress/averages';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import { ChartsCourseProgressAverages } from 'district-ui-client/components/charts/course-progress/averages/component';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<ReportingMathsCourseProgressAveragesRoute>;
    };
}
export const ReportingMathsCourseProgressAveragesRouteTemplate: TOC<Signature> = template(`
  <DetailedPageWrapper @empty={{not @model.courseProgressAverages}}>
    {{#each @model.courseProgressAverages.course_progress_averages as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsCourseProgressAverages
          class="h-full"
          @data={{gradeData}}
          @precinct="lessons"
          @product={{@model.product}}
          @tooltip={{t "reporting.tooltips.courseProgressAverages"}}
          @title={{t "reporting.chartTitles.courseProgressAverages"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReportingMathsCourseProgressAveragesRouteTemplate);
