import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { ReportingPane } from 'district-ui-client/components/pane/reporting';
import type UiScopeRoute from 'district-ui-client/routes/reporting/ui-scope';
import type ReportingUiScopeController from 'district-ui-client/controllers/reporting/ui-scope';
import { InlineDrawer } from 'district-ui-client/components/inline-drawer';
import Component from '@glimmer/component';
interface UiScopePageSignature {
    Args: {
        model: ModelFor<UiScopeRoute>;
        controller: ReportingUiScopeController;
    };
}
export class UiScopePage extends Component<UiScopePageSignature> {
    inlineDrawerElement = document.getElementById('inline-drawer');
    toggleNav = ()=>{
        this.args.controller.toggleNav();
    };
    static{
        template(`
    {{#if this.inlineDrawerElement}}
      {{#in-element this.inlineDrawerElement}}
        <InlineDrawer @isOpen={{@controller.drawer}} @onClose={{this.toggleNav}}>
          <ReportingPane @uiScope={{@model.uiScope}} />
        </InlineDrawer>
      {{/in-element}}
    {{/if}}
    <div class="px-8 py-10">
      {{outlet}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(UiScopePage);
