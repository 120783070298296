import Route from '@ember/routing/route'

export default class SubscriptionTypeError404Route extends Route {
  // keep it simple
  setupController(controller, model) {
    const { subscriptionType } = this.modelFor('subscription-type')
    controller.subscriptionType = subscriptionType
    super.setupController(controller, model)
  }
}
