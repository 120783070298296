import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { t, type IntlService } from 'ember-intl';
import { on } from '@ember/modifier';
import type Owner from '@ember/owner';
import { getOwner, setOwner } from '@ember/owner';
import { assert } from '@ember/debug';
import { fn } from '@ember/helper';
import { task } from 'ember-concurrency';
import { not, or } from 'ember-truth-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { Tooltip } from 'district-ui-client/components/tooltip';
import { PageTitle } from 'district-ui-client/components/page-title';
import { Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section';
import type SessionService from 'district-ui-client/services/session';
import type FlashQueueService from 'district-ui-client/services/flash-queue';
import { hasPasswordIncorrectError } from 'district-ui-client/errors/password-incorrect-error';
import type { Log } from '@blakeelearning/log';
import type DistrictCoordinator from 'district-ui-client/models/district-coordinator';
import { FormItemHorizontal, TextField } from 'district-ui-client/components/base/form';
import { eventValue } from 'district-ui-client/helpers/event-value';
import { ThemedButton, ThemedLink } from 'district-ui-client/components/themed-button';
import { DividerHorizontal } from 'district-ui-client/components/divider-horizontal';
const minPasswordLength = 4;
/**
 * We wrap in a changeset class (rather than editing the record directly) so that we don't need to rollback when leaving
 */ let DiscoChangeset = class DiscoChangeset {
    @service
    intl: IntlService;
    disco: DistrictCoordinator;
    // keys that are applied to the disco model on save
    discoKeys = [
        'login',
        'firstName',
        'lastName',
        'email'
    ] as const;
    // changeset keys - superset of discokeys
    keys = [
        ...this.discoKeys,
        'passwordCurrent',
        'password',
        'passwordConfirmation'
    ] as const;
    @tracked
    login = '';
    @tracked
    firstName = '';
    @tracked
    lastName = '';
    @tracked
    email = '';
    @tracked
    passwordCurrent = '';
    @tracked
    password = '';
    @tracked
    passwordConfirmation = '';
    constructor(context1: object, disco1: DistrictCoordinator){
        const owner1 = getOwner(context1);
        assert('missing owner for changeset', owner1);
        setOwner(this, owner1);
        this.disco = disco1;
        // set initial values
        this.discoKeys.forEach((key1)=>(this[key1] = this.disco[key1]));
    }
    save(saveOptions1?: Record<string, unknown>) {
        this.discoKeys.forEach((key1)=>(this.disco[key1] = this[key1]));
        return this.disco.save(saveOptions1);
    }
    get isPasswordModified() {
        return Boolean(this.passwordCurrent || this.password || this.passwordConfirmation);
    }
    get hasPasswordCurrent() {
        return Boolean(this.passwordCurrent);
    }
    get isPasswordConfirmed() {
        return this.password === this.passwordConfirmation;
    }
    get isPasswordLongEnough() {
        return this.password.length >= minPasswordLength;
    }
    get validationErrors(): string[] {
        let validationErrors1: string[] = [];
        if (!this.isPasswordModified) return validationErrors1;
        if (!this.hasPasswordCurrent) {
            validationErrors1 = [
                ...validationErrors1,
                this.intl.t('districtCoordinator.validationErrors.passwordMissing')
            ];
        }
        if (!this.isPasswordConfirmed) {
            validationErrors1 = [
                ...validationErrors1,
                this.intl.t('districtCoordinator.validationErrors.passwordMustMatch')
            ];
        }
        if (!this.isPasswordLongEnough) {
            validationErrors1 = [
                ...validationErrors1,
                this.intl.t('districtCoordinator.validationErrors.paswordNotLongEnough', {
                    minPasswordLength
                })
            ];
        }
        return validationErrors1;
    }
};
let DistrictCoordinatorPage = class DistrictCoordinatorPage extends Component<unknown> {
    @service
    session: SessionService;
    @service
    flashQueue: FlashQueueService;
    @service
    intl: IntlService;
    @service
    log: Log;
    @tracked
    changeset: DiscoChangeset;
    @tracked
    isEditing = false;
    constructor(owner1: Owner, args1: Record<string, never>){
        super(owner1, args1);
        this.resetChangeset();
    }
    startEdit = ()=>{
        this.isEditing = true;
    };
    save = task({
        drop: true
    }, async (event1: Event)=>{
        event1.preventDefault();
        if (this.changeset.validationErrors.length) {
            this.flashQueue.addFail({
                subtitle: this.changeset.validationErrors.join(', ')
            });
            return;
        }
        try {
            if (this.changeset.isPasswordModified) {
                // send the passwords through the serializer
                const { passwordCurrent: passwordCurrent1, password: password1, passwordConfirmation: passwordConfirmation1 } = this.changeset;
                await this.changeset.save({
                    adapterOptions: {
                        passwordCurrent: passwordCurrent1,
                        password: password1,
                        passwordConfirmation: passwordConfirmation1
                    }
                });
            } else {
                await this.changeset.save();
            }
            this.flashQueue.addSuccess({
                title: this.intl.t('districtCoordinator.saveSuccess')
            });
            this.stopEdit();
        } catch (error1) {
            if (hasPasswordIncorrectError(error1)) {
                this.flashQueue.addFail({
                    title: this.intl.t('districtCoordinator.saveFailedIncorrectPassword')
                });
                return;
            }
            this.log.error('failed to save disco on district-coordinator page', error1 ?? undefined);
            this.flashQueue.addFail({
                title: this.intl.t('districtCoordinator.saveFailedUnknown')
            });
            return;
        }
    });
    reset = (event1: Event)=>{
        event1.preventDefault();
        this.stopEdit();
    };
    stopEdit = ()=>{
        this.isEditing = false;
        this.resetChangeset();
    };
    resetChangeset = ()=>{
        this.changeset = new DiscoChangeset(this, this.session.currentDisco);
    };
    setDiscoValue = (key1: DiscoChangeset['keys'][number], value1: string)=>{
        this.changeset[key1] = value1;
    };
    static{
        template(`
    <div class="mx-auto w-full max-w-screen-lg space-y-8">
      <PageTitle>{{t "districtCoordinator.myDetails"}}</PageTitle>

      <Panel>
        <PanelHeader>
          <PanelTitle>{{t "districtCoordinator.changeMyDetails"}}</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <form {{on "submit" this.save.perform}} {{on "reset" this.reset}} data-test-disco-form>
            <div class="space-y-2">
              <FormItemHorizontal @label={{t "districtCoordinator.login"}}>
                <TextField
                  data-test-disco-login
                  value={{this.changeset.login}}
                  @readOnly={{not this.isEditing}}
                  {{on "input" (eventValue (fn this.setDiscoValue "login"))}}
                />
              </FormItemHorizontal>
              <FormItemHorizontal @label={{t "districtCoordinator.firstName"}}>
                <TextField
                  data-test-disco-first-name
                  value={{this.changeset.firstName}}
                  @readOnly={{not this.isEditing}}
                  {{on "input" (eventValue (fn this.setDiscoValue "firstName"))}}
                />
              </FormItemHorizontal>
              <FormItemHorizontal @label={{t "districtCoordinator.lastName"}}>
                <TextField
                  data-test-disco-last-name
                  value={{this.changeset.lastName}}
                  @readOnly={{not this.isEditing}}
                  {{on "input" (eventValue (fn this.setDiscoValue "lastName"))}}
                />
              </FormItemHorizontal>
              <FormItemHorizontal @label={{t "districtCoordinator.email"}}>
                <TextField
                  data-test-disco-email
                  value={{this.changeset.email}}
                  @readOnly={{not this.isEditing}}
                  {{on "input" (eventValue (fn this.setDiscoValue "email"))}}
                />
              </FormItemHorizontal>
            </div>
            <DividerHorizontal class="my-6" />
            <div class="space-y-2">
              <FormItemHorizontal @label={{t "districtCoordinator.passwordCurrent"}}>
                <TextField
                  data-test-disco-pw-current
                  value={{if this.isEditing this.changeset.passwordCurrent "********"}}
                  type="password"
                  @readOnly={{not this.isEditing}}
                  {{on "input" (eventValue (fn this.setDiscoValue "passwordCurrent"))}}
                />
              </FormItemHorizontal>
              <FormItemHorizontal @label={{t "districtCoordinator.password"}}>
                <TextField
                  data-test-disco-pw
                  value={{if this.isEditing this.changeset.password "********"}}
                  type="password"
                  @readOnly={{not this.isEditing}}
                  {{on "input" (eventValue (fn this.setDiscoValue "password"))}}
                />
              </FormItemHorizontal>
              <FormItemHorizontal @label={{t "districtCoordinator.passwordConfirmation"}}>
                <TextField
                  data-test-disco-pw-confirmation
                  value={{if this.isEditing this.changeset.passwordConfirmation "********"}}
                  type="password"
                  @readOnly={{not this.isEditing}}
                  {{on "input" (eventValue (fn this.setDiscoValue "passwordConfirmation"))}}
                />
              </FormItemHorizontal>
              <ThemedLink @style="neutral" href="/mfa/status" target="_blank" rel="noopener noreferrer" class="ml-auto">
                {{t "twoFactorAuth.label"}}
                <Tooltip @text={{t "twoFactorAuth.tooltip"}} />
              </ThemedLink>
            </div>
            <DividerHorizontal class="my-6" />
            <div class="space-y-2">
              <FormItemHorizontal @label={{t "districtCoordinator.districtCode"}}>
                <TextField data-test-district-code value={{this.session.currentDistrict.code}} @readOnly={{true}} />
              </FormItemHorizontal>
              <FormItemHorizontal @label={{t "districtCoordinator.country"}}>
                <TextField
                  data-test-disco-country
                  value={{or this.session.currentDisco.countryName this.session.currentDisco.countryCode}}
                  @readOnly={{true}}
                />
              </FormItemHorizontal>
            </div>
            <div class="mt-6 flex gap-2">
              {{#if this.isEditing}}
                <ThemedButton @style="neutral" type="reset">{{t "cancel"}}</ThemedButton>
                <ThemedButton @style="theme" type="submit">{{t "save"}}</ThemedButton>
              {{else}}
                <ThemedButton data-test-edit @style="theme" {{on "click" this.startEdit}}>
                  <FaIcon @icon="pencil" />
                  {{t "editDetails"}}
                </ThemedButton>
              {{/if}}
            </div>
          </form>
        </PanelBody>
      </Panel>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(DistrictCoordinatorPage);
