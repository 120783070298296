import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import BlakeColours from '@blakeelearning/blake-colours/colours';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { formatNumber, formatFixedDecimal } from 'district-ui-client/utils/giraffe/formatter';
import { formattedDuration } from 'district-ui-client/utils/format-duration';
import { roundAccurately } from 'district-ui-client/utils/round';
import { type IntlService, t } from 'ember-intl';
import { FigureBoxes, type FigureBoxTheme } from 'district-ui-client/components/figure-boxes';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component';
import type { TooltipFormatterContextObject } from 'highcharts';
import type { SeriesColumnOptions } from 'highcharts';
import type { TitleOptions } from 'highcharts';
import type { UIScope } from 'district-ui-client/domain/ui-scope';
import type Store from '@ember-data/store';
const secondsInAnHour = 60 * 60;
/**
 * Display a chart of time on task usage
 */ export interface TimeOnTaskUsageData {
    summary: {
        student_count: number;
        total_seconds: number;
        average_seconds_per_student: Nullable<number>;
        session_count: number;
        average_sessions_per_student: Nullable<number>;
        average_seconds_per_student_per_week: Nullable<number>;
        average_seconds_per_session: Nullable<number>;
    };
    months: {
        month: string;
        seconds: Nullable<number>;
    }[];
}
interface Signature {
    Args: {
        data?: TimeOnTaskUsageData;
        uiScope: UIScope;
        mainTheme?: 'blue' | 'green';
    };
    Element: HTMLDivElement;
}
export class TimeOnTaskUsageChart extends Component<Signature> {
    @service
    intl: IntlService;
    @service
    store: Store;
    get scopeName() {
        return this.args.uiScope.scope === 'school' ? this.intl.t('school') : this.intl.t('district');
    }
    get activeScopeName() {
        const { uiScope: uiScope1 } = this.args;
        if (uiScope1.scope === 'district') {
            return this.store.peekRecord('district', uiScope1.id)?.name ?? '';
        }
        return this.store.peekRecord('school', uiScope1.id)?.name ?? '';
    }
    get contributingStudents() {
        return this.args.data?.summary.student_count;
    }
    get totalsStats() {
        const siteCopyStatBoxPrefix1 = 'reporting.statBoxes.timeOnTaskUsage';
        const summaryData1 = this.args.data?.summary;
        const theme1: FigureBoxTheme = this.args.mainTheme ?? 'default';
        const totalSeconds1 = summaryData1?.total_seconds ?? 0;
        const averageSecondsPerStudent1 = summaryData1?.average_seconds_per_student ?? null;
        const averageSecondsPerWeek1 = summaryData1?.average_seconds_per_student_per_week ?? null;
        return {
            data: [
                {
                    label: `${this.scopeName} ${this.intl.t(`${siteCopyStatBoxPrefix1}.totalHours`)}`,
                    value: formatNumber(totalSeconds1 / secondsInAnHour, 1)
                },
                {
                    label: this.intl.t(`${siteCopyStatBoxPrefix1}.avgHoursPerStudent`),
                    value: averageSecondsPerStudent1 ? formatFixedDecimal(averageSecondsPerStudent1 / secondsInAnHour, 1) : '-'
                },
                {
                    label: this.intl.t(`${siteCopyStatBoxPrefix1}.avgHoursPerStudentPerWeek`),
                    value: averageSecondsPerWeek1 ? formatFixedDecimal(averageSecondsPerWeek1 / secondsInAnHour, 1) : '-'
                }
            ],
            title: `${this.activeScopeName} ${this.intl.t('reporting.chartTitles.timeOnTaskTotals')}`,
            theme: theme1
        };
    }
    get sessionsStats() {
        const { intl: intl1 } = this;
        const siteCopyStatBoxPrefix1 = 'reporting.statBoxes.timeOnTaskUsage';
        const summaryData1 = this.args.data?.summary;
        const totalSessions1 = summaryData1?.session_count ?? 0;
        const averageSessionsPerStudent1 = summaryData1?.average_sessions_per_student ?? null;
        const averageSecondsPerSession1 = summaryData1?.average_seconds_per_session ?? null;
        const theme1: FigureBoxTheme = 'orange';
        return {
            data: [
                {
                    label: `${this.scopeName} ${intl1.t(`${siteCopyStatBoxPrefix1}.totalSessions`)}`,
                    value: formatNumber(totalSessions1, 1)
                },
                {
                    label: intl1.t(`${siteCopyStatBoxPrefix1}.avgSessionsPerStudent`),
                    value: averageSessionsPerStudent1 ? formatNumber(averageSessionsPerStudent1, 1) : '-'
                },
                {
                    label: intl1.t(`${siteCopyStatBoxPrefix1}.avgTimeInSession`),
                    value: formattedDuration(averageSecondsPerSession1, '-')
                }
            ],
            title: intl1.t('reporting.chartTitles.timeOnTaskSessions'),
            theme: theme1
        };
    }
    get chartDataColor() {
        const { mainTheme: mainTheme1 } = this.args;
        if (!mainTheme1) return BlakeColours.dustyBlack100;
        return {
            blue: BlakeColours.oceanyBlue300,
            green: BlakeColours.grapeyGreen300
        }[mainTheme1];
    }
    get columnChartData(): SeriesColumnOptions[] {
        const monthData1 = this.args.data?.months;
        return [
            {
                name: 'hours',
                data: monthData1?.map((md1)=>roundAccurately((md1.seconds ?? 0) / secondsInAnHour, 1)) ?? [],
                color: this.chartDataColor,
                type: 'column'
            }
        ];
    }
    get toolTipFormatter() {
        const hours1 = this.intl.t('hours');
        return function(this1: TooltipFormatterContextObject) {
            return `${this.y} ${hours1}`;
        };
    }
    get categories() {
        const monthData1 = this.args.data?.months ?? [];
        return monthData1.map((md1)=>convertDateFormat(md1.month, 'yyyy-MM', 'MMMM'));
    }
    get titleOptions(): TitleOptions {
        return {
            align: 'left',
            text: this.intl.t('reporting.chartTitles.timeOnTaskUsage')
        };
    }
    static{
        template(`
    <div class="relative" ...attributes>
      <TooltipInfo
        class="z-tooltip absolute right-3 top-3 text-xs print:hidden"
        @text={{t "reporting.tooltips.timeOnTaskUsage"}}
      />

      <div class="m-2 flex justify-between">
        <div class="w-1/2" data-test-task-usage-totals>
          <FigureBoxes
            @figures={{this.totalsStats.data}}
            @title={{this.totalsStats.title}}
            @theme={{this.totalsStats.theme}}
          />
        </div>
        <div class="w-1/2" data-test-task-usage-session>
          <FigureBoxes
            @figures={{this.sessionsStats.data}}
            @title={{this.sessionsStats.title}}
            @theme={{this.sessionsStats.theme}}
          />
        </div>
      </div>
      <div class="m-2" data-test-task-usage-students>
        {{t "reporting.summaryTitles.contributingStudents"}}:
        {{this.contributingStudents}}
      </div>
      <div class="h-60">
        <SeriesColumn
          @data={{this.columnChartData}}
          @title={{this.titleOptions}}
          @categories={{this.categories}}
          @yAxisLabel={{t "reporting.yAxisLabels.timeOnTaskUsage"}}
          @toolTipFormatter={{this.toolTipFormatter}}
        />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default TimeOnTaskUsageChart;
