import Service from '@ember/service'

export interface SemVer {
  major: number
  minor: number
  patch: number
}
export default class DeviceDetection extends Service {
  get appVersion(): SemVer | undefined {
    if (this.isNativeMobile) {
      const version = this.userAgent.match(
        /((.*ios-v)|(.*android\/v))(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+)|(.*android\/.*-staging-)(?<stagingPatch>\d+)/,
      )
      if (version?.groups) {
        const { major, minor, patch, stagingPatch } = version.groups
        return {
          major: parseInt(major ?? '0', 10),
          minor: parseInt(minor ?? '0', 10),
          patch: parseInt(patch ?? stagingPatch ?? '0', 10),
        }
      }
      return {
        major: 0,
        minor: 0,
        patch: 0,
      }
    }

    return undefined
  }

  get userAgent(): string {
    return navigator.userAgent
  }

  get platform(): string {
    return navigator.platform
  }

  get maxTouchPoints(): number {
    return navigator.maxTouchPoints
  }

  get isNativeIos(): boolean {
    return /mathseeds-ios|readingeggs-ios/.test(this.userAgent)
  }

  get isNativeAndroid(): boolean {
    return /mathseeds-android|readingeggs-android/.test(this.userAgent)
  }

  get isNativeAndroidStaging(): boolean {
    return /mathseeds-android|readingeggs-android.*-staging-/.test(
      this.userAgent,
    )
  }

  get isNativeMobile(): boolean {
    return this.isNativeIos || this.isNativeAndroid
  }

  get isAndroidOrIosUserAgent(): boolean {
    return this.isIos || this.isAndroid
  }

  get isAndroid(): boolean {
    return this.userAgent.includes('Android')
  }

  /**
   * For our purposes, an iPod is just a type of iPhone.
   */
  get isIPhone(): boolean {
    return /(iPhone|iPod)/.test(this.userAgent)
  }

  /**
   * This is based on
   * https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
   * - iPadOS reports a desktop user agent because 'Request Desktop Website' is enabled by default.
   */
  get isIPadOs13(): boolean {
    return this.platform === 'MacIntel' && this.maxTouchPoints > 1
  }

  get isIPad(): boolean {
    const agentMatches = !!this.userAgent.match(/iPad/)
    return agentMatches || this.isIPadOs13
  }

  get isIos(): boolean {
    return this.isIPhone || this.isIPad
  }

  get isMobile(): boolean {
    return this.isIos || this.isAndroid || this.isNativeMobile
  }

  get isMathseedsApp(): boolean {
    return this.isNativeMobile && this.userAgent.includes('mathseeds')
  }

  get isReadingeggsApp(): boolean {
    return this.isNativeMobile && this.userAgent.includes('readingeggs')
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'device/detection': DeviceDetection
  }
}
