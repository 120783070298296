import Model, { belongsTo, attr } from '@ember-data/model'

/**
 * Model class for clever sisId and district id.
 *
 * @class CleverStudentMatchModel
 * @property {string} cleverId - clever id as a string
 * @property {boolean} missingGrade - boolean to indicate if the student has a missing grade
 * @property {string} sisId - unique id which relates to a clever record fo this type
 */
export default class extends Model {
  @attr('string') cleverId

  @attr('string') sisId

  @attr('boolean', { defaultValue: false }) missingGrade

  @belongsTo('clever/student', { async: false, inverse: 'cleverStudentMatch' })
  student

  @belongsTo('clever/clever-student', { async: false, inverse: 'cleverStudentMatch' })
  cleverStudent

  // Provided when creating a school match record
  @belongsTo('clever/clever-district-match', { async: false, inverse: null })
  cleverDistrictMatch
}
