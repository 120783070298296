import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { themeClass } from 'district-ui-client/helpers/theme-class';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        title?: string;
        theme?: string;
        iconType?: IconName;
        bodyPadding?: string;
    };
    Blocks: {
        default: [];
    };
}
/**
 * @deprecated - please use newer Panel component (or Tile) from components/section
 */ export const Panel: TOC<Signature> = template(`
  <div class="__panel__136a8 {{themeClass @theme}}" ...attributes>
    {{#if @title}}
      <panel-header class="block">
        <h2>
          {{#if @iconType}}
            <FaIcon @icon={{@iconType}} class="mr-1" />
          {{/if}}
          {{@title}}
        </h2>
      </panel-header>
    {{/if}}
    <panel-body class="block {{if @bodyPadding @bodyPadding 'p-7'}}">
      {{yield}}
    </panel-body>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Panel;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Panel: typeof Panel;
    }
}
