import Route from '@ember/routing/route'
import { service } from '@ember/service'
import { action } from '@ember/object'
import { isNone } from '@ember/utils'
import { didCancel } from 'ember-concurrency'

export default class CleverMatchRoute extends Route {
  @service clever

  @service store

  @service router

  async model({ id: cleverSchoolId }) {
    const { clever, store, router } = this

    // Need to peek the record first, to get the clever district id
    let cleverSchool = store.peekRecord('clever/clever-school', cleverSchoolId)

    if (isNone(cleverSchool)) {
      router.replaceWith('clever.dashboard')
      return
    }

    // Now fetch the most up to date record
    const { cleverDistrictId } = cleverSchool
    const cleverSchools = await store.query('clever/clever-school', {
      scope: `clever-districts/${cleverDistrictId}`,
      filter: { id: [cleverSchoolId] },
    })
    cleverSchool = cleverSchools.find((school) => school.id === cleverSchoolId) // should only be 1 school anyway

    const matchedBlakeSchool = await cleverSchool.blakeSchool

    const unmatchedBlakeSchools = clever.blakeSchoolsWithoutMatches()

    const { subscriptionType } = this.modelFor('subscription-type')

    return {
      cleverDistrictId,
      cleverSchoolId,
      unmatchedBlakeSchools,
      cleverSchool,
      subscriptionType,
      matchedBlakeSchool,
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model)

    const { cleverSchool } = model
    try {
      this.clever.pollForCleverSchool.perform(cleverSchool)
    } catch (e) {
      if (!didCancel(e)) throw e
    }
  }

  deactivate() {
    this.clever.pollForCleverSchool.cancelAll()
  }

  @action
  refreshModel() {
    this.refresh()
  }
}
