import { service } from '@ember/service'
import { alias, reads } from '@ember/object/computed'
import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { dependentKeyCompat } from '@ember/object/compat'

export default class IndexController extends Controller {
  @tracked selectedIds = []

  @tracked search = ''

  @service
  session

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get groupActionsDisabled() {
    return !this.selectedStudents.length || this.isCleverDisabled
  }

  @service
  activeRoute

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  @reads('model.school')
  school

  @alias('school.students')
  students

  @dependentKeyCompat
  get selectedStudents() {
    return this.model.schoolDataTask.isRunning ? [] : this.students.filter((s) => this.selectedIds.includes(s.id))
  }

  updateSelectedIds = (selectedIds) => {
    this.selectedIds = selectedIds
  }

  setSearch = (value) => {
    this.search = value
  }
}
