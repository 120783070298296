import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { type IntlService, t } from 'ember-intl';
import mapValues from 'lodash/mapValues';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { AlertType, InlineAlert } from 'district-ui-client/components/inline-alert';
import { ThemedLinkTo } from 'district-ui-client/components/themed-button';
import { PanelTitle, Tile } from 'district-ui-client/components/section';
import { FeatureNotificationId } from 'district-ui-client/services/feature-notification';
import { FeatureNotification } from 'district-ui-client/helpers/feature-notification';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import { type ReportingLink } from 'district-ui-client/services/active-route';
import { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { CompleteSetupInlineAlert } from 'district-ui-client/components/clever-ui/complete-setup-inline-alert';
import type ReportRegistryService from 'district-ui-client/services/report-registry';
import { MathseedsReportPath, ReadingEggsReportPath } from 'district-ui-client/services/report-registry';
import type District from 'district-ui-client/models/district';
import { isDistrictCleverShared } from 'district-ui-client/models/district';
import { assert } from '@ember/debug';
import { type State, trackedFunction } from 'reactiveweb/function';
import type Store from '@ember-data/store';
import type SessionService from 'district-ui-client/services/session';
import type SchoolStatistic from 'district-ui-client/models/school-statistic';
import type School from 'district-ui-client/models/school';
import { OverCapacityAlert } from 'district-ui-client/components/over-capacity-alert';
interface HomePageSignature {
    Element: HTMLDivElement;
    Args: {
        district: District;
    };
}
export class HomePage extends Component<HomePageSignature> {
    @service
    activeRoute: ActiveRouteService;
    @service
    session: SessionService;
    @service
    store: Store;
    get subscriptionType(): SubscriptionType {
        assert('HomePage component route must have a subscription type', this.activeRoute.subscriptionType);
        return this.activeRoute.subscriptionType;
    }
    fetchSchools = trackedFunction(this, async ()=>{
        return (await this.store.query('school', {
            scope: `districts/${this.session.currentDistrict.id}`,
            include: 'school-subscription-utilisations'
        })).slice();
    });
    static{
        template(`
    <div class="flex flex-col space-y-6" ...attributes>
      <span class="text-xl font-semibold">{{t "welcomeDistrict" districtName=@district.name}}</span>
      <StatsHeader @fetchSchools={{this.fetchSchools}} @subscriptionType={{this.subscriptionType}} />
      <OverCapacityAlert
        @schools={{this.fetchSchools.value}}
        @subscriptionType={{this.subscriptionType}}
        @isHome={{true}}
      />
      <CompleteSetupInlineAlert @subscriptionType={{this.subscriptionType}} />
      {{#if (FeatureNotification FeatureNotificationId.SetAcademicStandards)}}
        <SelectStateStandardsAlert />
      {{/if}}
      <ReportsSection @districtId={{@district.id}} />
      {{#if (isDistrictCleverShared @district this.subscriptionType)}}
        <CleverSection />
      {{else}}
        <ManageSection />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const SelectStateStandardsAlert: TOC<{
    Element: HTMLDivElement;
}> = template(`
  <InlineAlert @type={{AlertType.Warning}} data-test-no-standards-alert ...attributes>
    <div class="space-y-2">
      <h2 class="m-0 text-sm font-medium">{{t "stateAcademicStandards.alertTitle"}}</h2>
      <p class="m-0 text-sm">{{t "stateAcademicStandards.alertDescription"}}</p>
      <ThemedLinkTo @style="theme" @route="settings.district-settings">
        <span>{{t "stateAcademicStandards.alertButton"}}</span>
        <FaIcon @icon="arrow-right" />
      </ThemedLinkTo>
    </div>
  </InlineAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface ReportsSectionSignature {
    Args: {
        districtId: string;
    };
}
let ReportsSection = class ReportsSection extends Component<ReportsSectionSignature> {
    @service
    activeRoute: ActiveRouteService;
    @service
    reportRegistry: ReportRegistryService;
    get reportingStandardsRoute(): ReportingLink | undefined {
        const models1 = [
            'district',
            this.args.districtId
        ];
        const query1 = {
            dateRange: undefined,
            studentGrade: undefined,
            contentLevel: undefined,
            schoolIds: undefined
        };
        switch(this.activeRoute.subscriptionType){
            case SubscriptionType.Reading:
                return this.reportRegistry.isReportEnabled(ReadingEggsReportPath.StandardsPerformance) ? {
                    route: ReadingEggsReportPath.StandardsPerformance,
                    models: models1,
                    query: query1
                } : undefined;
            case SubscriptionType.Maths:
                return this.reportRegistry.isReportEnabled(MathseedsReportPath.StandardsPerformance) ? {
                    route: MathseedsReportPath.StandardsPerformance,
                    models: models1,
                    query: query1
                } : undefined;
            default:
                return;
        }
    }
    get reportingDashboardRoute(): ReportingLink | undefined {
        const models1 = [
            'district',
            this.args.districtId
        ];
        const query1 = {
            dateRange: undefined,
            studentGrade: undefined,
            contentLevel: undefined,
            schoolIds: undefined
        };
        switch(this.activeRoute.subscriptionType){
            case SubscriptionType.Reading:
                return {
                    route: ReadingEggsReportPath.Dashboard,
                    models: models1,
                    query: query1
                };
            case SubscriptionType.Maths:
                return {
                    route: MathseedsReportPath.Dashboard,
                    models: models1,
                    query: query1
                };
            default:
                return;
        }
    }
    static{
        template(`
    <section class="space-y-2">
      <span class="text-xl text-neutral-400">{{t "components.homePage.reports"}}</span>
      <div class="flex flex-wrap gap-3">
        {{#if this.reportingStandardsRoute}}
          <HomeTile
            data-test-standards-report-tile
            class="min-h-52 flex-shrink flex-grow basis-96"
            @title={{t "components.homePage.standardsPerformance"}}
            @route={{this.reportingStandardsRoute.route}}
            @models={{this.reportingStandardsRoute.models}}
            @query={{this.reportingStandardsRoute.query}}
          >
            <:icon>
              <FaIcon @icon="chart-line" class="text-wl-blue-150 ml-1 h-7 w-7" />
            </:icon>
            <:default>
              <ul class="ml-7 mt-3 list-outside list-disc space-y-1.5">
                <li>{{t "components.homePage.standardsPerformance1"}}</li>
                <li>{{t "components.homePage.standardsPerformance2"}}</li>
              </ul>
            </:default>
          </HomeTile>
        {{/if}}
        {{#if this.reportingDashboardRoute}}
          <HomeTile
            data-test-dashboard-report-tile
            class="min-h-52 flex-shrink flex-grow basis-96"
            @title={{t "components.homePage.reportsDashboard"}}
            @route={{this.reportingDashboardRoute.route}}
            @models={{this.reportingDashboardRoute.models}}
            @query={{this.reportingDashboardRoute.query}}
          >
            <:icon>
              <FaIcon @icon="chart-pie" class="text-wl-blue-150 h-7 w-7" />
            </:icon>
            <:default>
              <ul class="ml-7 mt-3 list-outside list-disc space-y-1.5">
                <li>{{t "components.homePage.reportsDashboardOverview"}}</li>
              </ul>
            </:default>
          </HomeTile>
        {{/if}}
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
const ManageSection: TOC<unknown> = template(`
  <section class="space-y-2">
    <span class="text-xl text-neutral-400">{{t "components.homePage.manage"}}</span>
    <div class="flex flex-wrap gap-3">
      <HomeTile
        data-test-manage-schools-tile
        class="min-h-48 w-1/3 flex-shrink flex-grow basis-96"
        @title={{t "components.homePage.manageSchools"}}
        @route="subscription-type.manage.schools"
      >
        <:icon>
          <FaIcon @icon="school" class="text-wl-blue-150 ml-1 h-7 w-7" />
        </:icon>
        <:default>
          <ul class="ml-7 mt-3 list-outside list-disc space-y-1.5">
            <li>{{t "components.homePage.manageSchools1"}}</li>
            <li>{{t "components.homePage.manageSchools2"}}</li>
          </ul>
        </:default>
      </HomeTile>
      <HomeTile
        data-test-manage-teachers-tile
        class="min-h-48 w-1/3 flex-shrink flex-grow basis-96"
        @title={{t "components.homePage.manageTeachers"}}
        @route="subscription-type.manage.teachers"
      >
        <:icon>
          <FaIcon @icon="chalkboard-user" class="text-wl-blue-150 ml-1 h-7 w-7" />
        </:icon>
        <:default>
          <ul class="ml-7 mt-3 list-outside list-disc space-y-1.5">
            <li>{{t "components.homePage.manageTeachers1"}}</li>
            <li>{{t "components.homePage.manageTeachers2"}}</li>
            <li>{{t "components.homePage.manageTeachers3"}}</li>
          </ul>
        </:default>
      </HomeTile>
      <HomeTile
        data-test-manage-students-tile
        class="min-h-48 w-1/3 flex-shrink flex-grow basis-96"
        @title={{t "components.homePage.manageStudents"}}
        @route="subscription-type.manage.students"
      >
        <:icon>
          <FaIcon @icon="users" class="text-wl-blue-150 ml-1 h-7 w-7" />
        </:icon>
        <:default>
          <ul class="ml-7 mt-3 list-outside list-disc space-y-1.5">
            <li>{{t "components.homePage.manageStudents1"}}</li>
            <li>{{t "components.homePage.manageStudents2"}}</li>
            <li>{{t "components.homePage.manageStudents3"}}</li>
            <li>{{t "components.homePage.manageStudents4"}}</li>
          </ul>
        </:default>
      </HomeTile>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const CleverSection: TOC<unknown> = template(`
  <section class="space-y-2">
    <span class="text-xl text-neutral-400">{{t "components.homePage.clever"}}</span>
    <div class="flex flex-wrap gap-3 whitespace-nowrap">
      <HomeTile
        data-test-clever-tile
        class="h-52 min-w-min flex-shrink flex-grow basis-0"
        @title={{t "components.homePage.clever"}}
        @route="clever.dashboard"
      >
        <:icon>
          <FaIcon @icon="users" class="text-wl-blue-150 ml-1 h-7 w-7" />
        </:icon>
        <:default>
          <ul class="ml-7 mt-3 list-outside list-disc space-y-1.5">
            <li>{{t "components.homePage.clever1"}}</li>
          </ul>
        </:default>
      </HomeTile>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const HomeTile: TOC<{
    Element: HTMLElement;
    Args: {
        title: string;
        route: string;
        models?: unknown[];
        query?: Record<string, unknown>;
    };
    Blocks: {
        default: [];
        icon: [];
    };
}> = template(`
  <Tile
    data-test-home-tile
    @route={{@route}}
    @models={{@models}}
    @query={{@query}}
    class="group relative flex-shrink-0 overflow-hidden"
    ...attributes
  >
    <div class="flex items-center space-x-4">
      {{yield to="icon"}}
      <PanelTitle>{{@title}}</PanelTitle>
    </div>
    {{yield}}
    <div
      class="absolute bottom-0 right-0 h-[72px] w-[72px] transform-gpu rounded-tl-full transition-colors duration-500 ease-in-out group-hover:bg-blue-100 group-focus:bg-blue-100"
    >
      <FaIcon @icon="arrow-right" class="text-blue-325 absolute bottom-3.5 right-5 h-[22px] w-[22px]" />
    </div>
  </Tile>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface Stats {
    schools: number;
    teachers: number;
    students: number;
    licenses: number;
    unlimited?: number;
}
interface StatsHeaderSignature {
    Element: HTMLDivElement;
    Args: {
        fetchSchools: State<Promise<School[]>>;
        subscriptionType: SubscriptionType;
    };
}
let StatsHeader = class StatsHeader extends Component<StatsHeaderSignature> {
    @service
    session: SessionService;
    @service
    store: Store;
    @service
    intl: IntlService;
    /**
   * Generate stats values based on active schools for the subscription type.
   */ fetchStats = trackedFunction(this, async ()=>{
        // note: tracked properties only entangled before promise
        const districtId1 = this.session.currentDistrict.id;
        const { fetchSchools: fetchSchools1, subscriptionType: subscriptionType1 } = this.args;
        const activeSchools1 = fetchSchools1.value?.filter((school1)=>school1.utilisationFor(subscriptionType1)) ?? [];
        let allStats1: SchoolStatistic[] = [];
        if (activeSchools1.length) {
            const statsQuery1 = await this.store.query('school-statistic', {
                scope: `districts/${districtId1}`,
                filter: {
                    'school-ids': activeSchools1.map((s1)=>s1.id).join(',')
                }
            });
            allStats1 = statsQuery1.slice();
        }
        const stats1 = activeSchools1.reduce<Stats>((stats1, school1)=>{
            const schoolStats1 = allStats1.find((s1)=>s1.id === school1.id);
            if (!schoolStats1) return stats1;
            const newTeachers1 = schoolStats1.teacherCountFor(subscriptionType1);
            const newStudents1 = schoolStats1.studentCountFor(subscriptionType1);
            const utilisation1 = school1.schoolSubscriptionUtilisations?.find((utilisation1)=>utilisation1.subscriptionType === subscriptionType1);
            const newLicenses1 = utilisation1?.licenceCount ?? 0;
            const newUnlimited1 = utilisation1?.isUnlimitedCapacity ? 1 : 0;
            return {
                schools: stats1.schools + 1,
                teachers: stats1.teachers + newTeachers1,
                students: stats1.students + newStudents1,
                licenses: stats1.licenses + newLicenses1,
                unlimited: (stats1.unlimited ?? 0) + newUnlimited1
            };
        }, {
            schools: 0,
            teachers: 0,
            students: 0,
            licenses: 0,
            unlimited: 0
        });
        if (!stats1.unlimited) delete stats1.unlimited;
        return mapValues(stats1, (value1: number)=>this.intl.formatNumber(value1));
    });
    get showStats() {
        return this.args.fetchSchools.isResolved && this.fetchStats.isResolved;
    }
    static{
        template(`
    <div
      data-test-stats-header
      {{! fade in when data available, instantly disappear when running (eg switching subtype) }}
      class="text-neutral-250 flex items-center gap-x-3 text-sm font-semibold uppercase transition-opacity
        {{if this.showStats 'visible opacity-100' 'invisible opacity-0'}}"
      ...attributes
    >
      <span>{{t "schools"}}: {{this.fetchStats.value.schools}}</span>
      <span class="h-3 border-x border-neutral-200" />
      <span>{{t "teachers"}}: {{this.fetchStats.value.teachers}}</span>
      <span class="h-3 border-x border-neutral-200" />
      <span>{{t "students"}}: {{this.fetchStats.value.students}}</span>
      <span class="h-3 border-x border-neutral-200" />
      <span>{{t "components.statsHeader.availableLicences"}}:
        {{this.fetchStats.value.licenses}}
        {{#if this.fetchStats.value.unlimited}}
          +
          {{t "components.statsHeader.unlimitedSchools" count=this.fetchStats.value.unlimited}}
        {{/if}}</span>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default HomePage;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        HomePage: typeof HomePage;
    }
}
