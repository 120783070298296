import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import colors from '@blakeelearning/blake-colours/colours';
import type { IntlService } from 'ember-intl';
import { formatValue } from 'district-ui-client/utils/format-value';
import type { TooltipFormatterContextObject, Point } from 'highcharts';
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import { t } from 'ember-intl';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component';
import type { SeriesColumnOptions } from 'highcharts';
/**
 * Puts together all the pieces for the storylands totals chart.
 */ interface SummaryTotalCount {
    category: 'total_count';
    count: number;
}
interface SummaryAverageScore {
    category: 'average_score';
    average: Nullable<number>;
}
export interface StorylandsLessonsData {
    grade_position: number;
    summary: Array<SummaryTotalCount | SummaryAverageScore>;
    months: {
        month: string;
        count: Nullable<number>;
    }[];
}
interface Signature {
    Args: {
        data?: StorylandsLessonsData;
        grade?: string;
        tooltip?: string;
        title?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsStorylandsLessonsTotals extends Component<Signature> {
    @service
    intl: IntlService;
    /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total lessons completed and average score per student.
   */ get summaryStats() {
        const summaryData1 = this.args.data?.summary ?? [];
        const total1 = summaryData1.find((d1): d is SummaryTotalCount =>d1.category === 'total_count')?.count ?? 0;
        const averageScore1 = summaryData1.find((d1): d is SummaryAverageScore =>d1.category === 'average_score')?.average;
        return [
            {
                label: this.intl.t('reporting.summaryTitles.storylandsTotals.total'),
                value: formatNumber(total1, 0)
            },
            {
                label: this.intl.t('reporting.summaryTitles.storylandsTotals.average'),
                value: formatValue(averageScore1, {
                    append: '%'
                })
            }
        ];
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get columns(): SeriesColumnOptions[] {
        const monthsData1 = this.args.data?.months ?? [];
        const color1 = colors.juicyOrange300;
        const data1 = monthsData1.map((monthData1, index1)=>({
                x: index1,
                y: monthData1.count,
                monthData: monthData1
            }));
        return [
            {
                name: 'count',
                color: color1,
                data: data1,
                type: 'column'
            }
        ];
    }
    get months() {
        return this.args.data?.months?.map((m1)=>convertDateFormat(m1.month)) ?? [];
    }
    /**
   * A formatter function to pass to Highcharts to format the tooltip label.
   */ toolTipFormatter(this1: TooltipFormatterContextObject) {
        const point1 = this.point as Point & {
            monthData: {
                month: string;
                count: number;
            };
        };
        return `${point1.monthData.count}`;
    }
    static{
        template(`
    <div data-test-storylands-lessons-totals class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{SummaryBoxColorScheme.Orange}}
          />
        </:left>
        <:right>
          <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
          <SeriesColumn
            @title={{@title}}
            @data={{this.columns}}
            @categories={{this.months}}
            @yAxisLabel={{t "reporting.yAxisLabels.storylandsTotals"}}
            @chartSpacing={{Array 10 10 10 10}}
            @toolTipFormatter={{this.toolTipFormatter}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsStorylandsLessonsTotals;
