import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { PageTitle } from 'district-ui-client/components/page-title';
import { ProductPill } from 'district-ui-client/components/pills';
import type ReportingService from 'district-ui-client/services/reporting';
import type RouterService from '@ember/routing/router-service';
import { isReportPath } from 'district-ui-client/services/report-registry';
import type { IntlService } from 'ember-intl';
import type ReportRegistryService from 'district-ui-client/services/report-registry';
interface Signature {
    Element: HTMLElement;
}
export class ReportingPageHeader extends Component<Signature> {
    @service
    reporting: ReportingService;
    @service
    router: RouterService;
    @service
    intl: IntlService;
    @service
    reportRegistry: ReportRegistryService;
    get product() {
        return this.reporting.product;
    }
    get title() {
        const { currentRouteName: currentRouteName1 } = this.router;
        return isReportPath(currentRouteName1) ? this.reportRegistry.reportName(currentRouteName1) : '';
    }
    static{
        template(`
    <PageTitle data-test-page-header class="flex items-center space-x-3" ...attributes>
      <span>{{this.title}}</span>
      {{#if this.product}}<ProductPill @product={{this.product}} />{{/if}}
    </PageTitle>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
