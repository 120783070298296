import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { Alert } from 'district-ui-client/components/alert';
import { t } from 'ember-intl';
import { and } from 'ember-truth-helpers';
import { UiButton } from 'district-ui-client/components/ui-button';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import gradeName from 'district-ui-client/helpers/grade-name';
export interface CandidateStudent {
    csvRow: number;
    firstName: string;
    lastName: string;
    gradePosition: string;
    externalId?: string;
    duplicateSource: boolean;
    duplicateDestination: boolean;
    teacherEmail?: string;
    schoolClassName?: string;
    schoolCode: string;
}
interface Signature {
    Args: {
        candidateStudents: CandidateStudent[];
        isSubmitting?: boolean;
        filename: string;
        cancelAction: () => void;
        submitAction: () => void;
    };
}
export class ImportPanelStudentConfirm extends Component<Signature> {
    get duplicateStudentsInSource() {
        return this.args.candidateStudents.some((student1)=>student1.duplicateSource);
    }
    get duplicateStudentsInDestination() {
        return this.args.candidateStudents.some((student1)=>student1.duplicateDestination);
    }
    get duplicateStudents() {
        return this.duplicateStudentsInSource || this.duplicateStudentsInDestination;
    }
    static{
        template(`
    {{#if this.duplicateStudents}}
      <Alert @tone="caution" role="alert" class="mb-7">
        {{#if this.duplicateStudentsInSource}}
          <p>{{t "manage.studentCsv.duplicateStudentsInSource"}}</p>
        {{/if}}
        {{#if (and this.duplicateStudentsInSource this.duplicateStudentsInDestination)}}
          <br />
        {{/if}}
        {{#if this.duplicateStudentsInDestination}}
          <p>{{t "manage.studentCsv.duplicateStudentsInDestination"}}</p>
        {{/if}}
        <p class="mb-0">{{t "manage.studentCsv.duplicateStudentsSolution"}}</p>
      </Alert>
    {{/if}}

    {{#if @isSubmitting}}
      <Alert @tone="caution">
        {{t "manage.studentCsv.importStudents" htmlSafe=true}}
      </Alert>
    {{else}}
      <Alert @tone="info" role="alert">
        <p data-test-filename class="mb-0">
          {{t "manage.studentCsv.validFile" filename=@filename count=@candidateStudents.length htmlSafe=true}}
        </p>
      </Alert>
    {{/if}}

    <UiButton class="muted mr-3 mt-7" disabled={{@isSubmitting}} {{on "click" @cancelAction}} data-test-cancel-button>
      {{t "cancel"}}
    </UiButton>
    <UiButton class="green mt-7" disabled={{@isSubmitting}} {{on "click" @submitAction}} data-test-submit-button>
      {{#if @isSubmitting}}
        {{t "fileImporter.button.submitting"}}
      {{else}}
        {{t "fileImporter.button.ready"}}
      {{/if}}
    </UiButton>

    <table class="mb-3 mt-7 w-full bg-white">
      <thead class="border-dusty-black-250 border-l border-r">
        <tr>
          {{#if this.duplicateStudents}}
            <th class="bg-dusty-black-250 w-24 border-r border-white p-5 font-normal text-white"></th>
          {{/if}}
          <th class="bg-dusty-black-250 w-32 border-r border-white p-5 text-center font-normal text-white">{{t
              "manage.csv.lineNo"
            }}</th>
          <th class="bg-dusty-black-250 border-r border-white p-5 font-normal capitalize text-white">{{t
              "manage.studentCsv.attribute.school-code"
            }}</th>
          <th class="bg-dusty-black-250 border-r border-white p-5 font-normal capitalize text-white">{{t
              "manage.studentCsv.attribute.first-name"
            }}</th>
          <th class="bg-dusty-black-250 border-r border-white p-5 font-normal capitalize text-white">{{t
              "manage.studentCsv.attribute.last-name"
            }}</th>
          <th class="bg-dusty-black-250 border-r border-white p-5 font-normal capitalize text-white">{{t
              "grade.label"
            }}</th>
          <th class="bg-dusty-black-250 border-r border-white p-5 font-normal capitalize text-white">{{t
              "manage.studentCsv.attribute.external-id"
            }}</th>
          <th class="bg-dusty-black-250 border-r border-white p-5 font-normal capitalize text-white">{{t
              "manage.studentCsv.attribute.teacher-email"
            }}</th>
          <th class="bg-dusty-black-250 border-0 border-r border-white p-5 font-normal text-white">
            <span class="capitalize">{{t "manage.studentCsv.schoolClassName"}}</span>
          </th>
        </tr>
      </thead>
      <tbody class="border-dusty-black-100 border-l border-r">
        {{#each @candidateStudents as |student|}}
          <tr data-test-student-row>
            {{#if this.duplicateStudents}}
              <td class="border-dusty-black-100 border-b p-5 text-center font-normal">
                {{#if (or student.duplicateSource student.duplicateDestination)}}
                  <FaIcon
                    @icon="triangle-exclamation"
                    @transform="grow-1"
                    class="text-juicy-orange-300"
                    data-test-warning-icon
                  />
                {{/if}}
              </td>
            {{/if}}
            <td class="border-dusty-black-100 border-b p-5 text-center font-normal">{{student.csvRow}}</td>
            <td class="border-dusty-black-100 border-b p-5 font-normal">{{student.schoolCode}}</td>
            <td class="border-dusty-black-100 border-b p-5 font-normal">{{student.firstName}}</td>
            <td class="border-dusty-black-100 border-b p-5 font-normal">{{student.lastName}}</td>
            <td class="border-dusty-black-100 border-b p-5 font-normal">{{gradeName student.gradePosition}}</td>
            <td class="border-dusty-black-100 border-b p-5 font-normal">{{student.externalId}}</td>
            <td class="border-dusty-black-100 border-b p-5 font-normal">{{student.teacherEmail}}</td>
            <td class="border-dusty-black-100 border-b p-5 font-normal">
              {{#if student.schoolClassName}}
                {{student.schoolClassName}}
              {{else}}
                {{t "manage.noClassAssigned"}}
              {{/if}}
            </td>
          </tr>
        {{/each}}
      </tbody>
    </table>

    <UiButton class="muted mr-3 mt-7" disabled={{@isSubmitting}} {{on "click" @cancelAction}} data-test-cancel-button>
      {{t "cancel"}}
    </UiButton>
    <UiButton class="green mt-7" disabled={{@isSubmitting}} {{on "click" @submitAction}} data-test-submit-button>
      {{#if @isSubmitting}}
        {{t "fileImporter.button.submitting"}}
      {{else}}
        {{t "fileImporter.button.ready"}}
      {{/if}}
    </UiButton>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ImportPanelStudentConfirm;
