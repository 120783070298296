export const THEME_KEYS = {
  blue: 'blue',
  green: 'green',
  positive: 'positive',
  critical: 'critical',
  default: 'default',
}

export const THEME_CLASSES = {
  [THEME_KEYS.blue]: 'theme-blue',
  [THEME_KEYS.green]: 'theme-green',
  [THEME_KEYS.positive]: 'theme-positive',
  [THEME_KEYS.critical]: 'theme-critical',
  [THEME_KEYS.default]: 'theme-default',
}
