import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import BlakeColours from '@blakeelearning/blake-colours/colours';
import PieChart from 'district-ui-client/components/primitives/pie-chart/component';
import { t } from 'ember-intl';
import type { TooltipFormatterCallbackFunction } from 'highcharts';
import type { SeriesPieOptions } from 'highcharts';
import type { TooltipFormatterContextObject } from 'highcharts';
export interface DrivingTestsData {
    driving_tests_by_grade: {
        grade_level: number;
        quiz_completed_percentage: number;
    }[];
}
interface Signature {
    Args: {
        data: DrivingTestsData;
        gradeToShortNameMap?: Record<number, string | undefined>;
    };
    Element: HTMLDivElement;
}
export class DrivingTestsDashboardChart extends Component<Signature> {
    get colours() {
        return [
            BlakeColours.oceanyBlue300,
            BlakeColours.oceanyBlue100
        ];
    }
    get columnsPerGraph() {
        const testsLength1 = this.args.data.driving_tests_by_grade.length;
        if (!testsLength1) {
            return 4;
        }
        return 12 / testsLength1;
    }
    get numberOfPiesClass() {
        const numberOfPies1 = this.args.data.driving_tests_by_grade.length;
        const pieClasses1 = [
            '',
            'w-full',
            'w-1/2',
            'w-1/3'
        ];
        return pieClasses1[numberOfPies1];
    }
    get chartInputData(): {
        series: SeriesPieOptions[];
        innerSize: string;
        size: string;
        subtitle: string;
        label: number;
    }[] {
        const gradeToShortNameMap1 = this.args.gradeToShortNameMap || {};
        const mapped1 = this.args.data.driving_tests_by_grade.map((drivingTest1)=>{
            const grade1 = drivingTest1.grade_level;
            const shortGradeName1 = gradeToShortNameMap1[grade1] || '?';
            const completed1 = drivingTest1.quiz_completed_percentage;
            const notCompleted1 = 100 - completed1;
            const size1 = this.pieSize;
            const series1: SeriesPieOptions[] = [
                {
                    data: [
                        [
                            'completed',
                            completed1
                        ],
                        [
                            'notCompleted',
                            notCompleted1
                        ]
                    ],
                    dataLabels: {
                        enabled: false
                    },
                    type: 'pie'
                }
            ];
            const inputData1 = {
                series: series1,
                innerSize: '80%',
                size: size1,
                subtitle: `<h1>${shortGradeName1}</h1>`,
                label: completed1
            };
            return inputData1;
        });
        return mapped1;
    }
    get pieSize() {
        const testsLength1 = this.args.data.driving_tests_by_grade.length;
        if (testsLength1 === 1) {
            return '80%';
        }
        return '100%';
    }
    get tooltip() {
        return {
            enabled: true,
            backgroundColor: BlakeColours.dustyBlack400,
            borderWidth: 0,
            borderRadius: 5,
            formatter: this.tooltipFormatter,
            shadow: false,
            style: {
                color: BlakeColours.white
            }
        };
    }
    get tooltipFormatter(): TooltipFormatterCallbackFunction {
        return function(this1: TooltipFormatterContextObject) {
            return `<b>${Math.round(this.y)}%</b>`;
        };
    }
    static{
        template(`
    <div class="flex h-full flex-col" data-test-dashboard-driving-tests ...attributes>
      <div data-test-dashboard-driving-tests-labels class="flex flex-grow items-center">
        {{#each this.chartInputData as |chartData|}}
          <div class="relative top-3 mx-auto mb-1">
            <p
              class="bg-dusty-black-400 text-dusty-black-100 print:!bg-dusty-black-400 print:!text-dusty-black-100 mb-0 flex h-[40px] w-[60px] items-center justify-center rounded print:!rounded"
            >
              {{chartData.label}}
              %
            </p>
            <div
              class="border-y-dusty-black-500 absolute bottom-[-10%] left-[25px] border-x-[5px] border-b-0 border-t-[5px] border-x-transparent"
            ></div>
          </div>
        {{/each}}
      </div>

      <div
        data-test-dashboard-driving-tests-charts
        class="relative flex flex-grow-[5] flex-row items-center justify-center"
      >
        <div class="absolute inset-0 flex">
          {{! Using display inline block so the below ~ is to avoid whitespace }}
          {{#each this.chartInputData as |chartData|}}
            <div class="{{this.numberOfPiesClass}} relative inline-block h-full">
              <PieChart
                @data={{chartData.series}}
                @subtitle={{chartData.subtitle}}
                @colours={{this.colours}}
                @size={{chartData.size}}
                @innerSize={{chartData.innerSize}}
                @tooltip={{this.tooltip}}
              />
            </div>
          {{/each}}
        </div>
      </div>

      <div data-test-dashboard-driving-tests-legend class="flex flex-grow items-center justify-around">
        <div data-test-dashboard-driving-tests-legend-item="completed" class="flex flex-row items-center">
          <div
            class="bg-oceany-blue-300 print:!bg-oceany-blue-300 m-0.5 h-[12px] w-[12px] rounded-full print:!rounded-full"
          >
          </div>
          <div class="text-dusty-black-500">
            {{t "reporting.charts.dashboard.drivingTests.completed"}}
          </div>
        </div>

        <div data-test-dashboard-driving-tests-legend-item="not-completed" class="flex flex-row items-center">
          <div
            class="bg-oceany-blue-200 print:!bg-oceany-blue-200 m-0.5 h-[12px] w-[12px] rounded-full print:!rounded-full"
          >
          </div>
          <div class="text-dusty-black-500">
            {{t "reporting.charts.dashboard.drivingTests.notCompleted"}}
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default DrivingTestsDashboardChart;
