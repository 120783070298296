import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { themeKeys as statBoxThemes } from 'district-ui-client/components/stat-box';
import { ReportingDistrictHeader } from 'district-ui-client/components/reporting/district-header';
import { DashboardControls } from 'district-ui-client/components/reporting/dashboard-controls';
import { DynamicDashboardChartWrapper } from 'district-ui-client/components/reporting/dashboard-chart-wrapper/dynamic';
import { StaticDashboardChartWrapper } from 'district-ui-client/components/reporting/dashboard-chart-wrapper/static';
import { AwardsEarnedRibbons } from 'district-ui-client/components/charts/dashboard/awards-earned/ribbons';
import { GiraffeChartsTotalUsageOverTime } from 'district-ui-client/components/charts/dashboard/total-usage-over-time/component';
import { ChartsDashboardCourseProgress } from 'district-ui-client/components/charts/dashboard/course-progress/component';
import { LexileGrowthDashboardChart } from 'district-ui-client/components/charts/dashboard/lexile-growth/component';
import { DashboardAssessmentScoresChart } from 'district-ui-client/components/charts/dashboard/assessment-scores/component';
import { WorkingAtGradeLevelDashboardChart } from 'district-ui-client/components/charts/dashboard/working-at-grade-level/component';
import { StoriesWrittenDashboardChart } from 'district-ui-client/components/charts/dashboard/stories-written/component';
import { BooksReadByGenreDashboardChart } from 'district-ui-client/components/charts/dashboard/books-read/by-genre/component';
import { t } from 'ember-intl';
import { not } from 'ember-truth-helpers';
import RouteTemplate from 'ember-route-template';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { ReportingReDashboardRoute } from 'district-ui-client/routes/reporting/ui-scope/re/dashboard';
interface Args {
    model: ModelFor<ReportingReDashboardRoute>;
}
interface Signature {
    Args: Args;
}
export class ReportingReDashboardRouteTemplate extends Component<Signature> {
    @service
    router: RouterService;
    goToCategory = (category1: string)=>{
        const path1 = {
            lessons: 'course-progress.totals',
            assessments: 'quizzes-completed',
            driving_tests: 'driving-tests',
            books: 'books-read',
            spelling: 'spelling-progress.totals'
        }[category1];
        const { scope: scopeName1, id: scopeId1 } = this.args.model.scope;
        this.router.transitionTo(`reporting.ui-scope.re.${path1}`, scopeName1, scopeId1);
    };
    get totalUsageBoxes() {
        return [
            {
                category: 'lessons',
                theme: statBoxThemes.THEME_OCEANY_BLUE
            },
            {
                category: 'assessments',
                theme: statBoxThemes.THEME_JUICY_ORANGE
            },
            {
                category: 'driving_tests',
                theme: statBoxThemes.THEME_STORM_BLUE
            },
            {
                category: 'books',
                theme: statBoxThemes.THEME_PURPLY_PINK
            },
            {
                category: 'spelling',
                theme: statBoxThemes.THEME_GRAPEY_GREEN
            }
        ];
    }
    get isSummaryDataPresent() {
        return isPresent(this.args.model.totalUsageOverTime);
    }
    static{
        template(`
    <ReportingDistrictHeader />

    <DashboardControls class="my-3" @viewMode="chart" @isDataPresent={{this.isSummaryDataPresent}} />
    <DynamicDashboardChartWrapper
      class="mb-6"
      @empty={{not this.isSummaryDataPresent}}
      @title={{t "reporting.chartTitles.dashboard.totalUsageOverTime"}}
      @tooltip={{t "reporting.tooltips.dashboard.totalUsageOverTime"}}
    >
      {{#if @model.totalUsageOverTime}}
        <GiraffeChartsTotalUsageOverTime
          @product={{@model.product}}
          @boxes={{this.totalUsageBoxes}}
          @data={{@model.totalUsageOverTime}}
          @tileClickAction={{this.goToCategory}}
          @uiScope={{@model.scope.scope}}
        />
      {{/if}}
    </DynamicDashboardChartWrapper>

    <div class="grid w-full auto-rows-[26rem] grid-cols-2 gap-x-7 gap-y-6 xl:grid-cols-3 print:grid-cols-3">

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.courseProgressByGrade.re"}}
        @empty={{not @model.courseProgressByGrade}}
        @tooltip={{t "reporting.tooltips.dashboard.courseProgressByGrade.re"}}
        @linkPath="reporting.ui-scope.re.course-progress.averages"
      >
        {{#if @model.courseProgressByGrade}}
          <ChartsDashboardCourseProgress
            @data={{@model.courseProgressByGrade}}
            @product={{@model.product}}
            @precinct="reading"
          />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.lexileGrowth"}}
        @tooltip={{t "reporting.tooltips.dashboard.lexileGrowth"}}
        @linkPath="reporting.ui-scope.re.lexile-details"
        @empty={{not @model.lexileGrowth}}
      >
        {{#if @model.lexileGrowth}}
          <LexileGrowthDashboardChart @data={{@model.lexileGrowth}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.assessmentScores.re"}}
        @tooltip={{t "reporting.tooltips.dashboard.assessmentScores.re"}}
        @linkPath="reporting.ui-scope.re.quizzes-completed"
        @empty={{not @model.assessmentScores}}
      >
        {{#if @model.assessmentScores}}
          <DashboardAssessmentScoresChart @data={{@model.assessmentScores}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.spellingCourseProgress"}}
        @tooltip={{t "reporting.tooltips.dashboard.spellingCourseProgress"}}
        @linkPath="reporting.ui-scope.re.spelling-progress.averages"
        @empty={{not @model.spellingCourseProgress}}
      >
        {{#if @model.spellingCourseProgress}}
          <ChartsDashboardCourseProgress
            @data={{@model.spellingCourseProgress}}
            @product={{@model.product}}
            @precinct="spelling"
          />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.workingAtGradeLevel"}}
        @tooltip={{t "reporting.tooltips.dashboard.workingAtGradeLevel"}}
        @linkPath="reporting.ui-scope.re.working-at-grade-level"
        @empty={{not @model.workingAtGradeLevel}}
      >
        {{#if @model.workingAtGradeLevel}}
          <WorkingAtGradeLevelDashboardChart @data={{@model.workingAtGradeLevel}} @product={{@model.product}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.storiesWritten"}}
        @tooltip={{t "reporting.tooltips.dashboard.storiesWritten"}}
        @linkPath="reporting.ui-scope.re.stories-written"
        @empty={{not @model.storiesWritten}}
      >
        {{#if @model.storiesWritten}}
          <StoriesWrittenDashboardChart @data={{@model.storiesWritten}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.booksReadByGenre.re"}}
        @tooltip={{t "reporting.tooltips.dashboard.booksReadByGenre"}}
        @linkPath="reporting.ui-scope.re.books-read"
        @empty={{not @model.booksReadByGenre}}
      >
        {{#if @model.booksReadByGenre}}
          <BooksReadByGenreDashboardChart @data={{@model.booksReadByGenre}} @product={{@model.product}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.awardsEarned.re"}}
        @tooltip={{t "reporting.tooltips.dashboard.awardsEarned.re" htmlSafe=true}}
        @linkPath="reporting.ui-scope.re.awards-earned"
        @empty={{not @model.awardsEarned}}
      >
        {{#if @model.awardsEarned}}
          <AwardsEarnedRibbons @data={{@model.awardsEarned}} @withLegend={{true}} />
        {{/if}}
      </StaticDashboardChartWrapper>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingReDashboardRouteTemplate);
