import Model, { attr, hasMany, belongsTo } from '@ember-data/model'
import { get } from '@ember/object'
import { isPresent } from '@ember/utils'
import {
  SYNC_STEPS,
  isSchoolMatchDone,
  areTeacherMatchesDone,
  areStudentMatchesDone,
  schoolMatchStateForStep,
  teacherMatchStateForStep,
  studentMatchStateForStep,
  hasProcessingState,
} from 'district-ui-client/utils/clever/clever-school/sync-process-states'

export default class CleverSchoolModel extends Model {
  @attr('string') name

  @attr('string') sisId

  /**
   * { state: string, city: string }
   */
  @attr() location

  /* counts */

  @attr('number') readingStudentCount

  @attr('number') readingTeacherCount

  @attr('number') readingSectionCount

  @attr('number') mathsStudentCount

  @attr('number') mathsTeacherCount

  @attr('number') mathsSectionCount

  /* booleans */

  @attr('boolean') confirmedMatch

  @attr('boolean') matched

  @attr('boolean') readingCleverApp

  @attr('boolean') mathsCleverApp

  /* state */

  @attr('string') syncProcessState

  /* relations */

  @belongsTo('clever/clever-district', { async: false, inverse: 'cleverSchools' })
  cleverDistrict

  @hasMany('clever/clever-teacher', { async: false, inverse: 'cleverSchool' })
  cleverTeachers

  @hasMany('clever/clever-student', { async: false, inverse: 'cleverSchool' })
  cleverStudents

  @belongsTo('clever/clever-school-match', { async: false, inverse: 'cleverSchool' })
  cleverSchoolMatch

  /** model properties */

  get cityStateJoined() {
    return isPresent(this.location?.city) && isPresent(this.location?.state)
      ? `${this.location.city}, ${this.location.state}`
      : null
  }

  get cleverDistrictId() {
    return get(this, 'cleverDistrict.id')
  }

  get blakeSchool() {
    return get(this, 'cleverSchoolMatch.school')
  }

  get blakeSchoolId() {
    return get(this, 'blakeSchool.id')
  }

  get studentCounts() {
    return { reading: this.readingStudentCount, maths: this.mathsStudentCount }
  }

  get sectionCounts() {
    return { reading: this.readingSectionCount, maths: this.mathsSectionCount }
  }

  get isInitializing() {
    return this.syncProcessState === SYNC_STEPS.INIT
  }

  get isMatchingInProgress() {
    return hasProcessingState(this.syncProcessState)
  }

  get isAwaitingSchoolMatch() {
    return this.syncProcessState === SYNC_STEPS.UNMATCHED_SCHOOL
  }

  get isAwaitingSchoolMatchConfirm() {
    return this.syncProcessState === SYNC_STEPS.UNCONFIRMED_SCHOOL_MATCH
  }

  // school is at any state after the school matching stage
  get isSchoolMatchDone() {
    return isSchoolMatchDone(this.syncProcessState)
  }

  get isAwaitingTeacherMatch() {
    return this.syncProcessState === SYNC_STEPS.AWAITING_TEACHER_MATCH
  }

  get isTeacherMatchingInProgress() {
    return this.syncProcessState === SYNC_STEPS.MATCHING_TEACHERS
  }

  // school is at any state after the teacher matching stage
  get areTeacherMatchesDone() {
    return areTeacherMatchesDone(this.syncProcessState)
  }

  get isAwaitingStudentMatch() {
    return this.syncProcessState === SYNC_STEPS.AWAITING_STUDENT_MATCH
  }

  get isStudentMatchingInProgress() {
    return this.syncProcessState === SYNC_STEPS.MATCHING_STUDENTS
  }

  // school is at any state after the student matching stage
  get areStudentMatchesDone() {
    return areStudentMatchesDone(this.syncProcessState)
  }

  get isAwaitingSync() {
    return this.syncProcessState === SYNC_STEPS.AWAITING_SYNC
  }

  get isSyncInProgress() {
    return this.syncProcessState === SYNC_STEPS.SYNC_IN_PROGRESS
  }

  get hasCompletedSync() {
    return this.syncProcessState === SYNC_STEPS.COMPLETE
  }

  get matchSchoolState() {
    return schoolMatchStateForStep(this.syncProcessState)
  }

  get matchTeachersState() {
    return teacherMatchStateForStep(this.syncProcessState)
  }

  get matchStudentsState() {
    return studentMatchStateForStep(this.syncProcessState)
  }
}
