import Service from '@ember/service'
import { capitalize } from '@ember/string'
import type Owner from '@ember/owner'
import { getAssetUrl } from 'district-ui-client/utils/contentful'
import { createClient } from 'contentful'
import type { ContentfulClientApi } from 'contentful'
import type { DiscoGuideSkeleton } from 'district-ui-client/utils/contentful'
import config from 'district-ui-client/config/environment'

type SubscriptionType = 'reading' | 'maths'

interface RequestParamsRequired {
  content_type: string
}
interface RequestParamsOptional {
  locale: string
  limit: number
  include: number
  [param: string]: string | number | boolean | undefined
}

type RequestParams = RequestParamsRequired & Partial<RequestParamsOptional>

export default class ContentfulClientService extends Service {
  client: ContentfulClientApi<undefined>

  constructor(owner: Owner) {
    super(owner)
    this.client = createClient(this.clientConfig)
  }

  // add any other environments you want to select, to this list. They should be accessible via the develop tokens
  get availableEnvironments() {
    return ['master', 'develop']
  }

  get clientConfig() {
    let selectedEnv = config.contentful.defaultEnvironment
    let usePreview = false

    if (config.contentful.showSelector) {
      const envFromStorage = window.localStorage.getItem('district-ui-client.contentful.selected-environment') ?? ''
      if (this.availableEnvironments.includes(envFromStorage)) selectedEnv = envFromStorage

      usePreview = window.localStorage.getItem('district-ui-client.contentful.use-preview') === 'true'
    }

    const settings = {
      environment: selectedEnv,
      space: config.contentful.spaceId,
      host: usePreview ? config.contentful.previewHost : undefined,
      accessToken: '',
    }

    // the drop-down options of "master" and "develop" are Contentful
    // environment aliases that usually point at the latest production
    // environment ("main-dd-mm-yyy"), the  "develop" environment can be
    // switched via the Contentful dashboard at
    // https://app.contentful.com/spaces/ffboqiwq61pe/settings/environments
    // the "master" alias is only updated as part of releases and rollbacks
    // as per https://www.contentful.com/developers/docs/concepts/environment-aliases#enabling-risk-free-releases-and-instant-rollbacks
    if (selectedEnv === 'master') {
      settings.accessToken = usePreview ? config.contentful.master.previewToken : config.contentful.master.accessToken
    } else {
      settings.accessToken = usePreview ? config.contentful.develop.previewToken : config.contentful.develop.accessToken
    }

    return settings
  }

  async getDiscoGuides({ subscriptionType, title }: { subscriptionType?: SubscriptionType; title?: string }) {
    const requestParams: RequestParams = {
      content_type: 'discoGuide',
    }
    if (subscriptionType) requestParams['fields.subscriptionType'] = capitalize(subscriptionType)
    if (title) requestParams['fields.title'] = title

    const { items } = await this.client.withoutUnresolvableLinks.getEntries<DiscoGuideSkeleton>(requestParams)
    return items
  }

  async getCleverGuidePdfs() {
    const cleverGuideEntries = await this.getDiscoGuides({ title: 'Clever Guide' })
    const readingEntry = cleverGuideEntries.find((entry) => entry.fields.subscriptionType === 'Reading')
    const mathsEntry = cleverGuideEntries.find((entry) => entry.fields.subscriptionType === 'Maths')

    return {
      reading: readingEntry ? getAssetUrl(readingEntry.fields.file) : undefined,
      maths: mathsEntry ? getAssetUrl(mathsEntry.fields.file) : undefined,
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'contentful-client': ContentfulClientService
  }
}
