import Controller from '@ember/controller'
import { action } from '@ember/object'
import { service } from '@ember/service'

export default class ApplicationErrorController extends Controller {
  @service('logout') logoutService

  @action
  logout() {
    this.logoutService.logoutAndRedirect()
  }
}
