import { service } from '@ember/service'
import Controller from '@ember/controller'
import { set, action } from '@ember/object'

export default class EditController extends Controller {
  @service
  session

  @service
  alert

  @service
  gradeSets

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  @service
  activeRoute

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get student() {
    return this.model.student
  }

  updatingStudent = false

  @action
  cancel() {
    this.student.rollbackAttributes()
    this._backToPrevious()
  }

  @action
  updateGrade(position) {
    this.student.gradePosition = parseInt(position, 10)
  }

  @action
  async updateStudent() {
    const passwordLength = 4
    const { student } = this

    const plainPassword = student.plainPassword || ''
    const firstName = student.firstName || ''
    const lastName = student.lastName || ''
    const login = student.login || ''

    if (firstName.trim().length === 0) return this._formError('First Name is required')
    if (lastName.trim().length === 0) return this._formError('Last Name is required')
    if (login.trim().length === 0) return this._formError('Login is required')
    if (plainPassword.length < passwordLength) {
      return this._formError(`Password must be at least ${passwordLength} characters`)
    }
    try {
      set(this, 'updatingStudent', true)
      await student.save()
      set(this, 'updatingStudent', false)
      this._backToPrevious()
      this.alert.showWithDismiss({
        type: 'positive',
        message: `Successfully updated student ${student.fullName}`,
      })
    } catch {
      student.rollbackAttributes()
      set(this, 'updatingStudent', false)
      return this._showModelError()
    }
  }

  _showModelError() {
    if (this.model.student.errors.messages) {
      this.model.student.errors.messages.forEach((error) => {
        this._formError(error.detail)
      })
    }
  }

  _formError(message) {
    this.alert.showWithDismiss({
      type: 'critical',
      message,
    })
  }

  _backToPrevious() {
    window.history.back()
  }
}
