import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import isFinite from 'lodash/isFinite';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import { reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config';
import BlakeColors from '@blakeelearning/blake-colours/colours';
import SeriesColumnRange from 'district-ui-client/components/primitives/series-column-range/component';
import type { IntlService } from 'ember-intl';
import type GradeSetsService from 'district-ui-client/services/grade-sets';
import { t } from 'ember-intl';
import type { TooltipFormatterCallbackFunction } from 'highcharts';
import type { TooltipFormatterContextObject } from 'highcharts';
import type { SeriesColumnrangeOptions } from 'highcharts';
export interface LexileGrowthData {
    lexile_growth_by_grade: {
        grades: {
            grade_position: number;
            from_lexile_growth: number;
            to_lexile_growth: number;
            student_count: number;
        }[];
        student_count: number;
    };
}
interface Signature {
    Args: {
        data: LexileGrowthData;
    };
}
export class LexileGrowthDashboardChart extends Component<Signature> {
    @service
    gradeSets: GradeSetsService;
    @service
    intl: IntlService;
    get grades() {
        return this.args.data.lexile_growth_by_grade.grades;
    }
    get sortedGrades() {
        return [
            ...this.grades
        ].sort((a1, b1)=>a1.grade_position - b1.grade_position);
    }
    get colour() {
        return BlakeColors.oceanyBlue300;
    }
    get chartData(): SeriesColumnrangeOptions[] {
        const color1 = this.colour;
        const data1 = this.sortedGrades.map((gradeInfo1)=>{
            const { from_lexile_growth: lexileFrom1, to_lexile_growth: lexileTo1, student_count: studentCount1 } = gradeInfo1;
            if ([
                studentCount1,
                lexileFrom1,
                lexileTo1
            ].every(isFinite)) {
                const avgMinlexile1 = Math.round(lexileFrom1);
                const avgMaxlexile1 = Math.round(lexileTo1);
                return [
                    avgMinlexile1,
                    avgMaxlexile1
                ];
            }
            return [
                0,
                0
            ];
        });
        return [
            {
                data: data1,
                color: color1,
                type: 'columnrange'
            }
        ];
    }
    get subtitleKey() {
        if (this.isSingleGrade) return 'reporting.dashboardSubtitles.singleGrade.lexileGrowth';
        return 'reporting.dashboardSubtitles.multiGrade';
    }
    get isSingleGrade() {
        return this.grades.length === 1;
    }
    get categories() {
        const { sortedGrades: sortedGrades1 } = this;
        return sortedGrades1.map((grade1)=>this.gradeSets.findByPosition(grade1.grade_position)?.name ?? '-');
    }
    get studentCount() {
        const count1 = this.args.data?.lexile_growth_by_grade?.student_count;
        return formatNumber(count1, 2);
    }
    get tooltipFormatter(): TooltipFormatterCallbackFunction {
        return function(this1: TooltipFormatterContextObject) {
            const formatPoint1 = this.point as Highcharts.Point & {
                low: number;
                high: number;
            };
            if (formatPoint1.low === formatPoint1.high) {
                return `<b>${formatPoint1.high}L</b>`;
            }
            return `<b>From ${formatPoint1.low}L to ${formatPoint1.high}L</b>`;
        };
    }
    get toolTip() {
        return toolTipConfig('y', '', {
            formatter: this.tooltipFormatter
        });
    }
    /**
   * Returns a useful datalabel string, if the point is a high point. If low point, returns an empty string.
   */ dataLabelsFormatter(this1: {
        point: {
            low: number;
            high: number;
            dataLabelUpper: string;
        };
    }) {
        const growth1 = this.point.high - this.point.low;
        return this.point.dataLabelUpper ? '' : `+${growth1}`;
    }
    static{
        template(`
    <div class="flex h-full flex-col" data-test-lexile-growth>
      <div data-test-dashboard-subtitle class="flex justify-between gap-4 text-xs">
        <span>
          {{t this.subtitleKey}}
        </span>
        <span>
          {{t "reporting.dashboardSubtitles.studentCount" count=this.studentCount}}
        </span>
      </div>

      <SeriesColumnRange
        @categories={{this.categories}}
        @data={{this.chartData}}
        @toolTip={{this.toolTip}}
        @xAxisLabel={{t "grade.label"}}
        @yAxisFloor={{0}}
        @dataLabelsEnabled={{true}}
        @dataLabelsFormatter={{this.dataLabelsFormatter}}
      />

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default LexileGrowthDashboardChart;
