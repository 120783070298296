// <MailTo
//   email='example.com'
//   cc=['cc-1@example.com','cc-2@example.com']
//   subject='The Subject'
//   body='The Body'
// >
//   This is the link text or element
// </MailTo?
import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
interface Signature {
    Args: {
        email: string;
        cc: string[];
        subject: string;
        body: string;
    };
    Blocks: {
        default: [];
    };
}
export class MailTo extends Component<Signature> {
    get href() {
        const params1 = [];
        const email1 = this.args.email || '';
        const encodedEmail1 = encodeURIComponent(email1);
        const cc1 = this.args.cc || '';
        if (cc1.length > 0) {
            const encodedCC1 = cc1.map(encodeURIComponent);
            const ccSegment1 = `cc=${encodedCC1.join(',')}`;
            params1.push(ccSegment1);
        }
        const { subject: subject1 } = this.args;
        if (subject1) {
            const encodedSubject1 = encodeURIComponent(subject1);
            params1.push(`subject=${encodedSubject1}`);
        }
        const { body: body1 } = this.args;
        if (body1) {
            const encodedBody1 = encodeURIComponent(body1);
            params1.push(`body=${encodedBody1}`);
        }
        const href1 = `mailto:${encodedEmail1}?${params1.join('&')}`;
        return href1;
    }
    static{
        template(`
    <a
      data-test-mail-to-anchor
      href={{this.href}}
      class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
    >{{yield}}</a>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default MailTo;
