import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export const DividerHorizontal: TOC<{
    Element: HTMLHRElement;
}> = template(`
  <hr class="h-[2px] bg-neutral-50" ...attributes />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
