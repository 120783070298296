import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { reportingLegend, reportingPlotLine } from 'district-ui-client/utils/giraffe/highcharts-config';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { gradePositionFromLesson, lessonRangeFromGradePosition } from 'district-ui-client/utils/giraffe/lesson-grade-map';
import BlakeColors from '@blakeelearning/blake-colours/colours';
import type GradeSetsService from 'district-ui-client/services/grade-sets';
import type { ReportingProductSlug } from 'district-ui-client/domain/product';
import type { TooltipFormatterCallbackFunction } from 'highcharts';
import type { AxisLabelsFormatterCallbackFunction } from 'highcharts';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import SeriesLine from 'district-ui-client/components/primitives/series-line/component';
import type { SeriesLineOptions } from 'highcharts';
import type { YAxisPlotLinesOptions } from 'highcharts';
/**
 * Format the value based on the current precinct.
 */ function valueFormatter(value1: number | string, product1: ReportingProductSlug, precinct1: string) {
    if (product1 === 'rex' && precinct1 === 'spelling') {
        let v1 = value1;
        if (typeof v1 === 'string') {
            v1 = parseInt(v1);
        }
        const maxLessons1 = 36;
        const grade1 = Math.ceil(v1 / maxLessons1);
        const lesson1 = `0${v1 % maxLessons1}`.slice(-2).replace('00', maxLessons1.toString());
        return `${grade1}.${lesson1}`;
    }
    return String(value1);
}
/**
 * Puts together all the pieces for the course progress averages chart.
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   months: [
 *     {
 *       avg_max_lesson: 50,
 *       month: '2016-07',
 *     },
 *     {
 *       avg_max_lesson: 70,
 *       month: '2016-08',
 *     },
 *   ],
 * }
 * ```
 */ interface CourseProgressMonthData {
    avg_max_lesson: number;
    month: string;
}
export interface CourseProgressAveragesData {
    grade_position: number;
    months: CourseProgressMonthData[];
}
interface Signature {
    Args: {
        title: string;
        data: CourseProgressAveragesData;
        precinct: 'lessons' | 'reading' | 'spelling' | 'my_lessons';
        product: 'maths' | 're' | 'rex';
        tooltip?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsCourseProgressAverages extends Component<Signature> {
    @service
    gradeSets: GradeSetsService;
    get hyphenatedTitle() {
        const mainChartTitle1 = this.args.title;
        const gradePosition1 = this.args.data.grade_position;
        if (typeof gradePosition1 !== 'number') return `${mainChartTitle1}`;
        const gradeSuffix1 = this.gradeSets.findByPosition(gradePosition1)?.fullName;
        return `${mainChartTitle1} - ${gradeSuffix1}`;
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get chartData(): SeriesLineOptions[] {
        const data1 = this.args.data.months?.map((monthData1, index1)=>({
                x: index1,
                y: monthData1.avg_max_lesson,
                monthData: monthData1
            })) ?? [];
        return [
            {
                name: 'avg_max_lesson',
                color: this.color,
                data: data1,
                type: 'line'
            }
        ];
    }
    get color() {
        const { product: product1, precinct: precinct1 } = this.args;
        switch(`${product1}.${precinct1}`){
            case 'maths.lessons':
                return BlakeColors.forestGreen300;
            case 're.spelling':
            case 'rex.spelling':
                return BlakeColors.grapeyGreen300;
            case 're.reading':
            case 'rex.my_lessons':
                return BlakeColors.oceanyBlue300;
            default:
                return BlakeColors.oceanyBlue300;
        }
    }
    /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get categories() {
        return this.args.data.months?.map((m1)=>convertDateFormat(m1.month)) ?? [];
    }
    get legend() {
        return reportingLegend('left', 'top', 50, 10);
    }
    /**
   * Plot lines to mark the grade bands for the lesson values.
   */ get plotLines(): YAxisPlotLinesOptions[] {
        const { product: product1, precinct: precinct1 } = this.args;
        const months1 = this.args.data.months ?? [];
        const lessons1 = months1.map((m1)=>m1.avg_max_lesson).filter((avgMaxLesson1)=>!isEmpty(avgMaxLesson1));
        if (isEmpty(lessons1)) return [];
        const grades1 = lessons1.map((lesson1)=>gradePositionFromLesson(product1, precinct1, lesson1)).filter((grade1): grade is number =>typeof grade1 === 'number');
        // Add the _next_ grade to the list
        const uniqueGrades1 = [
            ...new Set(grades1)
        ];
        uniqueGrades1.push(grades1[grades1.length - 1] + 1);
        const plotLines1 = uniqueGrades1.map((grade1)=>{
            const { min: lesson1 } = lessonRangeFromGradePosition(product1, precinct1, grade1) as {
                min?: number;
            };
            const label1 = this.gradeSets.findByPosition(grade1)?.fullName ?? '';
            if (typeof lesson1 === 'number') return reportingPlotLine(lesson1, label1);
            return null;
        });
        return plotLines1.filter((item1): item is YAxisPlotLinesOptions =>item1 !== null);
    }
    /**
   * Returns a formatter function to pass to Highcharts to format the tooltip label.
   */ get toolTipFormatter(): TooltipFormatterCallbackFunction {
        const { product: product1, precinct: precinct1 } = this.args;
        return function() {
            return `<strong>${valueFormatter(this.y, product1, precinct1)}</strong>`;
        };
    }
    /**
   * Returns a formatter function to pass to Highcharts to format the y axis label.
   */ get yAxisLabelFormatter(): AxisLabelsFormatterCallbackFunction {
        const { product: product1, precinct: precinct1 } = this.args;
        return function() {
            return valueFormatter(this.value, product1, precinct1);
        };
    }
    /**
   * Set the yRange of the chart to include all lessons for all grades in the data
   */ get yRange() {
        const { product: product1, precinct: precinct1 } = this.args;
        const months1 = this.args.data.months ?? [];
        const grades1 = months1.reduce<number[]>((acc1, monthData1)=>{
            if (monthData1.avg_max_lesson) {
                const grade1 = gradePositionFromLesson(product1, precinct1, monthData1.avg_max_lesson);
                return typeof grade1 !== 'number' || acc1.includes(grade1) ? acc1 : [
                    ...acc1,
                    grade1
                ];
            }
            return acc1;
        }, []);
        // map returns [ {min, max}, {min, max}]
        const allLessons1 = grades1.map((grade1)=>lessonRangeFromGradePosition(product1, precinct1, grade1)).reduce<number[]>((prev1, curr1)=>{
            const { min: min1, max: max1 } = curr1;
            return prev1.concat(min1, max1);
        }, []).sort((a1, b1)=>a1 - b1);
        if (isEmpty(allLessons1)) return [
            -Infinity,
            Infinity
        ];
        // add one lesson to ensure the next grade plotLine is shown for context
        allLessons1.push(allLessons1[allLessons1.length - 1] + 1);
        return [
            allLessons1[0],
            allLessons1[allLessons1.length - 1] + 1
        ];
    }
    get chartSpacing(): [number, number, number, number] {
        return [
            10,
            10,
            10,
            10
        ];
    }
    static{
        template(`
    <div data-test-course-progress-averages class="relative rounded-md bg-white" ...attributes>
      <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
      <SeriesLine
        @title={{this.hyphenatedTitle}}
        @data={{this.chartData}}
        @categories={{this.categories}}
        @chartSpacing={{this.chartSpacing}}
        @yAxisLabel="Lesson number"
        @yAxisLabelFormatter={{this.yAxisLabelFormatter}}
        @toolTipFormatter={{this.toolTipFormatter}}
        @plotLines={{this.plotLines}}
        @yRange={{this.yRange}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsCourseProgressAverages;
