import Component from '@glimmer/component'
import type { ComponentLike } from '@glint/template'
import { action } from '@ember/object'
import {
  min,
  max,
  addDays,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns'
import { assert } from '@ember/debug'

export interface PickerSignature {
  Element: HTMLElement
  Args: { largeCells?: boolean; colorCells?: boolean; afterUpdate?: () => void }
  Blocks: { default: [{ nav: ComponentLike; days: ComponentLike }] }
}

interface Signature {
  Element: HTMLElement
  Args: {
    startDate: Date
    endDate?: Date | null // optional, you can use this component as a single date picker if you like!
    maxDays?: number
    onDateSelect?: ({
      startDate,
      endDate,
    }: {
      startDate: Date
      endDate?: Date | null | undefined
    }) => unknown
  }
  Blocks: {
    default: [
      {
        startDatePicker: ComponentLike<PickerSignature>
        endDatePicker: ComponentLike<PickerSignature>
        durationDays: number | null
        setRelativeDays: (daysOrEvent: number | Event) => void
        setAbsoluteDays: (daysOrEvent: number | Event) => void
      },
    ]
    abc: [string]
  }
}

/**
 * This is a barebones date component. This component is responsible for handling date logic like;
 * - end date must be on or after start date + 1 & today
 * - updating dates when duration changes
 * - min/max date. min date always "today". max date should be never, but allows a max duration to be given (eg 365 days)
 * - providing a date picker component (currently ember-power-calendar)
 *
 * Can also be used as a single date picker control - just dont use/include the end date or duration.
 *
 * Using this component, you can add your own inputs, labels, buttons, styles, themes...
 *
 * Usage:
 * <Dates::TwoDatePicker>
 *   @startDate={{this.myStartDate}}
 *   @endDate={{this.myEndDate}}
 *   @maxDays={{365}}
 *   @onDateSelect={{this.onDateSelect}}
 *   as |picker|
 * >
 *   Start Date: {{this.myStartDate}}
 *   End Date: {{this.myEndDate}}
 *   <picker.startDatePicker />
 *   <picker.endDatePicker />
 *   <button {{on "click" (fn picker.setAbsoluteDays 7)}}>1 week</button>
 *   <input type="number" value={{picker.durationDays}} {{on "input" picker.setAbsoluteDays}} >
 * </Dates::TwoDatePicker>
 */
export default class TwoDatePickerComponent extends Component<Signature> {
  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args)
    assert(
      'you must pass an "startDate" to this component',
      args.startDate instanceof Date,
    )
  }

  get duration() {
    const { startDate, endDate } = this.args
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (startDate && endDate) {
      return differenceInCalendarDays(endDate, startDate)
    }

    return null
  }

  get maxDays() {
    return this.args.maxDays ?? 365
  }

  /** smallest of today vs start date */
  get minStartDate() {
    const today = new Date()
    return min([this.args.startDate, today])
  }

  get minEndDate() {
    return this.endDateBounds(this.args.startDate).minEndDate
  }

  get maxEndDate() {
    return this.endDateBounds(this.args.startDate).maxEndDate
  }

  /**
   * Calculates end date bounds based on a given start date.
   * Needs to be variable start because we don't always want to use the start date from component args
   */
  endDateBounds(startDate: Date) {
    // end date selection must be at least on or after; the day after startdate, and today
    const today = new Date()
    const dayAfterStartDate = addDays(startDate, 1)
    const minEndDate = max([dayAfterStartDate, today])

    const maxEndDate = addDays(startDate, this.maxDays)
    return { minEndDate, maxEndDate }
  }

  /**
   * Bound end date by given start date
   */
  boundEndDate(startDate: Date, endDate: Date) {
    const bounds = this.endDateBounds(startDate)
    if (isSameDay(endDate, bounds.minEndDate) || endDate < bounds.minEndDate) {
      return bounds.minEndDate
    }
    if (isSameDay(endDate, bounds.maxEndDate) || endDate > bounds.maxEndDate) {
      return bounds.maxEndDate
    }
    return endDate
  }

  @action
  onStartDateSelect(startDate: Date) {
    const { endDate } = this.args
    const boundedEndDate = endDate
      ? this.boundEndDate(startDate, endDate)
      : endDate
    // When a start date is selected, always ensure the min/max end date rules are upheld
    this.args.onDateSelect?.({ startDate, endDate: boundedEndDate })
  }

  @action
  onEndDateSelect(endDate: Date) {
    this.args.onDateSelect?.({ startDate: this.args.startDate, endDate })
  }

  /** Sets end date to exactly this number of days from the start date */
  @action
  setAbsoluteDays(daysOrEvent: number | Event) {
    const days = this.parseDaysOrEvent(daysOrEvent)
    this.setDays(days, false)
  }

  /** Changes end date, based on the relative value given (+7 -> add 7 days, -7 -> minus 7 days) */
  @action
  setRelativeDays(daysOrEvent: number | Event) {
    const days = this.parseDaysOrEvent(daysOrEvent)
    this.setDays(days, true)
  }

  parseDaysOrEvent(daysOrEvent: number | Event): number {
    let days: number
    if (typeof daysOrEvent === 'number') {
      days = daysOrEvent
    } else {
      assert(
        'event target for setting days must be input element',
        daysOrEvent.target instanceof HTMLInputElement,
      )
      days = parseInt(daysOrEvent.target.value, 10)
    }
    return days
  }

  /** Sets end date based on the number of days given. Relative days will adjust the end date +/- that number of days.
   * Else exact will set end date to exactly that number of days from start date
   */
  @action
  setDays(days: number, isRelative: boolean) {
    const { startDate, endDate } = this.args
    if (Number.isInteger(days) && endDate) {
      const desiredEndDate = addDays(isRelative ? endDate : startDate, days)
      this.args.onDateSelect?.({
        startDate,
        endDate: this.boundEndDate(startDate, desiredEndDate),
      })
    }
  }
}
