import Controller from '@ember/controller'
import { service } from '@ember/service'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type SessionService from 'district-ui-client/services/session'

export default class IndexController extends Controller {
  @service activeRoute!: ActiveRouteService

  @service session!: SessionService

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get currentDistrict() {
    return this.session.currentDistrict
  }

  @tracked showRolloverModal = true

  @action
  onCloseRolloverModal() {
    this.showRolloverModal = false
  }
}

declare module '@ember/controller' {
  interface Registry {
    'subscription-type/index': IndexController
  }
}
