import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type ReportingService from 'district-ui-client/services/reporting';
import type SessionService from 'district-ui-client/services/session';
// @ts-expect-error not typescripted yet
import DatesRangeDatePicker from '@blakeelearning/dates/components/dates/range-date-picker';
import mapKeys from 'lodash/mapKeys';
import { camelize } from '@ember/string';
import { formatDate } from 'district-ui-client/services/reporting';
import type { IntlService } from 'ember-intl';
import { Pill } from 'district-ui-client/components/pills';
interface Signature {
    Element: HTMLElement;
    Args: {
        onDateChange: (dateRange: string) => void;
        disabled?: boolean;
    };
}
export default class ReportingPeriodSelect extends Component<Signature> {
    @service
    reporting: ReportingService;
    @service
    session: SessionService;
    @service
    intl: IntlService;
    /**
   * from
   * { 'this-week': 'This week', ... }
   * to
   * { thisWeek: 'This week', ... }
   */ get presetDateRangeLabels() {
        return mapKeys(this.reporting.periodNames, (_value1, key1)=>camelize(key1));
    }
    get selectedDateRange() {
        return this.reporting.periodDateRange;
    }
    get periodName() {
        return this.reporting.periodNameKey ? this.reporting.periodNames[this.reporting.periodNameKey] : this.intl.t('presetDateRangeLabels.custom');
    }
    static{
        template(`
    <DatesRangeDatePicker
      data-test-period-select
      @inputValueFormatted={{this.periodName}}
      @countryCode={{this.session.currentDisco.countryCode}}
      @presetDateRangeLabels={{this.presetDateRangeLabels}}
      {{! ThemedDropdownButton @style="subtle" with some extra classes to turn off some built-in styles }}
      @inputClass="cursor-pointer h-auto border-none shadow-none focus:border-none focus:shadow-none focus:outline-none rounded-lg px-4 py-2 text-sm leading-5 transition-colors text-neutral-400 open:text-blue-350 disabled:text-neutral-250 bg-neutral-50 hover:bg-neutral-75 active:bg-neutral-100 open:bg-blue-100 disabled:bg-neutral-50 disabled:cursor-default [&:disabled+svg]:text-neutral-250 font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-325 focus-visible:outline-offset-2 select-none"
      @contentClass="bg-white shadow-xl border border-dusty-black-100 rounded-lg my-px"
      @selectedDateRange={{this.selectedDateRange}}
      @onDateRangeSelected={{@onDateChange}}
      @isDisabled={{@disabled}}
      ...attributes
    />
    <Pill class="mt-1 w-fit" @disabled={{@disabled}}>
      {{formatDate this.reporting.period.start}}
      -
      {{formatDate this.reporting.period.end}}
    </Pill>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
