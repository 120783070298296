import { template } from "@ember/template-compiler";
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { Interval } from '@blakeelearning/stable-table/utils/st/split-duration';
import { serialize as serializeSort, deserialize as deserializeSort, type SortingConfig } from '@blakeelearning/data-tables/utils/sorting';
import type { IntlService } from 'ember-intl';
import type { ReportingProductSlug } from 'district-ui-client/domain/product';
import type School from 'district-ui-client/models/school';
import { BaseTableActions } from 'district-ui-client/components/base/table-actions';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import type { CellObject } from '@blakeelearning/stable-table/components/basic-table/cell';
export interface SchoolsData {
    school_id: number;
    student_count: number;
    total_time: number;
    lessons_completed: number;
    average_total_time_per_student: number;
    average_lessons_completed_per_student: number;
    average_lexile_growth: number;
}
export interface AveragesAndTotalsData {
    average_total_time_per_school: number;
    average_lessons_completed_per_school: number;
    average_total_time_per_student: number;
    average_lessons_completed_per_student: number;
    average_lexile_growth: number;
    student_count: number;
    lessons_completed: number;
    total_time: number;
}
interface Signature {
    Args: {
        product: ReportingProductSlug;
        schools: School[];
        schoolsData?: SchoolsData[];
        averagesAndTotalsData?: AveragesAndTotalsData;
    };
}
type DataKey = 'schoolName' | 'studentCount' | 'totalTime' | 'lessonsCompleted' | 'averageTotalTimePerStudent' | 'averageLessonsCompletedPerStudent' | 'averageLexileGrowth';
export class SummaryTableSchools extends Component<Signature> {
    @service
    intl: IntlService;
    @tracked
    sort = '';
    get sortingConfig() {
        return deserializeSort(this.sort);
    }
    set sortingConfig(newSortConfig1) {
        this.sort = serializeSort(newSortConfig1);
    }
    onSortClick = (sortingConfig1: SortingConfig)=>{
        this.sortingConfig = sortingConfig1;
    };
    /**
   * An array of keys used to identify data columns, also serving as the key/path to data within a row object.
   */ get dataKeys(): DataKey[] {
        const dataKeys1: DataKey[] = [
            'schoolName',
            'studentCount',
            'totalTime',
            'lessonsCompleted',
            'averageTotalTimePerStudent',
            'averageLessonsCompletedPerStudent'
        ];
        return this.args.product === 'maths' ? dataKeys1 : [
            ...dataKeys1,
            'averageLexileGrowth'
        ];
    }
    /**
   * Transforms an array of data keys into column objects. The column objects are used by the table to determine where
   * to get data from for each cell in the column, how to sort that column, etc
   *
   * See the BasicTable readme for more info on column objects
   */ get columns() {
        const { dataKeys: dataKeys1, comparersForColumns: comparersForColumns1, durationConfig: durationConfig1, intl: intl1 } = this;
        return dataKeys1.map((dataKey1)=>{
            const value1 = intl1.t(`reporting.district.summaryReportTitles.${this.args.product}.${dataKey1}`);
            const isTimeColumn1 = [
                'totalTime',
                'averageTotalTimePerStudent'
            ].includes(dataKey1);
            const replaceZeroColumn1 = [
                'studentCount',
                'lessonsCompleted',
                'averageLessonsCompletedPerStudent',
                'averageLexileGrowth'
            ].includes(dataKey1);
            const headerClasses1 = this.headerClasses(this.args.product, dataKey1);
            const cellClasses1 = this.cellClasses(this.args.product, dataKey1);
            return {
                value: value1,
                valuePath: dataKey1,
                component: 'basic-cells/sortable',
                comparerKey: comparersForColumns1[dataKey1],
                cellComponent: isTimeColumn1 ? 'basic-cells/duration-unit' : undefined,
                durationConfig: isTimeColumn1 ? durationConfig1 : undefined,
                formatEmptyConfig: replaceZeroColumn1 ? {
                    replaceZero: true
                } : undefined,
                classNames: `table-box table-box-header ${headerClasses1}`,
                cellClassNames: `table-box table-box-cell ${cellClasses1}`
            };
        });
    }
    /**
   * Returns an object that maps data keys to comparer keys, which tell the sorting system how to sort each column
   */ get comparersForColumns(): Partial<Record<DataKey, string>> {
        return {
            schoolName: 'sortByValue',
            studentCount: 'sortByNumber',
            totalTime: 'sortByNumber',
            lessonsCompleted: 'sortByNumber',
            averageTotalTimePerStudent: 'sortByNumber',
            averageLessonsCompletedPerStudent: 'sortByNumber',
            averageLexileGrowth: 'sortByNumber'
        };
    }
    /**
   * Defines how the durations should be formatted
   */ get durationConfig() {
        const splitDurationOptions1 = {
            max: Interval.Hours,
            min: Interval.Minutes,
            maxIntervals: 2
        };
        const dataInterval1 = 'seconds';
        return {
            dataInterval: dataInterval1,
            splitDurationOptions: splitDurationOptions1
        };
    }
    /**
   * Obtain extra class names for the column header, cell or footer cell
   */ headerClasses(product1: ReportingProductSlug, dataKey1: DataKey) {
        const defaultClass1 = product1 === 'maths' ? 'bg-ms-green-100' : 'bg-oceany-blue-100';
        const mapping1: Partial<Record<DataKey, string>> = {
            totalTime: 'data-column bg-purply-pink-350 text-white',
            lessonsCompleted: 'data-column bg-juicy-orange-350 text-white',
            averageTotalTimePerStudent: 'data-column bg-oceany-blue-300 text-white',
            averageLessonsCompletedPerStudent: 'data-column bg-ms-green-350 text-white',
            averageLexileGrowth: 'data-column bg-storm-blue-350 text-white'
        };
        return mapping1[dataKey1] || defaultClass1;
    }
    cellClasses(product1: ReportingProductSlug, dataKey1: DataKey) {
        const colorClass1 = product1 === 'maths' ? 'bg-ms-green-50 group-hover:bg-ms-green-100' : 'bg-oceany-blue-50 group-hover:bg-oceany-blue-100';
        const mapping1: Partial<Record<DataKey, string>> = {
            totalTime: 'text-center',
            lessonsCompleted: 'text-center',
            averageTotalTimePerStudent: 'text-center',
            averageLessonsCompletedPerStudent: 'text-center',
            averageLexileGrowth: 'text-center'
        };
        const customClass1 = mapping1[dataKey1] || '';
        return `${colorClass1} ${customClass1}`;
    }
    footerCellClasses(product1: ReportingProductSlug, dataKey1: DataKey) {
        const defaultClass1 = 'table-footer-cell border-t print:border-black';
        const colorClass1 = product1 === 'maths' ? 'border-ms-green-100' : 'border-oceany-blue-100';
        const mapping1: Partial<Record<DataKey, string>> = {
            totalTime: 'text-center',
            lessonsCompleted: 'text-center',
            averageTotalTimePerStudent: 'text-center',
            averageLessonsCompletedPerStudent: 'text-center',
            averageLexileGrowth: 'text-center'
        };
        const customClass1 = mapping1[dataKey1] || '';
        return `${defaultClass1} ${colorClass1} ${customClass1}`;
    }
    /**
   * Map each teacher to a row object, each row containing the keys (with data) that match what the column will use to
   * retrieve it
   */ get rows() {
        const schools1 = this.args.schools || [];
        const schoolsData1 = this.args.schoolsData || [];
        const { dataKeys: dataKeys1 } = this;
        return schools1.map(({ id: id1, name: schoolName1 })=>{
            const data1 = schoolsData1.find(({ school_id: school_id1 })=>school_id1 === Number(id1));
            // We don't need any per-cell level configuration, so just use an empty array of cellObjects
            const cells1 = Array(dataKeys1.length).fill({});
            if (!data1) {
                return {
                    schoolName: schoolName1,
                    classNames: 'table-row-striped group',
                    cells: cells1
                };
            }
            return {
                schoolName: schoolName1,
                studentCount: data1.student_count,
                totalTime: data1.total_time,
                lessonsCompleted: data1.lessons_completed,
                averageTotalTimePerStudent: data1.average_total_time_per_student,
                averageLessonsCompletedPerStudent: data1.average_lessons_completed_per_student,
                averageLexileGrowth: data1.average_lexile_growth,
                // classes for row striping and hovering the row of cells as a group (via tailwind group-hover)
                classNames: 'table-row-striped group',
                cells: cells1
            };
        });
    }
    /**
   * This returns an object of data keys that map to cellObjects for the average row of the footer
   */ get averageCellsForColumns(): Partial<Record<DataKey, CellObject>> {
        const averagesAndTotalsData1: Partial<AveragesAndTotalsData> = this.args.averagesAndTotalsData ?? {};
        const { durationConfig: durationConfig1, intl: intl1 } = this;
        return {
            schoolName: {
                value: intl1.t(`reporting.district.summaryReportTitles.${this.args.product}.average`)
            },
            studentCount: {
                value: intl1.t('reporting.district.summaryReportTitles.na')
            },
            totalTime: {
                component: 'basic-cells/duration-unit',
                durationConfig: durationConfig1,
                value: averagesAndTotalsData1.average_total_time_per_school
            },
            lessonsCompleted: {
                value: averagesAndTotalsData1.average_lessons_completed_per_school
            },
            averageTotalTimePerStudent: {
                component: 'basic-cells/duration-unit',
                durationConfig: durationConfig1,
                value: averagesAndTotalsData1.average_total_time_per_student
            },
            averageLessonsCompletedPerStudent: {
                value: averagesAndTotalsData1.average_lessons_completed_per_student
            },
            averageLexileGrowth: {
                value: averagesAndTotalsData1.average_lexile_growth
            }
        };
    }
    /**
   * This returns an object of data keys that map to cellObjects for the totals row of the footer
   */ get totalCellsForColumns(): Partial<Record<DataKey, CellObject>> {
        const averagesAndTotalsData1: Partial<AveragesAndTotalsData> = this.args.averagesAndTotalsData ?? {};
        const { durationConfig: durationConfig1, intl: intl1 } = this;
        return {
            schoolName: {
                value: intl1.t(`reporting.district.summaryReportTitles.${this.args.product}.totals`)
            },
            studentCount: {
                value: averagesAndTotalsData1.student_count
            },
            totalTime: {
                component: 'basic-cells/duration-unit',
                durationConfig: durationConfig1,
                value: averagesAndTotalsData1.total_time
            },
            lessonsCompleted: {
                value: averagesAndTotalsData1.lessons_completed
            },
            averageTotalTimePerStudent: {
                value: intl1.t('reporting.district.summaryReportTitles.na')
            },
            averageLessonsCompletedPerStudent: {
                value: intl1.t('reporting.district.summaryReportTitles.na')
            },
            averageLexileGrowth: {
                value: intl1.t('reporting.district.summaryReportTitles.na')
            }
        };
    }
    /**
   * This returns an array of rowObjects to display in the footer of the table
   */ get footerRows() {
        const { dataKeys: dataKeys1, averageCellsForColumns: averageCellsForColumns1, totalCellsForColumns: totalCellsForColumns1 } = this;
        const averageCells1 = dataKeys1.map((dataKey1)=>{
            const cell1 = averageCellsForColumns1[dataKey1] ?? {};
            cell1.classNames = this.footerCellClasses(this.args.product, dataKey1);
            return cell1;
        });
        const averageRow1 = {
            cells: averageCells1
        };
        const totalsCells1 = dataKeys1.map((dataKey1)=>{
            const cell1 = totalCellsForColumns1[dataKey1] ?? {};
            cell1.classNames = this.footerCellClasses(this.args.product, dataKey1);
            return cell1;
        });
        const totalsRow1 = {
            cells: totalsCells1
        };
        return [
            averageRow1,
            totalsRow1
        ];
    }
    static{
        template(`
    <div class="__summary-tables__schools__5a8be">
      <BaseTableActions
        @items={{this.rows}}
        @columns={{this.columns}}
        @sortingConfig={{this.sortingConfig}}
        @onSortClick={{this.onSortClick}}
        as |Table|
      >
        <div class="scrollable-table-body overflow-x-auto">
          <Table exportable data-test-summary-table @footerRows={{this.footerRows}} as |tbl|>
            <tbl.head />
            <tbl.body />
            <tbl.foot class="print:border-t-2 print:border-black" />
          </Table>
        </div>
      </BaseTableActions>
      <div
        class="footer-message border-t bg-white p-2 font-bold
          {{if (eq @product 'maths') 'border-ms-green-100' 'border-oceany-blue-100'}}
          print:border-none"
      >
        {{t "reporting.district.summaryReportTitles.footerMessage"}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SummaryTableSchools;
