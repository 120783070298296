import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { FluencySprintsSchoolOverview } from 'district-ui-client/components/tables/fluency-sprints/school-overview/component';
import type ReportingMsFluencySprintsTableRoute from 'district-ui-client/routes/reporting/ui-scope/maths/fluency-sprints/table';
import { not } from 'ember-truth-helpers';
import type ReportingMsFluencySprintsTableController from 'district-ui-client/controllers/reporting/ui-scope/maths/fluency-sprints/table';
import RouteTemplate from 'ember-route-template';
import { isPresent } from '@ember/utils';
interface Signature {
    Args: {
        model: ModelFor<ReportingMsFluencySprintsTableRoute>;
        controller: ReportingMsFluencySprintsTableController;
    };
}
export class ReportingMsFluencySprintsTableRouteTemplate extends Component<Signature> {
    get isDataPresent() {
        return isPresent(this.args.model.mentalMinuteSchoolOverview);
    }
    static{
        template(`
    <DetailedPageWrapper @empty={{not this.isDataPresent}} @viewMode="table">

      <FluencySprintsSchoolOverview
        @schools={{@model.mentalMinuteSchoolOverview.schools}}
        @aggregate={{@model.mentalMinuteSchoolOverview.aggregate}}
        @onSortClick={{@controller.updateSorts}}
        @sortingConfig={{@controller.sortingConfig}}
      />
    </DetailedPageWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingMsFluencySprintsTableRouteTemplate);
