import { service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import type UiScopeRoute from 'district-ui-client/routes/reporting/ui-scope';
import type StudentEventsService from 'district-ui-client/services/student-events';
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope';
import type { NormalizedInterval } from 'date-fns';
import type { AwardsEarnedCertificatesByMonthData } from 'district-ui-client/components/charts/awards-earned/certificates/by-month';
interface StatsResponse {
    awardsEarnedTotals: Nullable<{
        awards_earned_totals: AwardsEarnedCertificatesByMonthData[];
    }>;
}
export default class MsAwardsEarnedRoute extends Route {
    @service
    studentEvents: StudentEventsService;
    async model() {
        const product: LegacyProductMs = 'maths';
        const { uiScope: scope, statsScope, period, scopedData } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>;
        const stats = await this.makeStatsRequest(product, statsScope, period);
        return {
            product,
            scope,
            period,
            scopedData,
            ...stats
        };
    }
    makeStatsRequest(product: string, scope: StatsScope, period: NormalizedInterval): Promise<StatsResponse> {
        const { studentEvents } = this;
        return RSVP.hash({
            awardsEarnedTotals: studentEvents.awardsEarnedTotals(product, scope, period)
        });
    }
}
