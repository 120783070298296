import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { SeriesBarOptions } from 'highcharts';
import blakeColors from '@blakeelearning/blake-colours/colours';
import SeriesBar from 'district-ui-client/components/primitives/series-bar/component';
import ChartLegend from 'district-ui-client/components/chart-legend';
type GradeLevelKey = 'below' | 'at' | 'above';
export interface WorkingAtGradeLevelData {
    working_at_grade_level: {
        below: number;
        at: number;
        above: number;
    };
}
interface Signature {
    Args: {
        data: WorkingAtGradeLevelData;
        product: Product;
        title?: string;
    };
}
const colorMap: Record<Product, Record<string, string>> = {
    maths: {
        below: blakeColors.msGreen150,
        at: blakeColors.msGreen300,
        above: blakeColors.msGreen400
    },
    re: {
        below: blakeColors.oceanyBlue100,
        at: blakeColors.oceanyBlue300,
        above: blakeColors.oceanyBlue400
    },
    rex: {
        below: blakeColors.oceanyBlue100,
        at: blakeColors.oceanyBlue300,
        above: blakeColors.oceanyBlue400
    }
};
type Product = 'maths' | 'rex' | 're';
export class WorkingAtGradeLevelDashboardChart extends Component<Signature> {
    get workingAtGradeLevelData() {
        return this.args.data.working_at_grade_level;
    }
    get chartData(): SeriesBarOptions[] {
        const { below: below1, at: at1, above: above1 } = this.workingAtGradeLevelData;
        const { below: belowColor1, at: atColor1, above: aboveColor1 } = this.colours;
        return [
            {
                name: 'Below',
                data: [
                    {
                        y: below1,
                        color: belowColor1
                    }
                ],
                type: 'bar'
            },
            {
                name: 'On',
                data: [
                    {
                        y: at1,
                        color: atColor1
                    }
                ],
                type: 'bar'
            },
            {
                name: 'Above',
                data: [
                    {
                        y: above1,
                        color: aboveColor1
                    }
                ],
                type: 'bar'
            }
        ];
    }
    get maxChartValue() {
        const { below: below1, at: at1, above: above1 } = this.workingAtGradeLevelData;
        return below1 + at1 + above1;
    }
    get yAxis() {
        const max1 = this.maxChartValue;
        return {
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 0,
            max: max1,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            reversedStacks: false
        };
    }
    get colours() {
        const { product: product1 } = this.args;
        return colorMap[product1];
    }
    get legendData() {
        const { colours: colours1 } = this;
        const studentNumbersByKey1 = this.workingAtGradeLevelData;
        const categories1: {
            key: GradeLevelKey;
            label: string;
        }[] = [
            {
                key: 'below',
                label: 'Below'
            },
            {
                key: 'at',
                label: 'On'
            },
            {
                key: 'above',
                label: 'Above'
            }
        ];
        const legendData1 = categories1.map((category1)=>{
            const { label: label1, key: key1 } = category1;
            const colour1 = colours1[key1];
            const data1 = Math.round(studentNumbersByKey1[key1]);
            return {
                label: label1,
                colour: colour1,
                data: data1
            };
        });
        return legendData1;
    }
    static{
        template(`
    <div class="flex h-full flex-col" data-test-working-at-grade-level>
      <SeriesBar
        @title={{@title}}
        @data={{this.chartData}}
        @xAxisVisible={{false}}
        @yAxisVisible={{false}}
        @yAxis={{this.yAxis}}
        @groupPadding={{0}}
        @barPadding={{0}}
        @chartMargin={{Array 60 10 160 10}}
        @stackingStyle="normal"
        @maxPointWidth={{120}}
        @plotBackgroundColour="transparent"
        @borderWidth={{1}}
      />
      <ChartLegend
        {{! 80px to match highcharts chartMargin top }}
        class="absolute inset-0 top-auto h-[80px] px-5"
        @data={{this.legendData}}
        @labelsBelow={{true}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default WorkingAtGradeLevelDashboardChart;
