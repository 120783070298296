import Model, { belongsTo, hasMany, attr } from '@ember-data/model'
import { isNone } from '@ember/utils'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type CleverDistrictMatch from 'district-ui-client/models/clever/clever-district-match'
import type Synchronisation from 'district-ui-client/models/clever/synchronisation'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CleverSchool = any

/**
 * Model class for clever data districts
 *
 * @class CleverDistrictModel
 * @property {string} name - Name of the school
 * @property {CleverSchoolModel} cleverSchools - HasMany relationship to all clever schools
 */
export class CleverDistrict extends Model {
  @attr('string') name!: string

  @hasMany('clever/clever-school', { async: false, inverse: 'cleverDistrict' })
  cleverSchools!: CleverSchool[]

  @belongsTo('clever/clever-district-match', { async: false, inverse: 'cleverDistrict' })
  cleverDistrictMatch!: CleverDistrictMatch

  // Included alongside clever-district if in middle of clever setup. See synchronisation model for more details.
  @belongsTo('clever/synchronisation', { async: false, inverse: null })
  synchronisation?: Synchronisation

  // True if clever data records are downloading, or user needs to do match & sync of schools/teachers/students
  // In other words, if a synchronisation record is present, one of the above is true.
  // Note this can be false between products (after reading is fully setup & before maths clever has been started)
  get isCleverUndergoingSetup() {
    return !isNone(this.synchronisation)
  }

  isCleverUndergoingSetupFor(subscriptionType: SubscriptionType) {
    return this.isCleverUndergoingSetup && this.synchronisation?.subscriptionType === subscriptionType
  }

  isCleverUndergoingSetupForOther(subscriptionType: SubscriptionType) {
    return this.isCleverUndergoingSetup && this.synchronisation?.subscriptionType !== subscriptionType
  }

  get isDataInitializing() {
    return this.synchronisation && this.isCleverUndergoingSetup && !this.synchronisation.isInitialized
  }

  get isDataInitialized() {
    return this.synchronisation && this.isCleverUndergoingSetup && this.synchronisation.isInitialized
  }
}

export default CleverDistrict
