import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import colors from '@blakeelearning/blake-colours/colours';
import type { IntlService } from 'ember-intl';
import type { ReportingProductSlug } from 'district-ui-client/domain/product';
import LeftSplitChart from 'district-ui-client/components/left-split-chart';
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component';
import type { LegendOptions } from 'highcharts';
import type { SeriesColumnOptions } from 'highcharts';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
/**
 * Puts together all the pieces for the assessment scores totals chart.
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'total_count', count: 120 },
 *     { category: 'student_count', count: 20 },
 *   ],
 *   months: [
 *     {
 *       count: 20,
 *       month: '2016-07',
 *     },
 *     {
 *       count: 100,
 *       month: '2016-08',
 *     },
 *   ],
 * ```
 */ export interface AssessmentScoresTotalsData {
    grade_position: number;
    summary: {
        category: string;
        count: number;
    }[];
    months: {
        count: number;
        month: string;
    }[];
}
interface Signature {
    Args: {
        data?: AssessmentScoresTotalsData;
        product: ReportingProductSlug;
        title?: string;
        legend?: LegendOptions;
        grade?: string;
        tooltip?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsAssessmentScoresTotals extends Component<Signature> {
    @service
    intl: IntlService;
    /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total assessments completed and student counts, and average lessons per student.
   */ get summaryStats() {
        const summaryData1 = this.args.data?.summary ?? [];
        const total1 = summaryData1.find((d1)=>d1.category === 'total_count')?.count ?? 0;
        const studentCount1 = summaryData1.find((d1)=>d1.category === 'student_count')?.count ?? 0;
        const average1 = basicAverage(total1, studentCount1);
        const [totalsLabel1, studentsLabel1, averageLabel1] = [
            'totals',
            'studentCount',
            'average'
        ].map((key1)=>{
            if (this.intl.exists(`reporting.summaryTitles.assessmentScoresTotals.${key1}`)) {
                return this.intl.t(`reporting.summaryTitles.assessmentScoresTotals.${key1}`);
            }
            return this.intl.t(`reporting.summaryTitles.assessmentScoresTotals.${key1}.${this.args.product}`);
        });
        return [
            {
                label: totalsLabel1,
                value: formatNumber(total1, 1)
            },
            {
                label: studentsLabel1,
                value: formatNumber(studentCount1, 1)
            },
            {
                label: averageLabel1,
                value: formatNumber(average1, 1)
            }
        ];
    }
    get title() {
        return this.args.title ?? 'Total assessments completed';
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get columns(): SeriesColumnOptions[] {
        const monthData1 = this.args.data?.months ?? [];
        return [
            {
                name: 'count',
                data: monthData1.map((m1)=>m1.count),
                color: colors.juicyOrange300,
                type: 'column'
            }
        ];
    }
    /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get categories() {
        const monthData1 = this.args.data?.months ?? [];
        return monthData1.map((m1)=>convertDateFormat(m1.month));
    }
    static{
        template(`
    <div data-test-assessment-scores-totals class="relative" ...attributes>
      <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{SummaryBoxColorScheme.Orange}}
          />
        </:left>
        <:right>
          <SeriesColumn
            @title={{this.title}}
            @data={{this.columns}}
            @categories={{this.categories}}
            @stackingStyle="normal"
            @legend={{@legend}}
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsAssessmentScoresTotals;
