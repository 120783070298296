import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import { reportingLegend as legend } from 'district-ui-client/utils/giraffe/highcharts-config';
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import type { IntlService } from 'ember-intl';
import type Features from 'district-ui-client/services/features';
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component';
import { StatBox, type themes } from 'district-ui-client/components/stat-box';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import type { SeriesColumnOptions } from 'highcharts';
import { isPresent } from '@ember/utils';
import type { LegendOptions } from 'highcharts';
import blakeColors from '@blakeelearning/blake-colours/colours';
import type { UIScopeName } from 'district-ui-client/domain/ui-scope';
const colorMap: Record<CamelizedProduct, Record<string, string>> = {
    fastPhonics: {
        lessons: blakeColors.oceanyBlue300,
        quizzes: blakeColors.juicyOrange300,
        words: blakeColors.stormBlue300,
        sounds: blakeColors.purplyPink300,
        books: blakeColors.grapeyGreen300
    },
    maths: {
        lessons: blakeColors.forestGreen300,
        assessments: blakeColors.juicyOrange300,
        drivingTests: blakeColors.oceanyBlue300,
        quizzes: blakeColors.grapeyGreen300,
        mentalMinute: blakeColors.midOrange300
    },
    re: {
        lessons: blakeColors.oceanyBlue300,
        assessments: blakeColors.juicyOrange300,
        drivingTests: blakeColors.stormBlue300,
        books: blakeColors.purplyPink300,
        spelling: blakeColors.grapeyGreen300
    },
    rex: {
        lessons: blakeColors.oceanyBlue300,
        quizzes: blakeColors.stormBlue300,
        assessments: blakeColors.juicyOrange300,
        books: blakeColors.purplyPink300,
        spelling: blakeColors.grapeyGreen300
    }
};
type CamelizedProduct = 'fastPhonics' | 'maths' | 're' | 'rex';
export function isCamelizedProduct(maybeProduct1: unknown): maybeProduct is CamelizedProduct {
    const camelizedProducts1: CamelizedProduct[] = [
        'fastPhonics',
        'maths',
        're',
        'rex'
    ];
    return camelizedProducts1.includes(maybeProduct1 as CamelizedProduct);
}
export interface Box {
    category: string;
    tooltip?: string;
    theme?: keyof typeof themes;
}
interface Month {
    month: string;
    count: number;
}
interface MonthUsage {
    count: number;
    category: string;
    months: Month[];
}
interface TimeSummary {
    category: string;
    event_count: number;
    student_count: number;
}
export interface TotalUsageOverTimeData {
    total_usage_over_time_summary: TimeSummary[];
    total_usage_over_time_by_month: MonthUsage[];
}
interface ChartArgs {
    data: TotalUsageOverTimeData;
    boxes: Box[];
    product: string;
    tileClickAction?: (category: string) => void;
    uiScope: UIScopeName;
}
interface Stats {
    title: string;
    category: string;
    stats: {
        name: string;
        value: string;
    }[][];
    noClickAction?: boolean;
    tooltip?: string;
    theme?: keyof typeof themes;
}
interface Signature {
    Args: ChartArgs;
    Element: HTMLDivElement;
}
export class GiraffeChartsTotalUsageOverTime extends Component<Signature> {
    @service
    intl: IntlService;
    @service
    features: Features;
    get legend(): LegendOptions {
        return legend('left', 'top', 40, 10);
    }
    tileClickAction = (statBox1: Stats, category1: string)=>{
        if (statBox1.noClickAction) return;
        this.args.tileClickAction?.(category1);
    };
    get summaryData() {
        return this.args.data.total_usage_over_time_summary;
    }
    get monthData() {
        return this.args.data.total_usage_over_time_by_month;
    }
    // This preserves the order that the stat boxes should be displayed in
    get categories() {
        return this.args.boxes.map((item1)=>item1.category);
    }
    /**
   * Converts the category array an object like { 'lessons': { tooltip: '..', abc: .. }, 'some-other-category':, { .. } }
   * For easier access to category properties
   */ get keyedBoxes(): Record<string, Box> {
        return this.args.boxes.reduce((keyed1, box1)=>{
            return {
                ...keyed1,
                [box1.category]: box1
            };
        }, {});
    }
    // Returns an array of stat box objects, each with the data required for a stat box, in the intended display order.
    get statBoxes(): Stats[] {
        const titlePrefix1 = 'reporting.totalUsageOverTime.titles';
        const statPrefix1 = 'reporting.statBoxes.totalUsageOverTime';
        return this.categories.map((category1)=>{
            const categorySummaryData1 = this.summaryData.find((summary1)=>summary1.category === category1);
            if (!categorySummaryData1) return null;
            const { event_count: event_count1, student_count: student_count1 } = categorySummaryData1;
            const average1 = basicAverage(event_count1, student_count1);
            const camelizedCategory1 = camelize(category1);
            const camelizedProduct1 = camelize(this.args.product);
            let title1 = this.intl.t(`${titlePrefix1}.${camelizedCategory1}.${camelizedProduct1}`);
            if (this.intl.exists(`${titlePrefix1}.${camelizedCategory1}`)) {
                title1 = this.intl.t(`${titlePrefix1}.${camelizedCategory1}`);
            }
            const scopeSiteCopyKey1 = category1 === 'mental_minute' ? 'sprints' : camelize(this.args.uiScope);
            const firstStatLabel1 = this.intl.t(`${statPrefix1}.${scopeSiteCopyKey1}`);
            const secondStatLabel1 = this.intl.t(`${statPrefix1}.perStudent`);
            return {
                title: title1,
                stats: [
                    [
                        {
                            name: firstStatLabel1,
                            value: formatNumber(event_count1, 1) as number | string
                        },
                        {
                            name: secondStatLabel1,
                            value: formatNumber(average1, 1) as number | string
                        }
                    ]
                ],
                ...this.keyedBoxes[category1]
            };
        }).filter((sb1): sb is Stats =>sb1 !== null);
    }
    // Not ideal. But we can't join class strings like `xl:w-1/{{statBoxes.length}}` or the class will not be included.
    // We probably want to come up with a better solution for XL screen sizes though
    get statBoxesXlWidthClass() {
        switch(this.statBoxes.length){
            case 4:
                return 'xl:w-1/4';
            case 5:
                return 'xl:w-1/5';
            case 6:
                return 'xl:w-1/6';
            default:
                return '';
        }
    }
    // These will be the chart X axis categories, use the months given in the first data item.
    get chartMonths() {
        const monthData1 = this.monthData[0].months;
        return monthData1.map(({ month: month1 })=>convertDateFormat(month1, 'yyyy-MM', 'MMMM'));
    }
    // Format and sort data for the series column chart
    get chartData(): SeriesColumnOptions[] {
        const { categories: categories1, monthData: monthData1 } = this;
        const legendPrefix1 = 'reporting.totalUsageOverTime.legends';
        return categories1.map((category1)=>{
            if (category1 === 'quizzes' && !this.features.isEnabled('tuiReadingSkillsMasteredFF')) return null;
            const dataItem1 = monthData1.find((item1)=>item1.category === category1);
            if (!dataItem1) return null;
            const byMonth1 = dataItem1.months.map((item1)=>item1.count);
            const camelizedCategory1 = camelize(category1);
            const camelizedProduct1 = camelize(this.args.product);
            if (!isCamelizedProduct(camelizedProduct1)) return;
            let name1 = this.intl.t(`${legendPrefix1}.${camelizedCategory1}.${camelizedProduct1}`);
            if (this.intl.exists(`${legendPrefix1}.${camelizedCategory1}`)) {
                name1 = this.intl.t(`${legendPrefix1}.${camelizedCategory1}`);
            }
            const color1 = colorMap[camelizedProduct1][camelizedCategory1];
            return {
                name: name1,
                category: category1,
                data: byMonth1,
                color: color1,
                type: 'column'
            } as const;
        }).filter(isPresent);
    }
    static{
        template(`
    <div data-test-total-usage-over-time class="flex h-full flex-col" ...attributes>
      <div class="flex flex-wrap justify-center" data-test-stat-box-container>
        {{#each this.statBoxes as |statBox|}}
          <StatBox
            data-test-stat-box={{statBox.category}}
            class="w-1/3 min-w-[200px] {{this.statBoxesXlWidthClass}} border-b border-r border-white"
            @title={{statBox.title}}
            @tooltipText={{statBox.tooltip}}
            @theme={{statBox.theme}}
            @stats={{statBox.stats}}
            role={{unless statBox.noClickAction "button"}}
            {{on "click" (fn this.tileClickAction statBox statBox.category)}}
            {{! This lint rule doesn't work too nicely with optional interactions }}
            {{! https://github.com/ember-template-lint/ember-template-lint/issues/274 }}
            {{! template-lint-disable no-invalid-interactive }}
          />
        {{/each}}
      </div>

      <total-usage-chart class="relative block basis-96" data-test-total-usage-chart>
        <SeriesColumn
          @data={{this.chartData}}
          @categories={{this.chartMonths}}
          @stackingStyle="normal"
          @legend={{this.legend}}
          @yAxisReversedStacks={{false}}
        />
      </total-usage-chart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default GiraffeChartsTotalUsageOverTime;
