import Component from '@glimmer/component'
import { service } from '@ember/service'
import type ActiveRouteService from 'district-ui-client/services/active-route'

interface Signature {
  Args: {
    isShowingModal?: boolean
    onClose: () => void
    size?: string
    type?: string
    title?: string
  }
  Blocks: {
    default: []
  }
}

export default class Modal extends Component<Signature> {
  @service activeRoute!: ActiveRouteService
}
