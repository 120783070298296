import { template } from "@ember/template-compiler";
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { or } from 'ember-truth-helpers';
import { not } from 'ember-truth-helpers';
import { TimeOnTaskUsageChart } from 'district-ui-client/components/charts/time-on-task/usage/component';
import { TimeOnTaskTable } from 'district-ui-client/components/reporting/time-on-task/table';
import Component from '@glimmer/component';
import type ReTimeOnTaskRoute from 'district-ui-client/routes/reporting/ui-scope/re/time-on-task';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<ReTimeOnTaskRoute>;
    };
}
export class ReportingReTimeOnTaskRouteTemplate extends Component<Signature> {
    get isDistrictScope() {
        return this.args.model.scope.scope === 'district';
    }
    get timeOnTaskUsage() {
        const { timeOnTaskUsage: timeOnTaskUsage1 } = this.args.model;
        if (!timeOnTaskUsage1) return;
        return {
            summary: timeOnTaskUsage1.time_on_task_summary,
            months: timeOnTaskUsage1.time_on_task_by_month
        };
    }
    static{
        template(`
    <DetailedPageWrapper
      @empty={{or (not @model.timeOnTaskUsage) (not @model.timeOnTaskHistory)}}
      @viewMode={{if this.isDistrictScope "table" "chart"}}
    >
      <div class="print:border-dusty-black-50 mb-5 rounded-md bg-white p-2 print:rounded-none print:border">
        <TimeOnTaskUsageChart
          data-test-time-on-task-usage-chart
          @data={{this.timeOnTaskUsage}}
          @uiScope={{@model.scope}}
          @mainTheme="blue"
        />
      </div>

      {{#if this.isDistrictScope}}
        {{#if @model.timeOnTaskHistory}}
          <TimeOnTaskTable @schools={{@model.scopedData.schools}} @data={{@model.timeOnTaskHistory}} />
        {{/if}}
      {{/if}}
    </DetailedPageWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingReTimeOnTaskRouteTemplate);
