import Route from '@ember/routing/route'

export default class SubscriptionTypeErrorRoute extends Route {
  // model hooks not called for a substate

  // keep it simple
  setupController(controller, model) {
    const { subscriptionType } = this.modelFor('subscription-type')
    controller.subscriptionType = subscriptionType
    super.setupController(controller, model)
  }
}
