import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { service } from '@ember/service'

export default class extends Component {
  @service contentfulClient

  @tracked helpGuideUrls = null

  @tracked isShowingHelpModal = false

  get subscriptionType() {
    return this.args.subscriptionType
  }

  get helpGuideUrl() {
    return this.helpGuideUrls?.[this.subscriptionType] || ''
  }

  @action
  async showHelpModal(value) {
    this.helpGuideUrls ??= await this.contentfulClient.getCleverGuidePdfs()
    this.isShowingHelpModal = value
  }
}
