import BlakeColours from '@blakeelearning/blake-colours/colours'
import { formatNumber } from './formatter'
import type {
  AlignValue,
  AxisPlotLinesOptions,
  LegendOptions,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
  VerticalAlignValue,
} from 'highcharts'

/**
 * Provides several functions for highcharts config.
 * offer highcharts data structure for typical
 * reporting labels, legends, plotlines, and tooltips.
 * Includes customisation options
 *
 * @module HighchartsConfig
 */

/**
 * Generate a HighChartsTitle config object for reporting.
 *
 * This function might seem pointless but it gives a place where we can adjust title options for all charts.
 * Currently used for both chart titles and axis titles. Though available options differ between them, so we may want
 * to refactor to add a new reportingAxisTitle() function for axis titles.
 *
 * @memberOf HighchartsConfig
 * @param  {String} [text] - The string of title text
 * @param {Object} [options] - Options for the title
 * @return {Object} Highcharts title configuration object
 */
export function reportingTitle(text: string, options = {}) {
  return { text, ...options }
}

/**
 * Provides a highcharts data structure for a typical reporting legend.
 *
 * @memberOf HighchartsConfig
 * @param  {String} [horizontal='left'] - The horizontal alignment of the legend
 * @param  {String} [vertical='top'] - The vertical alignment of the legend
 * @param  {Number} [xOffset=50] - The xOffset of the legend. You'll typically
 *                                 change this if you're overlapping a label.
 * @param  {Number} [yOffset=0] - The yOffset of the legend. You'll typically
 *                                 change this if you've overlapping a label.
 * @param {Boolean} [options.enabled=true] - Is legend enabled
 * @param {Number} [options.symbolHeight=11] - Height of symbol
 * @param {Number} [options.symbolWidth=11] - Width of symbol
 * @param {Number} [options.symbolRadius=6] - Radius of symbol
 * @return {HighChartsLegend}
 */
export function reportingLegend(
  horizontal: AlignValue = 'left',
  vertical: VerticalAlignValue = 'top',
  xOffset = 50,
  yOffset = 0,
  { enabled = true, symbolHeight = 11, symbolWidth = 11, symbolRadius = 6 }: LegendOptions = {},
): LegendOptions {
  const config = {
    enabled,
    align: horizontal,
    verticalAlign: vertical,
    x: xOffset,
    y: yOffset,
    symbolHeight,
    symbolWidth,
    symbolRadius,
  }
  return config
}

/**
 * Provides a highcharts data structure for a typical chart plot line. The line
 * is drawn at the position indicated by `value` and labelled by `label`. The
 * line and label may be styled by passing in an `options` object.
 *
 * @memberOf HighchartsConfig
 * @param {Number} value - The value on the chart to draw the plot line at.
 * @param {String} label - A string to label the plot line.
 * @param {Object} [options] - The options object to override display properties of the plot line.
 * @param {String} [options.colour = '#aaa'] - CSS colour value to set the colour of the line and label.
 * @param {String} [options.fontSize = '80%'] - CSS font-size value to set the size of the label.
 * @param {String} [options.icon = '&#9650'] - HTML string to render an icon on the left of the string label.
 * @param {Number} [options.width = 1] - Width to draw the plot line.
 * @param {String} [options.align = 'right'] - Direction to align the plot line label.
 * @param {String} [options.textAlign = 'left'] - Direction to align the plot line label.
 * @param {Number} [options.x = 10] - Position the plot line label in x.
 * @param {Number} [options.x = 0] - Position the plot line label in y.
 *
 * @return {HighChartsPlotLine} Highcharts plot line configuration object.
 */

interface ReportingPlotLineOptions {
  colour?: string
  labelColour?: string
  fontSize?: string
  icon?: string
  width?: number
  align?: AlignValue
  textAlign?: AlignValue
  y?: number
  x?: number
  rotation?: number
  zIndex?: number
}

export function reportingPlotLine(
  value: number,
  label: string,
  {
    colour: color = BlakeColours.dustyBlack200,
    labelColour: labelColor,
    fontSize = '80%',
    icon = '▲',
    width = 1,
    align = 'right',
    textAlign = 'left',
    x = 10,
    y = 0,
    rotation,
    zIndex = 0,
  }: ReportingPlotLineOptions = {},
): AxisPlotLinesOptions {
  const text = `${icon} ${label}`
  const config = {
    color,
    width,
    value,
    zIndex,
    label: {
      /* A highcharts bug;
       * a specific config of align & textAlign causes the plotline label to not render, unless useHTML is true..
       * https://github.com/highcharts/highcharts/issues/17888
       */
      useHTML: true,
      align,
      textAlign,
      rotation,
      style: {
        color: labelColor || color,
        fontSize,
      },
      x,
      y,
      text,
    },
  }
  return config
}

/**
 * Provides a highcharts data structure for a typical chart tooltip. The tooltip
 * will display the value of the `value` property at the current point, folowed
 * by the `unit`. The appearance may be adjusted by passing in an `options` object.
 *
 * @memberOf HighchartsConfig
 * @param {String} value - The value on the chart point to display in the tooltip.
 *                         See: http://api.highcharts.com/highcharts/tooltip.formatter
 * @param {String} [unit=''] - A string to label the tooltip value.
 * @param {Object} [options={}] - The options object to override display properties of the tooltip.
 * @param {Boolean} [options.enabled=true] - Should the tooltip be enabled?
 * @param {String} [options.colour='#fff'] - CSS colour value to set the colour of the tooltip.
 * @param {String} [options.backgroundColor='#333'] - CSS colour value to set the background-colour
 *                                                      of the tooltip.
 * @param {Number} [options.borderWidth=0] - Width to draw the tooltip border.
 * @param {Number} [options.borderRadius=0] - Size to draw the tooltip border-radius.
 * @param {Boolean} [options.shadow=false] - Should the tooltip have a shadow?
 * @param {Function} [options.formatter=default] - Highcharts formatter function to control the
 *                                                   content and display of the tooltip.
 *
 * @return {HighChartsToolTip} Highcharts tooltip configuration object.
 */

interface TooltipOptions {
  enabled?: boolean
  color?: string
  backgroundColor?: string
  borderWidth?: number
  borderRadius?: number
  shadow?: boolean
  formatter?: TooltipFormatterCallbackFunction
}

export function reportingToolTip(
  value: 'x' | 'y',
  unit = '',
  {
    enabled = true,
    color = BlakeColours.white,
    backgroundColor = BlakeColours.dustyBlack400,
    borderWidth = 0,
    borderRadius = 5,
    shadow = false,
    formatter = undefined,
  }: TooltipOptions = {},
) {
  // Set here so we can use the 'value' and 'unit' params
  if (typeof formatter !== 'function') {
    formatter = function (this: TooltipFormatterContextObject) {
      const formattedValue = formatNumber(this[value], 2)
      return `<b>${formattedValue}${unit || ''}</b>`
    }
  }

  return {
    enabled,
    backgroundColor,
    borderWidth,
    borderRadius,
    shadow,
    formatter,
    style: {
      color,
    },
  }
}
