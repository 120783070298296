import Component from '@glimmer/component'
import { service } from '@ember/service'
import {
  isSchoolInitDone,
  isSchoolMatchDone,
  areTeacherMatchesDone,
  areStudentMatchesDone,
} from 'district-ui-client/utils/clever/clever-school/sync-process-states'

/**
 * This component renders a bar with 3 elements.
 * The current route defines which of the steps becomes active.
 * The current sync state determines which of the steps are done or disabled.
 *
 * @class WorkflowBarComponent
 */
export default class CleverWorkflowBar extends Component {
  @service intl

  /**
   * Used to determine what match steps are available
   *
   * @property currentSyncState
   * @type string
   */
  get currentSyncState() {
    return this.args.syncProcessState || null
  }

  /**
   * Computes the steps and their states to be rendered
   *
   * @return {Array}
   */
  get steps() {
    const { intl, currentSyncState } = this

    const initDone = isSchoolInitDone(currentSyncState)
    const schoolDone = isSchoolMatchDone(currentSyncState)
    const teachersDone = areTeacherMatchesDone(currentSyncState)
    const studentsDone = areStudentMatchesDone(currentSyncState)

    return [
      {
        stepId: 'school',
        title: intl.t('clever.workflowBarSchoolTitle'),
        route: 'clever.match.schools.index',
        done: schoolDone,
        disabled: !initDone,
      },
      {
        stepId: 'teachers',
        title: intl.t('clever.workflowBarTeachersTitle'),
        route: 'clever.match.schools.teachers',
        done: teachersDone,
        disabled: !schoolDone,
      },
      {
        stepId: 'students',
        title: intl.t('clever.workflowBarStudentsTitle'),
        route: 'clever.match.schools.students',
        done: studentsDone,
        disabled: !teachersDone,
      },
    ]
  }
}
