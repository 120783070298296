/**
 * This file is for clever utils regarding the synchronisation at a school level.
 *
 * The main two concepts to be aware of regarding these Clever utils are;
 *
 * Sync steps
 * - the step in the overall synchronisation procedure for a clever school item. It indicates where in the
 * synchronisation process the school is.
 *
 * Match states
 * - a match state indicates, for any one data type (school, teachers or students), whether it is yet to be matched, is
 * in the process of matching, or matching is completed.
 */

/**
 * Steps for the clever synchronisation process, and their meanings. These are the overall/higher level steps.
 *
 * init: initial step. A school will be on this step while downloading clever data, (first load or after being reset)
 * matching: { disco can't do anything here } schools are being automatched by the system
 * unmatched: awaiting matching of school
 * unconfirmed_match: awaiting confirmation of school match
 * matching_teachers: { disco can't do anything here } teacher are being automatched by the system
 * matching_students: { disco can't do anything here } students are being automatched by the system
 * awaiting_teacher_match: if any unmatched teachers, awaiting matching
 * awaiting_student_match: if any unmatched students, awaiting matching
 * awaiting_sync: if all schools, teachers and students have been matched for this school, awaiting sync initialization
 * complete: all synchronisation is complete for the school
 */
const INIT = 'init'
const UNMATCHED_SCHOOL = 'unmatched'
const MATCHING_SCHOOL = 'matching'
const UNCONFIRMED_SCHOOL_MATCH = 'unconfirmed_match'
const AWAITING_TEACHER_MATCH = 'awaiting_teacher_match'
const DOWNLOADING_TEACHERS = 'downloading_teachers'
const MATCHING_TEACHERS = 'matching_teachers'
const AWAITING_STUDENT_MATCH = 'awaiting_student_match'
const DOWNLOADING_STUDENTS = 'downloading_students'
const MATCHING_STUDENTS = 'matching_students'
const DOWNLOADING_SECTIONS = 'downloading_sections'
const AWAITING_SYNC = 'awaiting_sync'
const SYNC_IN_PROGRESS = 'syncing'
const COMPLETE = 'complete'
export const SYNC_STEPS = {
  INIT,
  UNMATCHED_SCHOOL,
  MATCHING_SCHOOL,
  DOWNLOADING_TEACHERS,
  DOWNLOADING_STUDENTS,
  DOWNLOADING_SECTIONS,
  UNCONFIRMED_SCHOOL_MATCH,
  AWAITING_TEACHER_MATCH,
  MATCHING_TEACHERS,
  AWAITING_STUDENT_MATCH,
  MATCHING_STUDENTS,
  AWAITING_SYNC,
  SYNC_IN_PROGRESS,
  COMPLETE,
}

/**
 * The state of any one match process, (where a 'match process' is the process to match one of school, teachers or students).
 * For example, when matching teachers, the state of that matching process could be tbd, in_prog, done, ...
 */
const TBD = 'tbd'
const IN_PROGRESS = 'in_prog'
const DONE = 'done'
const CONFIRM = 'alert:match_confirm'
export const MATCH_STATES = {
  TBD,
  IN_PROGRESS,
  DONE,
  CONFIRM,
}

/**
 * This object ties together the higher level sync steps, and the match state of each match process at that given step.
 * [SYNC_STEP]: { [MATCH_TYPE]: [MATCH_STATE], ... }
 */
// prettier-ignore
export const syncProcessStateObject = {
  [INIT]:                     { school: IN_PROGRESS,  teachers: TBD,          students: TBD },
  [UNMATCHED_SCHOOL]:         { school: TBD,          teachers: TBD,          students: TBD },
  [MATCHING_SCHOOL]:          { school: IN_PROGRESS,  teachers: TBD,          students: TBD },
  [DOWNLOADING_TEACHERS]:     { school: IN_PROGRESS,  teachers: TBD,          students: TBD },
  [DOWNLOADING_STUDENTS]:     { school: IN_PROGRESS,  teachers: TBD,          students: TBD },
  [DOWNLOADING_SECTIONS]:     { school: IN_PROGRESS,  teachers: TBD,          students: TBD },
  [UNCONFIRMED_SCHOOL_MATCH]: { school: CONFIRM,      teachers: TBD,          students: TBD },
  [AWAITING_TEACHER_MATCH]:   { school: DONE,         teachers: TBD,          students: TBD },
  [MATCHING_TEACHERS]:        { school: DONE,         teachers: IN_PROGRESS,  students: TBD },
  [AWAITING_STUDENT_MATCH]:   { school: DONE,         teachers: DONE,         students: TBD },
  [MATCHING_STUDENTS]:        { school: DONE,         teachers: DONE,         students: IN_PROGRESS },
  [AWAITING_SYNC]:            { school: DONE,         teachers: DONE,         students: DONE },
  [SYNC_IN_PROGRESS]:         { school: DONE,         teachers: DONE,         students: DONE },
  [COMPLETE]:                 { school: DONE,         teachers: DONE,         students: DONE },
}

/**
 * Validates against the available sync steps
 * @param {string} syncStep
 * @returns {bool}
 */
export const validateSyncStep = function (syncStep) {
  return Object.values(SYNC_STEPS).includes(syncStep)
}

export const schoolMatchStateForStep = function (syncStep) {
  return validateSyncStep(syncStep) ? syncProcessStateObject[syncStep].school : MATCH_STATES.TBD
}

export const teacherMatchStateForStep = function (syncStep) {
  return validateSyncStep(syncStep) ? syncProcessStateObject[syncStep].teachers : MATCH_STATES.TBD
}

export const studentMatchStateForStep = function (syncStep) {
  return validateSyncStep(syncStep) ? syncProcessStateObject[syncStep].students : MATCH_STATES.TBD
}

export const isSchoolInitDone = function (syncStep) {
  return validateSyncStep(syncStep) && syncStep !== SYNC_STEPS.INIT
}

export const isSchoolMatchDone = function (syncStep) {
  return schoolMatchStateForStep(syncStep) === MATCH_STATES.DONE
}

export const areTeacherMatchesDone = function (syncStep) {
  return teacherMatchStateForStep(syncStep) === MATCH_STATES.DONE
}

export const areStudentMatchesDone = function (syncStep) {
  return studentMatchStateForStep(syncStep) === MATCH_STATES.DONE
}

/**
 * Returns true if the given step has a match state that indicates processing / in progress
 *
 * @param syncStep
 * @returns {boolean}
 */
export const hasProcessingState = function (syncStep) {
  const matchStates = validateSyncStep(syncStep) ? Object.values(syncProcessStateObject[syncStep]) : []
  return matchStates.includes(MATCH_STATES.IN_PROGRESS)
}
