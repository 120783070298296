import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { Tooltip } from 'district-ui-client/components/tooltip';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        isOpen: boolean;
        onClose: () => void;
    };
    Blocks: {
        default: [];
    };
}
export const FloatingDrawer: TOC<Signature> = template(`
  <div
    class="{{if @isOpen 'opacity-100' 'invisible opacity-0'}}
      flex h-full w-full transition-opacity duration-300 print:hidden"
    ...attributes
  >
    <aside class="border-r-neutral-75 relative h-full w-[270px] shrink-0 overflow-hidden border-r-2 bg-white">
      <CloseButton class="absolute right-0 top-10 print:hidden" {{on "click" @onClose}} />
      <div class="h-full px-6 pt-10">
        {{yield}}
      </div>
    </aside>
    <button type="button" class="h-full w-full bg-[#030E1F] bg-opacity-50" {{on "click" @onClose}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const CloseButton: TOC<{
    Element: HTMLButtonElement;
}> = template(`
  <button
    type="button"
    class="bg-neutral-75 flex items-center justify-center rounded-l-lg py-2.5 pl-0.5 pr-0.5"
    ...attributes
  >
    <FaIcon @icon="xmark" class="text-neutral-250 h-3 w-3 p-1" />
    <Tooltip @text={{t "close"}} />
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
