import type { Options } from 'highcharts'
import { Chart } from 'highcharts'
import Modifier from 'ember-modifier'
import { tracked } from '@glimmer/tracking'
import { assert } from '@ember/debug'
import { registerDestructor } from '@ember/destroyable'

interface Signature {
  Args: {
    Named: {
      callback?: (chart: Chart) => void
      options?: Options
    }
    Positional: unknown[]
  }
  Element: HTMLElement
}

function createOptions(named: Signature['Args']['Named']): Options {
  const disabled = { enabled: false }

  const defaultOptions: Options = {
    credits: disabled,
  }

  if (typeof named.options !== 'undefined') {
    return {
      ...defaultOptions,
      ...named.options,
    }
  }

  return defaultOptions
}

function cleanup(instance: HighchartsModifier) {
  instance.chart?.destroy()
}

/**
 * Modifier to manage the rendering and updating of Highcharts charts
 *
 * @example
 * ```hbs
 * <div {{highcharts callback=this.callback options=this.options}}></div>
 * ```
 */
export default class HighchartsModifier extends Modifier<Signature> {
  @tracked chart?: Chart

  /**
   * Merges default options with args.named.options
   */
  options?: Options

  didSetup = false

  modify(
    element: Signature['Element'],
    _positional: Signature['Args']['Positional'],
    named: Signature['Args']['Named'],
  ): void {
    assert(
      'highcharts can only be installed on HTML elements',
      element instanceof HTMLElement,
    )

    this.options = createOptions(named)

    if (!this.didSetup) {
      this.chart = new Chart(element, this.options, named.callback)
      registerDestructor(this, cleanup)
      this.didSetup = true
    } else if (this.chart) {
      this.chart.update(this.options, true, true)
    }
  }
}
