import type Store from '@ember-data/store'
import Route from '@ember/routing/route'
import type Transition from '@ember/routing/transition'
import { service } from '@ember/service'
import type ManageTeacherEditController from 'district-ui-client/controllers/subscription-type/manage/teachers/edit'

export default class ManageTeacherEditRoute extends Route {
  @service store!: Store

  model({ id }: { id: string }) {
    return this.store.findRecord('teacher', id)
  }

  resetController(controller: ManageTeacherEditController, isExiting: boolean, transition: Transition) {
    super.resetController(controller, isExiting, transition)
    if (isExiting && controller.model?.hasDirtyAttributes) controller.model.rollbackAttributes()
  }
}
