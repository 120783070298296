import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { service } from '@ember/service'
import { isEmpty } from '@ember/utils'
import { trackedFunction } from 'reactiveweb/function'

export default class IndexController extends Controller {
  @service session

  @service alert

  @service teacherOperations

  @service intl

  @service log

  @service activeRoute

  @service authToken

  @service store

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get groupActionsDisabled() {
    return isEmpty(this.selectedIds) || this.isCleverDisabled
  }

  @tracked selectedIds = []

  @tracked showChangeSchoolModal = false

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  @action
  setChangeSchoolModal(value) {
    this.showChangeSchoolModal = value
  }

  @action
  updateSelectedIds(selectedIds) {
    this.selectedIds = selectedIds
  }

  @action
  async changeSchool(school) {
    const teachers = this.selectedTeachers

    const response = await this.teacherOperations.moveTeachersToSchool(teachers, school, this.subscriptionType)

    if (response.ok) {
      this.uncheckSelectedItems()
      this.alert.show({
        message: `Successfully moved ${teachers.length} teachers`,
      })
    } else {
      this.alert.show({
        type: 'critical',
        message: 'Failed to move teachers',
      })
    }

    this.setChangeSchoolModal(false)
  }

  uncheckSelectedItems() {
    this.selectedIds = []
  }

  get teachers() {
    return this.model.teachersForSubType.filter((t) => !t.isDeleted)
  }

  get selectedTeachers() {
    return this.model.teachersForSubType.filter((t) => this.selectedIds.includes(t.id))
  }

  fetchSchools = trackedFunction(this, async () => {
    const { subscriptionType } = this
    return (
      await this.store.query('school', {
        scope: `districts/${this.session.currentDistrict.id}`,
        filter: { 'subscription-type': subscriptionType }, // has relevant sub (could be expired/active/pending)
        include: 'school-subscriptions',
      })
    )
      .slice()
      .filter((school) => school.hasActiveSchoolSubscriptionFor(subscriptionType))
  })

  @action
  async deleteTeachers() {
    const teachers = this.selectedTeachers
    if (teachers.length > 0) {
      const confirmMessage = `Are you sure you wish to delete ${teachers.length} teacher(s)`
      const confirmResponse = window.confirm(confirmMessage)
      if (!confirmResponse) return

      const successMessage = `Successfully deleted ${teachers.length} teachers`

      try {
        const promises = teachers.map((teacher) => teacher.destroyRecord({ adapterOptions: { bulk: true } }))
        await Promise.all(promises)

        this.alert.showWithDismiss({ message: successMessage })
        this.uncheckSelectedItems()
      } catch (e) {
        teachers.forEach((teacher) => teacher.rollbackAttributes())

        this.log.error('failed to delete teachers', e)
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.deleteTeachers'),
        })
      }
    }
  }

  @action
  async passwordReset() {
    const teachers = this.selectedTeachers
    if (teachers.length > 0) {
      const response = await this.teacherOperations.passwordReset(teachers)
      if (response.ok) {
        this.uncheckSelectedItems()
        this.alert.showWithDismiss({ message: this.intl.t('subscriptionType.manageTeachers.passwordResetSuccess') })
      } else {
        this.log.error('failed to request password reset', await response.text())
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.passwordReset'),
        })
      }
    }
  }

  @action
  async exportTeachers() {
    const response = await this.teacherOperations.exportTeachers(this.subscriptionType)
    if (response.ok) {
      this.alert.showWithDismiss({
        message: this.intl.t('subscriptionType.manageTeachers.exportTeachers.exportCsvSuccess'),
      })
    } else {
      this.log.error('failed to export teachers', await response.text())
      this.alert.show({
        type: 'critical',
        message: this.intl.t('subscriptionType.manageTeachers.exportTeachers.exportCsvError'),
      })
    }
  }
}
