import Model, { hasMany, belongsTo, attr } from '@ember-data/model'
import { get } from '@ember/object'
import { dependentKeyCompat } from '@ember/object/compat'

/**
 * Model class for teachers (gravity data)
 *
 * @class BlakeTeacherModel
 * @property {string} firstName - first name of the teacher
 * @property {string} lastName - last name of the teacher
 * @property {CleverTeacherMatchModel} cleverTeacherMatch - HasOne relationship to access the sisId
 * @property {string} sisId - An alias to the sisId from the blakeCleverTeacherMatch relationship
 * @property {string} fullName - Name like 'Klaus Dieter' or '-'
 */
export default class extends Model {
  @attr('string') firstName

  @attr('string') lastName

  @attr('string') email

  @attr('string') login

  @attr('string') accountType // used when creating blake teachers for matching

  @belongsTo('clever/clever-teacher-match', { async: false, inverse: 'teacher' })
  cleverTeacherMatch

  @hasMany('clever/student', { async: false, inverse: null })
  students

  @belongsTo('clever/school', { async: false, inverse: 'teachers' })
  school

  get cleverTeacher() {
    return get(this, 'cleverTeacherMatch.cleverTeacher')
  }

  // Used in a computed property in match teachers table, in order to update dropdown options to remove teacher from
  // other dropdowns. Might be removable when that is using native getters.
  @dependentKeyCompat
  get sisId() {
    return get(this, 'cleverTeacherMatch.sisId')
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ') || '-'
  }
}
