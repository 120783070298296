import Model, { attr, belongsTo } from '@ember-data/model'

/**
 * Model class for clever sisId and district id.
 *
 * @class CleverSchoolMatchModel
 * @property {string} sisId - unique id which relates to a clever record fo this type
 */
export default class extends Model {
  @attr('string') sisId

  @attr('string') cleverId

  @belongsTo('clever/school', { async: false, inverse: 'cleverSchoolMatch' })
  school

  @belongsTo('clever/clever-school', { async: false, inverse: 'cleverSchoolMatch' })
  cleverSchool

  // Provided when creating a school match record
  @belongsTo('clever/clever-district-match', { async: false, inverse: null })
  cleverDistrictMatch
}
