import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { guidFor } from '@ember/object/internals'
import { htmlSafe } from '@ember/template'

const optionTemplate = htmlSafe(`
  <div>
    <%-schoolName%>
  </div>
  <div class="text-sm">
    <%-schoolTown%>, <%-schoolState%>
  </div>
`)

function schoolToOption(school) {
  return {
    schoolName: school.name,
    schoolTown: school.contactDetail.town,
    schoolState: school.contactDetail.state,
    text: school.name,
    value: school.id,
    uid: school.id,
  }
}

export default class CleverMatchSchool extends Component {
  @tracked
  _selectedOption = null

  optionTemplate = optionTemplate

  get selectedOption() {
    return this._selectedOption || this.defaultOption
  }

  set selectedOption(option) {
    this._selectedOption = option
  }

  get componentId() {
    return guidFor(this)
  }

  // If there is a matched school provided, then we want to show the reset text
  get showResetText() {
    return Boolean(this.args.matchedSchool)
  }

  get disableButton() {
    return this.defaultOption?.uid === this.selectedOption?.uid
  }

  get options() {
    return this.args.blakeSchools.map(schoolToOption)
  }

  get defaultOption() {
    return this.args.matchedSchool ? schoolToOption(this.args.matchedSchool) : null
  }

  @action
  resetDropdown() {
    this.selectedOption = null
  }

  @action
  selectSchoolOption(option) {
    this.selectedOption = option
  }

  @action
  submit() {
    this.args.submitAction(this.selectedOption.uid)
  }
}
