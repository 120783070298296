import Route from '@ember/routing/route'
import { service } from '@ember/service'
import { task } from 'ember-concurrency'

export default class ManageSchoolsStudentsIndexRoute extends Route {
  @service store

  schoolDataTask = task({ restartable: true }, async (school) => {
    return await this.store.query('student', {
      scope: `schools/${school.id}`,
      include: 'school,school-classes,school-classes.teachers',
    })
  })

  async model(params) {
    // includes teachers & school-classes, to know which teachers to populate the change teacher modal with
    const school = await this.store.findRecord('school', params.school_id, {
      // need teachers' school-classes; change teacher modal populates only with teachers that have relevant class
      include: 'school-classes,teachers,teachers.school-classes',
      reload: true,
    })
    this.schoolDataTask.perform(school)
    return { school, schoolDataTask: this.schoolDataTask }
  }

  resetController(controller, isExiting) {
    if (isExiting) controller.updateSelectedIds?.([])
  }
}
