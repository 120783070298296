import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import orderBy from 'lodash/orderBy';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import type Teacher from 'district-ui-client/models/teacher';
import { on } from '@ember/modifier';
import { t, formatDate } from 'ember-intl';
import { eventValue } from 'district-ui-client/helpers/event-value';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { fn } from '@ember/helper';
import { includes } from 'district-ui-client/helpers/includes';
import UiButtonLinkTo from 'district-ui-client/components/ui-button/link-to';
import { colspanMax } from 'district-ui-client/modifiers/colspan-max';
interface Args {
    subscriptionType: SubscriptionType;
    teachers: Teacher[];
    selectedIds: string[];
    updateSelectedIds?: (selectedIds: string[]) => void;
    isCleverDisabled?: boolean;
}
interface Signature {
    Args: Args;
    Blocks: {
        default: [];
    };
}
export class TeacherTable extends Component<Signature> {
    @tracked
    sortProperties = [
        'lastName:asc'
    ];
    @tracked
    search = '';
    get teacherViewModels() {
        const { subscriptionType: subscriptionType1 } = this.args;
        return this.args.teachers.map((teacher1)=>{
            let numberOfStudents1 = 0;
            let trialEndDate1;
            /* optional because sometimes this table shows teachers NOT for the current subtype (add existing teacher page).
       * If you were to do so, you may end up with multiple trial dates, and would need school classes for all _other_
       * sub types.
       */ if (subscriptionType1) {
                numberOfStudents1 = teacher1.schoolClasses?.reduce((sum1, sc1)=>(sc1.subscriptionType === subscriptionType1 ? sum1 + sc1.studentCount : sum1), 0) ?? 0;
                // filter by active, sort by latest endDate first
                const sortedTeacherSubs1 = teacher1.teacherSubscriptionsFor(subscriptionType1).filter((sub1)=>sub1.isActive).sort((a1, b1)=>b1.endDate.getTime() - a1.endDate.getTime());
                trialEndDate1 = sortedTeacherSubs1[0]?.endDate;
            }
            return {
                id: teacher1.id,
                firstName: teacher1.firstName,
                lastName: teacher1.lastName,
                login: teacher1.login,
                numberOfStudents: numberOfStudents1,
                trialEndDate: trialEndDate1,
                schoolName: teacher1.school?.name
            };
        });
    }
    get arrangedContent() {
        const properties1 = this.sortProperties.map((sortProperty1)=>sortProperty1.split(':')[0]);
        const directions1 = this.sortProperties.map((sortProperty1)=>sortProperty1.split(':')[1]).filter((direction1)=>{
            return direction1 === 'asc' || direction1 === 'desc';
        });
        return orderBy(this.teacherViewModels, properties1, directions1);
    }
    get filteredContent() {
        const lowerCaseSearch1 = this.search.toLowerCase();
        return this.arrangedContent.filter((content1)=>`${content1.firstName} ${content1.lastName} ${content1.login} ${content1.schoolName}`.toLowerCase().includes(lowerCaseSearch1));
    }
    get isAllSelected() {
        return this.filteredContent.every((t1)=>this.args.selectedIds.includes(t1.id));
    }
    setSearch = (value1: string)=>{
        this.search = value1;
    };
    @action
    sortBy(property1: string) {
        // Only supports 1 column, but can be extended.
        const [sortProperty1, sortDirection1] = this.sortProperties[0].split(':');
        if (sortProperty1 === property1) {
            this.sortProperties = [
                `${property1}:${sortDirection1 === 'asc' ? 'desc' : 'asc'}`
            ];
        } else {
            this.sortProperties = [
                `${property1}:asc`
            ];
        }
    }
    @action
    onSelectAll() {
        if (this.isAllSelected) {
            this.args.updateSelectedIds?.([]);
        } else {
            this.args.updateSelectedIds?.(this.filteredContent.map((t1)=>t1.id));
        }
    }
    @action
    onSelectTeacher(teacherId1: string) {
        if (this.args.selectedIds.includes(teacherId1)) {
            this.args.updateSelectedIds?.(this.args.selectedIds.filter((id1)=>id1 !== teacherId1));
        } else {
            this.args.updateSelectedIds?.([
                ...this.args.selectedIds,
                teacherId1
            ]);
        }
    }
    static{
        template(`
    <div>
      <div class="pb-3 text-center">
        <form class="form-inline mx-4 mb-3 inline-block align-middle">
          <div class="has-feedback overflow-hidden">
            <input
              data-test-search-teachers
              class="form-control"
              type="search"
              aria-label={{t "searchPlaceholder.teacher"}}
              placeholder={{t "searchPlaceholder.teacher"}}
              value={{this.search}}
              {{on "input" (eventValue this.setSearch)}}
            />
            <span class="form-control-feedback">
              <FaIcon @icon="magnifying-glass" />
            </span>
          </div>
        </form>
        {{yield}}
      </div>

      <div class="overflow-y-auto pb-3">
        <table class="disco-table w-full bg-white">
          <thead>
            <tr>
              <th class="th-checkbox">
                <input
                  type="checkbox"
                  class="cursor-pointer"
                  checked={{this.isAllSelected}}
                  aria-label={{if this.isAllSelected (t "uncheckAll") (t "checkAll")}}
                  {{on "input" this.onSelectAll}}
                />
              </th>
              <th class="text-left" role="button" {{on "click" (fn this.sortBy "firstName")}}>
                {{t "components.teacherTable.firstName"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th class="text-left" role="button" {{on "click" (fn this.sortBy "lastName")}}>
                {{t "components.teacherTable.lastName"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              {{#unless @isCleverDisabled}}
                <th class="text-left" role="button" {{on "click" (fn this.sortBy "login")}}>
                  {{t "components.teacherTable.login"}}
                  <FaIcon @icon="sort" @pull="right" />
                </th>
              {{/unless}}
              {{#if @subscriptionType}}
                <th role="button" {{on "click" (fn this.sortBy "numberOfStudents")}}>
                  {{t "components.teacherTable.students"}}
                  <FaIcon @icon="sort" @pull="right" />
                </th>
                <th role="button" class="text-left" {{on "click" (fn this.sortBy "trialEndDate")}}>
                  {{t "components.teacherTable.trialEnd"}}
                  <FaIcon @icon="sort" @pull="right" />
                </th>
              {{/if}}
              <th role="button" {{on "click" (fn this.sortBy "schoolName")}}>
                {{t "components.teacherTable.schoolName"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {{#each this.filteredContent as |teacher index|}}
              <tr data-test-teacher-row={{index}}>
                <td class="td-checkbox">
                  <input
                    type="checkbox"
                    class="cursor-pointer"
                    checked={{includes @selectedIds teacher.id}}
                    aria-label="{{teacher.firstName}} {{teacher.lastName}}"
                    {{on "input" (fn this.onSelectTeacher teacher.id)}}
                  />
                </td>
                <td>
                  {{teacher.firstName}}
                </td>
                <td>
                  {{teacher.lastName}}
                </td>
                {{#unless @isCleverDisabled}}
                  <td class="td-login">
                    {{teacher.login}}
                  </td>
                {{/unless}}
                {{#if @subscriptionType}}
                  <td align="center">
                    {{teacher.numberOfStudents}}
                  </td>
                  <td>
                    {{#if teacher.trialEndDate}}
                      {{formatDate teacher.trialEndDate day="2-digit" month="long" year="numeric"}}
                    {{else}}
                      {{t "components.teacherTable.notOnTrial"}}
                    {{/if}}
                  </td>
                {{/if}}
                <td align="center">
                  {{teacher.schoolName}}
                </td>
                <td align="center">
                  <UiButtonLinkTo
                    @route="subscription-type.manage.teachers.edit"
                    @model={{teacher.id}}
                    class="regular ui-btn-small"
                    aria-label="{{t
                      'components.teacherTable.edit'
                      firstName=teacher.firstName
                      lastName=teacher.lastName
                    }}"
                  >
                    <FaIcon @icon="pencil" />
                  </UiButtonLinkTo>
                </td>
              </tr>
            {{else}}
              <tr>
                <td {{colspanMax}}>{{t "components.teacherTable.noResults"}}</td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default TeacherTable;
