import { computed } from '@ember/object'
import { readOnly } from '@ember/object/computed'
import Controller from '@ember/controller'

export default class TableController extends Controller {
  @readOnly('model.product')
  product

  @computed('model.{scope.scope,scope.id,scopedData.schools.[]}')
  get schools() {
    const uiScope = this.model.scope
    if (uiScope.scope === 'district') return this.model.scopedData.schools
    return this.model.scopedData.schools.filter((school) => school.id === uiScope.id)
  }

  @readOnly('model.simpleSummary.simple_summary.schools')
  schoolsData

  @readOnly('model.simpleSummary.simple_summary.district')
  averagesAndTotalsData
}
