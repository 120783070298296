import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { capitalize } from '@ember/string';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import { roundAccurately } from 'district-ui-client/utils/round';
import { PieChart } from 'district-ui-client/components/primitives/pie-chart/component';
import type { SeriesPieOptions } from 'highcharts';
import colours from '@blakeelearning/blake-colours/colours';
import { reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config';
import type { TooltipFormatterContextObject } from 'highcharts';
import type { TooltipFormatterCallbackFunction } from 'highcharts';
/**
 * Display a donut chart of books read by genre (fiction/nonfiction).
 */ export interface BooksReadByGenreData {
    books_read_by_genre: {
        fiction: number;
        nonfiction: number;
    };
}
interface Signature {
    Args: {
        data: BooksReadByGenreData;
        product: 're' | 'rex';
    };
}
export class BooksReadByGenreDashboardChart extends Component<Signature> {
    get chartData(): SeriesPieOptions[] {
        const data1 = this.booksReadData;
        return [
            {
                data: data1,
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                type: 'pie'
            }
        ];
    }
    get chartInnerSize() {
        return '80%';
    }
    get chartSubtitle() {
        const subtitle1 = formatNumber(this.totalCount, 1);
        return `<h1>${subtitle1}</h1>`;
    }
    get chartSize() {
        return '80%';
    }
    get booksReadData(): [string, number][] {
        const values1 = this.args.data.books_read_by_genre;
        const keys1 = [
            'nonfiction',
            'fiction'
        ] as const;
        return keys1.map((key1)=>{
            return [
                capitalize(key1),
                values1[key1]
            ];
        });
    }
    get totalCount() {
        const values1 = this.args.data.books_read_by_genre;
        const keys1 = [
            'nonfiction',
            'fiction'
        ] as const;
        return keys1.reduce((total1, key1)=>{
            return total1 + values1[key1];
        }, 0);
    }
    get colours() {
        return [
            colours.purplyPink300,
            colours.purplyPink400
        ];
    }
    get legendData() {
        const total1 = this.totalCount;
        const data1 = this.booksReadData;
        const [[nonfictionName1, nonfiction1], [fictionName1, fiction1]] = data1;
        let fictionPercentage1 = 0;
        let nonfictionPercentage1 = 0;
        if (total1 !== 0) {
            fictionPercentage1 = (fiction1 / total1) * 100;
            nonfictionPercentage1 = (nonfiction1 / total1) * 100;
        }
        return {
            left: {
                name: fictionName1,
                percentage: roundAccurately(fictionPercentage1, 1)
            },
            right: {
                name: nonfictionName1,
                percentage: roundAccurately(nonfictionPercentage1, 1)
            }
        };
    }
    get tooltipFormatter(): TooltipFormatterCallbackFunction {
        return function(this1: TooltipFormatterContextObject) {
            return `<b>${this.point.name}</b>: ${formatNumber(this.y, 2)}`;
        };
    }
    get tooltip() {
        const formatter1 = this.tooltipFormatter;
        return toolTipConfig('y', '', {
            formatter: formatter1
        });
    }
    static{
        template(`
    <div class="relative flex h-full flex-col pb-7 text-2xl font-light print:!font-light" data-test-books-read-by-genre>
      <PieChart
        @data={{this.chartData}}
        @subtitle={{this.chartSubtitle}}
        @colours={{this.colours}}
        @size={{this.chartSize}}
        @innerSize={{this.chartInnerSize}}
        @tooltip={{this.tooltip}}
      />
      <div data-test-books-read-by-genre-legend class="absolute bottom-2 left-0 right-0 flex">
        <div data-test-books-read-by-genre-label="left" class="ml-[5%] flex flex-col items-center">
          <div
            class="bg-purply-pink-500 print:!bg-purply-pink-500 h-[10px] w-[10px] rounded-full print:!rounded-full"
          ></div>
          <div
            data-test-books-read-by-genre-percentage
            class="text-dusty-black-500 text-2xl font-thin print:!font-thin"
          >
            {{this.legendData.left.percentage}}%
          </div>
          <div data-test-books-read-by-genre-name class="text-dusty-black-500 m-0 text-base font-bold print:!font-bold">
            {{this.legendData.left.name}}
          </div>
        </div>

        <div data-test-books-read-by-genre-label="right" class="ml-auto mr-[5%] flex flex-col items-center">
          <div
            class="bg-purply-pink-300 print:!bg-purply-pink-300 h-[10px] w-[10px] rounded-full print:!rounded-full"
          ></div>
          <div
            data-test-books-read-by-genre-percentage
            class="text-dusty-black-500 text-2xl font-thin print:!font-thin"
          >
            {{this.legendData.right.percentage}}%
          </div>
          <div data-test-books-read-by-genre-name class="text-dusty-black-500 m-0 text-base font-bold print:!font-bold">
            {{this.legendData.right.name}}
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default BooksReadByGenreDashboardChart;
