import Component from '@glimmer/component'
import { MATCH_STATES } from 'district-ui-client/utils/clever/clever-school/sync-process-states'

export default class CleverMatchStateIndicator extends Component {
  get isTBD() {
    return this.args.matchState === MATCH_STATES.TBD
  }

  get inProgress() {
    return this.args.matchState === MATCH_STATES.IN_PROGRESS
  }

  get isDone() {
    return this.args.matchState === MATCH_STATES.DONE
  }

  get requiresMatchConfirmation() {
    return this.args.matchState === MATCH_STATES.CONFIRM
  }
}
