import Model, { hasMany, belongsTo, attr } from '@ember-data/model'
import { get } from '@ember/object'

/**
 * Model class for schools (a blake school model - gravity data, with clever relationships)
 */
export default class extends Model {
  @attr('string') name

  @belongsTo('clever/contact-detail', { async: false, inverse: 'school' }) contactDetail

  @belongsTo('clever/clever-school-match', { async: false, inverse: 'school' })
  cleverSchoolMatch

  @hasMany('clever/teacher', { async: false, inverse: 'school' })
  teachers

  @hasMany('clever/student', { async: false, inverse: 'school' })
  students

  get cleverSchool() {
    return get(this, 'cleverSchoolMatch.cleverSchool')
  }

  @hasMany('clever/school-subscription-utilisation', { async: false, inverse: 'school' })
  schoolSubscriptionUtilisations

  utilisationFor(subscriptionType) {
    return this.schoolSubscriptionUtilisations?.find((utilisation) => utilisation.subscriptionType === subscriptionType)
  }
}
