import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'

export default class TableRoute extends Route {
  @service
  studentEvents

  @service
  session

  async model() {
    const product = 'rex'
    const { uiScope: scope, statsScope, period, scopedData } = this.modelFor('reporting.ui-scope')

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product, _scope, period) {
    const districtScope = {
      ids: [this.session.currentDistrict.id],
      scope: 'district',
    }

    return RSVP.hash({
      simpleSummary: this.studentEvents.simpleSummary(product, districtScope, period),
    })
  }
}
