import Model, { attr, belongsTo } from '@ember-data/model'

/**
 * Model class for clever sisId and district id.
 *
 * @class CleverTeacherMatchModel
 * @property {string} cleverId - clever id as a string
 * @property {string} sisId - unique id which relates to a clever record fo this type
 * @property {boolean} missingEmail - boolean to indicate if the teacher has a missing email address
 * @property {BlakeTeacherModel} teacher - Blake teacher model
 * @property {CleverTeacherModel} cleverTeacher - clever teacher model
 * @property {CleverDistrictMatchModel} cleverDistrictMatch - Clever district match
 */
export default class extends Model {
  @attr('string') cleverId

  @attr('string') sisId

  @attr('boolean', { defaultValue: false }) missingEmail

  @belongsTo('clever/teacher', { async: false, inverse: 'cleverTeacherMatch' })
  teacher

  @belongsTo('clever/clever-teacher', { async: false, inverse: 'cleverTeacherMatch' })
  cleverTeacher

  // Provided when creating a school match record
  @belongsTo('clever/clever-district-match', { async: false, inverse: null })
  cleverDistrictMatch
}
