import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type Store from '@ember-data/store'

export default class EditRoute extends Route {
  @service store!: Store

  async model(params: { id: string }) {
    const student = await this.store.findRecord('student', params.id)
    return { student }
  }
}
